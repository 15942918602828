import React from 'react';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import Quill from 'react-quill';

import AuthMixin from '../../mixins/authenticationMixin';
import AppStore from '../../stores/stores';
import DataError from '../errors/data-error';
import LocalLoading from '../loading/local-loading';

// import notes from '../../../images/icons/notes.svg';

import './notes.scss';

class NoteField extends React.Component {
  constructor() {
    super();

    this.state = {
      content: '',
      saved: true,
      saving: false,
      loading: true,
      open: false
    };

    this.saveTimeout = null;
  }

  componentDidMount() {
    let { checklist, userApiRequest, readOnly } = this.props;

    if (readOnly) {
      this.setState({loading: false});
      return;
    }

    AppStore.getNote(checklist, userApiRequest, {
      onSuccess: (response) => {
        let note = response.data || { content: '' };
        this.setState({
          content: note.content || '',
          loading: false
        })
      },
      onError: ({ response }) => {
        if ("Rollbar" in window) {
          Rollbar.error(`Failed to load notes for ${checklist}` , response);
        }

        if (response.statusCode === 404) {
          this.setState({loading: false, error: false, content: response.body.content});

          return;
        }

        this.setState({
          error: true,
          loading: false
        });
      }
    });
  }

  textChanged(content, delta, source, editor) {
    this.setState({
      content: editor.getText().trim().length > 0 ? content : '',
      saved: false
    });
    window.onbeforeunload = "Are you sure you want to leave?";

    clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => {
      this.setState({ saving: true });

      let { checklist, userApiRequest } = this.props;
      let { content } = this.state;

      AppStore.storeNote(checklist, content, userApiRequest, {
        onSuccess: () => {
          window.onbeforeunload = null;
          this.setState({ saved: true, saving: false });
        },
        onError: () => {
          this.setState({ saved: false, saving: false, error: true });
        }
      });

      ReactGA.event({
        category: "User",
        action: "Edited notes on a checklist",
        label: checklist
      });
    }, 1000);
  }

  render () {
    let { loading, saved, saving, error, content, open } = this.state;
    let { readOnly } = this.props;

    if (loading) {
      return (
        <div className="inner-container">
          <LocalLoading text="Loading..." />
        </div>
      );
    }
    if (error) {
      return (
        <div className="inner-container">
          <DataError />
        </div>
      );
    }

    let classes = classNames({
      'checklist-notes': true,
      'checklist-notes-unsaved': !saved,
      'checklist-notes-saving': saving,
      open
    })

    return (
      <div className="inner-container">
        <div className={classes}>
          {/* <button className="open-btn" onClick={() => this.setState({open: !this.state.open})}><img src={notes} alt="My notes" /></button> */}
          { saving ? <LocalLoading text="saving..." /> : null }

          <Quill
            value={content}
            onChange={this.textChanged.bind(this)}
            readOnly={readOnly}
            placeholder={readOnly ? "Keep notes and reminders right here in Neurochecklists. Requires subscription." : "Your notes. Changes are autosaved."} />
        </div>
      </div>
    )
  }
}

export default AuthMixin(NoteField);
