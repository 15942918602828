import React from 'react';
import {Link} from 'react-router';
import {Style} from '../../components/breadcrumbs/breadcrumbs.scss';

export default (props)=> {
  let { query } = props.location;
  let perPage = query.per_page;
  let pageNumber = query.page || 1;
  let searchTitle = query && query.q ? query.q  : '';
  let resultsText = "";
  let currentlyShowing = ""
  let curentOffset = props.totalShowing * pageNumber;

  let breadcrumbs = (
    <ul className="breadcrumbs-list">
      <li>
        <Link to='/'>Home</Link>
        <i className="icon-right-open"/>
      </li>
      <li>
        <span>Search Results</span>
      </li>
    </ul>
  );


  if (props.pageNumber === 1){
    currentlyShowing = 1
  } else {
    currentlyShowing = ((pageNumber * props.totalShowing ) - (props.totalShowing - 1));
  }

  if(pageNumber == props.totalPages  ) {
    currentlyShowing = (props.totalCount - props.totalShowing );
    curentOffset = props.totalCount;
  }


  if(props.totalPages > 1){
    resultsText = <p className="results-text">Showing results {currentlyShowing} to {curentOffset} of {props.totalCount} for {searchTitle}</p>
  } else {
    resultsText = <p className="results-text">Showing results {props.totalShowing} of {props.totalCount} for {searchTitle}</p>
  }


  return(
    <div className="search-results-banner bg-grey">
      <div className="inner-container">
        <div className="pure-g">
          <div className="pure-u-1 pure-u-md-1-2 mobile-show">
            <div className="l-box">
              {breadcrumbs}
            </div>
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1">
            <div className="l-box">
              <h1><span className="darkGrey">Search Results: </span>{searchTitle}</h1>
            </div>
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1 pure-u-md-1-2 mobile-hide-ib">
            <div className="l-box">
              {breadcrumbs}
            </div>
          </div>
          <div className="pure-u-1 pure-u-md-1-2">
            <div className="l-box">
              {resultsText}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
