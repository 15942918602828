import React from 'react';
import { Link } from 'react-router';
import AppRoutes from '../../routes/routes'
import Styles from './user-footer.scss';

export default () => {
  return (
    <footer className="page-footer-user clearfix">
      <div className="inner-container">
        <div className="l-box">
          <nav className="page-footer-user--menu">
            <div>
            <Link className="app-footer-image" to={'/'}>
              <img className="pure-img" src={require('../../../images/logo.svg')} width="120" height="20" alt="Neurochecklists" />
            </Link>
            <br/>
            <span className="mobile-hide">&copy; {new Date().getFullYear()} &nbsp; Neurochecklists Ltd. All Rights Reserved.</span>
            </div>
            <ul>
              <li><Link to={`/`}>Home</Link></li>
              <li><Link to={AppRoutes.about}>About</Link></li>
              <li><Link to={AppRoutes.faqs}>FAQs</Link></li>
              <li><Link to={AppRoutes.privacy}>Privacy Policy</Link></li>
              <li><Link to={AppRoutes.data}>Data Policy</Link></li>
              <li><Link to={AppRoutes.tcs}>Terms &amp; conditions</Link></li>
              <li><Link to={AppRoutes.credits}>Credits</Link></li>
              <li><Link to={AppRoutes.contact}>Contact us</Link></li>
            </ul>
            <span className="mobile-show copyright">&copy; {new Date().getFullYear()} &nbsp; Neurochecklists Ltd. All Rights Reserved.</span>

          </nav>

        </div>
      </div>
    </footer>
  )
}
