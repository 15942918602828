import React from 'react';
import { Helmet } from 'react-helmet';
import uuid from 'uuid/v4';
import AppRoutes from '../../routes/routes';
import UserSection from './user-section';
import Api from '../../api/api';
import AuthApi from '../../api/authApi';
import CONSTANTS from '../../constants/constants';
import { SERVER_ERROR, HELP_INFO } from '../../constants/messages';
import Notice from '../../components/notices/notice';
import Loading from '../../components/loading/loading';
import LoginActions from '../../actions/login-actions';

class DeleteAccount extends React.Component {
  constructor(){
    super();
    this.captcha = Api.captcha();
    this.state = {
      success: false,
      clientError: false,
      serverError: false,
      error_messages: [],
      loading: false
    };
  }

  validateInput(data){
    const error_messages = [];

    if(+data.captcha != this.captcha.result){
      error_messages.push("Your answer was incorrect please try again");
    }

    if(Api.hasEmptyFields(data)){
      error_messages.push('Please fill in all fields correct');
    }

    return error_messages;
  }

  handleSubmit(e){
    e.preventDefault();

    this.setState({
      loading: true
    });

    const message = this.refs.message.value;
    const captcha = this.refs.captcha.value;
    const data = { message, captcha};
    const error_messages = this.validateInput(data);

    if(error_messages.length){
     return this.setState({
        clientError: true,
        success: false,
        serverError: false,
        loading: false,
        error_messages
      });
    }

    this.setState({
      clientError: false,
      serverError: false
    });

    this.sendForm(data);
  }

  sendForm(data){
    AuthApi.deleteUserAccount(this.props.userApiRequest, data, {
      onSuccess: () => {
        this.refs.form.reset();
        this.setState({
          success: true,
          clientError: false,
          serverError: false,
          loading: false
        });

        setTimeout(() => LoginActions.logout(this.props.userApiRequest), 5000);
      },
      onError: () => {
        this.setState({
          serverError: true,
          success: false,
          clientError: false,
          loading: false
        });
      }
    });
  }

  renderClientErrorNotice(){
    let errors = this.state.error_messages.map( (error, index) => {
      return <li key={uuid()}>{error}</li>
    })

    return (
      <Notice alert='alert-danger'>
        <ul>
          {errors}
        </ul>
      </Notice>
    );
  }

  renderServerErrorNotice(){
    return (
      <Notice alert='alert-danger'>
        <div>
          <p>{SERVER_ERROR}</p>
          <p>{HELP_INFO}</p>
        </div>
      </Notice>
    );
  }

  renderSuccessNotice(){
    return (
      <Notice alert='alert-success'>
        <p>Your request was successfully submitted. Your account has been deleted. You will be logged out in 5 seconds.</p>
      </Notice>
    );
  }

  render(){
    let notice = null;
    let clientNotice = null;

    if(this.state.clientError) clientNotice = this.renderClientErrorNotice();
    if(this.state.serverError) notice = this.renderServerErrorNotice();
    if(this.state.success) notice = this.renderSuccessNotice();

    return (
      <div>
        <Helmet>
          <title>Neurochecklists | Delete your account</title>
        </Helmet>

        <div className="user-section-form force-80-height">
          <div className="l-box">
            <div className="centred-text ">
              <p>We're sorry to hear you'd like to delete your account.</p>
              <p>If you found this website hard to use, please our&nbsp; <a href={`${AppRoutes.faqs}`}>FAQs</a> page for answers to frequently asked questions before permanently deleting your account. </p>
              <p>Let us know how we can make things better. send us an email to <a href={`mailto:${CONSTANTS.CONTACT_EMAIL}`}>{CONSTANTS.CONTACT_EMAIL}</a></p>
              <p>If you would still like to permanently delete your account please fill the form below and your account will be permanently deleted within 24hrs.</p>
            </div>

            <div className="pure-g">
              {notice ? (
                <div className="pure-u-1">
                  {notice}
                </div>
              ) : null}

              {clientNotice ? (
                <div className="pure-u-1">
                  {clientNotice}
                </div>
              ) : null}

              {notice ? null : (
                <form ref="form" onSubmit={this.handleSubmit.bind(this)} className="pure-form delete-form">
                  {(this.state.loading)? <Loading text="Please wait..." /> : null }

                    <div className="pure-u-1">
                      <p>In a few words please tell us why you want to delete your account:</p>
                    </div>
                    <div className="pure-u-1">
                      <textarea style={{ height: 150 }} ref="message" className="pure-input-1" type="text" placeholder="Why are you deleting your account? *" required/>
                    </div>

                  <div className="pure-u-1 pure-u-md-16-24">
                    <input ref="captcha" className="pure-input-1" type="text" placeholder={`What is the sum of ${this.captcha.leftside} + ${this.captcha.rightside}?`} required/>
                  </div>
                  <div className="pure-u-1 pure-u-md-8-24">
                    <button className="pure-button delete-button">Delete Account</button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserSection(DeleteAccount);
