import xhr from 'xhr';
import { SERVER_ERROR } from '../constants/messages';

function sendRollBarMessage(error, severity = "error"){
  try {
    Rollbar[severity]("Error: " + error.message, error);
  } catch(e){}
}

function handleAction(error, response, body, callbacks) {
  if (!Object.keys(callbacks).length) return;

  if (response.statusCode >= 200 && response.statusCode < 400) {
    return callbacks.onSuccess({ response, data: body });
  }

  if ( body ) {
    return callbacks.onError({ response, error: body });
  }

  //doesn't seem to be a client facing error.
  sendRollBarMessage(error);
  return callbacks.onError({ error: SERVER_ERROR, details: error });
}

function JSONParse(item){
  try {
    return JSON.parse(item);
  } catch(e) {
    return undefined;
  }
}

export function getData(url, options = {}, callbacks = {}){
  const settings = {
    json: true,
    headers: {
      ...options,
      "Content-Type": "application/json"
    }
  };

  xhr.get(url, settings, function(error, response, body){
    handleAction(error, response, body, callbacks);
  });
}

export function postData(url, options = {}, data={}, callbacks = {}){
  const settings = {
    headers: {
      ...options,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  xhr.post(url, settings, function(error, response, body){
    handleAction(error, response, JSONParse(body), callbacks);
  });
}

export function updateData(url, options = {}, data={}, callbacks = {}){
  const settings = {
    json: true,
    headers: {
      ...options,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  xhr.put(url, settings, function(error, response, body){
    let data = (body || typeof(body) === 'string' )? JSON.parse(body) : undefined;
    handleAction(error, response, JSONParse(body), callbacks);
  });
}

export function deleteData(url, options = {}, data={}, callbacks = {}){
  const settings = {
    headers: {
      ...options,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  xhr.del(url, settings, function(error, response, body){
    handleAction(error, response, JSONParse(body), callbacks);
  });
}

export function patchData(url, options = {}, data={}, callbacks = {}){
  const settings = {
    headers: {
      ...options,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  xhr.patch(url, settings, function(error, response, body){
    handleAction(error, response, JSONParse(body), callbacks);
  });
}
