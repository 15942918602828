import React from 'react';
import { Link } from 'react-router';
import TitleBanner from '../banners/title-banner';
import AppRoutes from '../../routes/routes';
import uuid from 'uuid/v4';
import './recommendation-error.scss';

export default class RecommendationError extends React.Component {
    constructor() {
        super();
        this.buildSuggestions = this.buildSuggestions.bind(this);
    }

    buildSuggestions(suggestions) {
        return suggestions.map(item => (
            <li className="recommendation-error-list-item" key={uuid()} >
                <Link to={`${AppRoutes.checklists}${item.slug}`}>{item.title}</Link>
            </li>)
        )
    }
    render() {
        return (
        <section className="big-padding-bottom force-full-height">
            <TitleBanner title="Error" />
            <div className="inner-container">
            <p className="error-text">{this.props.message}</p>
                {this.props.did_you_mean && this.props.did_you_mean.length > 0 ? (
                    <div>
                        <h3 className="recommendation-error-list-title">Similar results:</h3>
                        <ul className="recommendation-error-list">
                            {this.buildSuggestions(this.props.did_you_mean)}
                        </ul>
                        <p className="aside">Still not right? Try <Link to={'/explore'}>exploring</Link> your topic or searching</p>
                    </div>
                )
                : <p className="error-text">We don't have any recommendations for you.<br/>Try <Link to={'/explore'}>exploring</Link> your topic or searching for similar terms</p>
                }

            </div>
        </section>
        )
    }
}

