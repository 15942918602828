import React from 'react';
import { browserHistory, withRouter } from 'react-router';
import queryString from 'query-string';
import AppAuthStore from '../../stores/login-store';

import './upgrade.scss';

class UpgradeButton extends React.Component {
  constructor() {
    super();
    this.state = { popupActive: false };
  }

  componentDidMount() {
    const { account_type } = AppAuthStore.getSessionUserData();
    const modal = this.props.location.query.modal;

    if ((modal === 'upgrade' || modal === 'payment') && account_type === 'Starter') this.setState({ popupActive: true });
  }

  componentWillReceiveProps(props) {
    this.setState({ popupActive: props.location.query.modal === 'upgrade' || props.location.query.modal === 'payment' });
  }

  handleClick(location, e) {
    e.preventDefault();
    const query = queryString.stringify({ modal: location });
    browserHistory.replace(`${this.props.location.pathname}?${query}`);
  }

  render() {
    let modal = this.props.location.query.modal;

    return (
      <div className="upgrade-btn">
        <a href="#" onClick={this.handleClick.bind(this, 'upgrade')}>Upgrade</a>
      </div>
    );
  }
}

export default withRouter(UpgradeButton);
