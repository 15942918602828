import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default class LocalLoading extends React.Component {
    constructor(props) {
        super(props);

        this.state = {text: props.text};
    }

    componentWillReceiveProps(newProps) {
        this.setState({text: newProps.text});
    }

    render() {
        return (
            <div className="local-loader tac">
                <FontAwesomeIcon icon={faSpinner} size="2x" spin />
                <p>{this.state.text}</p>
            </div>
        )
    }
}
