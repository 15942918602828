import React from 'react';
import AuthNavigation from '../navigation/user-navigation';
import LoginStore from '../../stores/login-store';
import Classnames from 'classnames';
import AuthenticationMixin from '../../mixins/authenticationMixin';

import './header.scss';

const Header = props => {
  let loggedIn = LoginStore.isLoggedIn();

  let classes = Classnames({
    'app-header': true,
    'app-header-user': loggedIn
  });

  let nav = <AuthNavigation {...props} />;

  return <header id="app-header" className={classes}>{nav}</header>;
};

export default AuthenticationMixin(Header);
