import React from 'react';
import { Link } from 'react-router';
import { browserHistory } from 'react-router';
import queryString from 'query-string';
import { OutboundLink } from 'react-ga';
import classnames from 'classnames';

import AppRoutes from '../../routes/routes';
import SlideOutUserNav from './slideout-user-nav';

import AuthRoutes from '../../routes/logged-in';
import LoginActions from '../../actions/login-actions';
import AuthMixin from '../../mixins/authenticationMixin';
import TopSearchBar from '../searches/top-searchbar';
import UpgradeButton from '../upgrade/upgrade-button';
import LoginStore from '../../stores/login-store';
import AppStore from '../../stores/stores';

import './user-navigation.scss';

class UserNavigation extends React.Component {
  constructor() {
    super();
    this.state = {
      menuOpened: false,
      loggedIn: LoginStore.isLoggedIn()
    };
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    AppStore.addChangeListener(this._onChange);
    LoginStore.addChangeListener(this._onChange);
  }

  componentWillUnmount() {
    AppStore.removeChangeListener(this._onChange);
    LoginStore.removeChangeListener(this._onChange);
  }

  componentWillReceiveProps(nextProps) {
    document.body.style.overflowY = '';
  }

  _onChange() {
    this.setState({
      menuOpened: false,
      loggedIn: LoginStore.isLoggedIn()
    });
  }

  handleLogin(location) {
    let query = queryString.stringify({ modal: location });
    browserHistory.replace(`${this.props.location.pathname}?${query}`);
  }

  handleLogout() {
    LoginActions.logout(this.props.userApiRequest);
  }

  selectedItem(location, page) {
    return (location === page ? 'pure-menu-selected' : '');
  }

  openMenu() {
    document.body.style.overflowY = '';
    this.setState({
      menuOpened: !this.state.menuOpened
    });
  }

  render() {
    const currentLocation = this.props.location.pathname;
    const searchBar = currentLocation === AppRoutes.homepage ? '' : <TopSearchBar {...this.props} />;
    const menuIcon = this.state.menuOpened ? <i className="menu-icon menu-icon--close" /> : <i className="menu-icon menu-icon--hambuger" />;
    const mobileMenuStyles = classnames({
      [`menu-${currentLocation}`]: true,
      'menu-btn-mobile': true,
      'is-inactive': !this.state.menuOpened
    });

    return (
      <div>
        <div className="user-navigation pure-menu pure-menu-horizontal">
          <div className="nav-container clearfix">
            <div className="inner-container">
              <Link to={AppRoutes.homepage} className="menu-logo menu-desktop-logo" />
              <ul className="pure-menu-list">
                <li className="pure-menu-item">
                  <Link to={AppRoutes.explore} className={this.selectedItem(currentLocation, AppRoutes.explore)}>
                    Explore
                  </Link>
                </li>

                {this.state.loggedIn ? (
                  <React.Fragment>
                    <li className="pure-menu-item">
                      <Link to={AuthRoutes.bookmarks} className={this.selectedItem(currentLocation, AuthRoutes.bookmarks)}>
                        Bookmarks
                      </Link>
                    </li>
                    <li className="pure-menu-item">
                      <Link to={AuthRoutes.notes} className={this.selectedItem(currentLocation, AuthRoutes.notes)}>
                        Notes
                      </Link>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li className="pure-menu-item">
                      <Link className={this.selectedItem(currentLocation, AppRoutes.about)} to={AppRoutes.about}>
                        About
                      </Link>
                    </li>
                    <li className="pure-menu-item">
                      <Link className={this.selectedItem(currentLocation, AppRoutes.pricing)} to={AppRoutes.pricing}>
                        Pricing
                      </Link>
                    </li>
                  </React.Fragment>
                )}

                <li className="pure-menu-separator"></li>

                <li className="pure-menu-item">
                  <OutboundLink eventLabel="Blog link" to={AppRoutes.news} target="_blank">The Neurology Lounge Podcast</OutboundLink>
                </li>

                <li className="pure-menu-item">
                  <OutboundLink eventLabel="Blog link" to={AppRoutes.paper} target="_blank">Surfing the Brain</OutboundLink>
                </li>

                <li className="pure-menu-item">
                  <OutboundLink eventLabel="Book link" to={AppRoutes.book} target="_blank">700 Essential Neurology Checklists</OutboundLink>
                </li>

                <li className="pure-menu-separator"></li>

                {this.state.loggedIn ? (
                  <React.Fragment>
                    <li className="pure-menu-item">
                      <Link to={AuthRoutes.account}>
                        <span>{`Hi, ${this.props.user.name}`}</span>
                      </Link>
                    </li>
                    <li className="pure-menu-item">
                      <a onClick={this.handleLogout.bind(this)} className="logout-btn">
                        <i className="icon-logout" />
                      </a>
                    </li>
                    <li className="pure-menu-item">
                      <UpgradeButton {...this.props} />
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li className="pure-menu-item">
                      <a id="login-sidebar" className="clickable" onClick={this.handleLogin.bind(this, 'login')}>
                        Login
                      </a>
                    </li>
                    <li className="pure-menu-item">
                      <a id="register-sidebar" className="clickable" onClick={this.handleLogin.bind(this, 'register')}>
                        Register
                      </a>
                    </li>
                  </React.Fragment>
                )}
              </ul>

              <a onClick={this.openMenu.bind(this)} className="menu-btn">
                {menuIcon}
              </a>
            </div>

          </div>
          {searchBar}
        </div>
        <SlideOutUserNav {...this.props} menuOpened={this.state.menuOpened} />
      </div>
    );
  }
}

export default AuthMixin(UserNavigation);
