import React from 'react';
import { browserHistory } from 'react-router';
import queryString from 'query-string';

export default class FeedBackButton extends React.Component {

  constructor(){
    super();
    this.state = {popupActive: false};
  }

  componentWillReceiveProps(props){
    let modal = props.location.query.modal;
    if(modal === 'feedback')
      this.setState({popupActive: true});
    else
      this.setState({popupActive: false});
  }

  componentDidMount(){
    let modal = this.props.location.query.modal;
    if(modal === 'feedback') this.setState({popupActive: true});
  }

  handleClick(location){
    const query = queryString.stringify({
      modal: location
    });
    browserHistory.replace(`${this.props.location.pathname}?${query}`)
  }

  render(){
    return (
      <div>
        <button onClick={this.handleClick.bind(this, 'feedback')} className="pure-button rotated-text">
          <span className="rotated-text__inner">
            <i className="icon-comment"/>
          </span>
        </button>
      </div>
    )
  }
}
