import React from 'react';
import { Link } from 'react-router';
import AppRoutes from '../../routes/logged-in';

export default class UserSectionNavigation extends React.Component {
  render() {
    return (
      <ul className="user-section-nav">
        <li><Link to={AppRoutes.account} activeClassName="selected">Account Details</Link></li>
        <li><Link to={AppRoutes.changePassword} activeClassName="selected">Change Password</Link></li>
      </ul>
    )
  }
}
