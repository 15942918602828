import React from 'react';
import { withRouter, browserHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import apiEndpoints from '../constants/endpoints';

class SitemapRedirector extends React.Component {
    componentDidMount() {
        // all this does is instantly switch the URL over.
        window.location.href = apiEndpoints.SITEMAP;
    }

    render() {
        return (
            <Helmet>
                <link rel="canonical" href={apiEndpoints.SITEMAP}></link>
            </Helmet>
        )
    }
}

export default withRouter(SitemapRedirector);
