import React from 'react';
import ClassNames from 'classnames';

import ApiEndPoints from '../../constants/endpoints';
import AppStore from '../../stores/stores';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import authenticationMixin from '../../mixins/authenticationMixin';

class AccountDownloadLink extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      baseUrl: ApiEndPoints.DOWNLOADACCOUNT
    };

    this.submit = this.submit.bind(this);
  }

  waitForDownload(title, token) {
    const { userApiRequest } = this.props;

    return new Promise(function(resolve, reject) {
      AppStore.powerpointReady(title, userApiRequest, {
        onSuccess: ({response, data}) => {
          if (response.status === 202) {
              return waitForDownload(title, token);
          }

          if (response.statusCode) {
            resolve(title, token);
          }
        },
        onError: (err) => {
          reject(err);
        }
      });
    });
  }

  componentDidMount() {
    const { userApiRequest } = this.props;

    this.setState({loading: true});

    AppStore.downloadToken(userApiRequest, {
        onSuccess: ({data}) => {
          this.setState({
            loading: false,
            token: data.token,
            url: this.state.baseUrl
          });
        },
        onError: () => {
            this.setState({loading: true});
            setTimeout(this.handleClick, 2500);
        }
    });
  }

  submit() {
    let f = document.createElement('form');
    f.method = "post";
    f.action = ApiEndPoints.DOWNLOADACCOUNT;
    let t = document.createElement('input');
    t.type = "hidden";
    t.name = "t";
    t.value = this.state.token;
    let tok = document.createElement('input');
    tok.type = "hidden";
    tok.name = "x-user-token";
    tok.value = this.props.userApiRequest["X-USER-TOKEN"];
    let em = document.createElement('input');
    em.type = "hidden";
    em.name = "x-user-email";
    em.value = this.props.userApiRequest["X-USER-EMAIL"];
    f.appendChild(t);
    f.appendChild(tok);
    f.appendChild(em);
    document.body.appendChild(f);
    f.submit();
  }

  render() {
    let classnames = ClassNames({
        'download-link': true
    });
    let content = this.state.loading ? <i><FontAwesomeIcon size="2x" spin icon={faSpinner} /></i> : 'Download my data';
    return !this.state.token ? null : <div className={classnames} onClick={this.submit}>{content}</div>
  }
}

export default authenticationMixin(AccountDownloadLink);
