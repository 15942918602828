import React from 'react';
import { browserHistory, withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import classnames from 'classnames';
import uuid from 'uuid/v4';
import ReactGA from 'react-ga';

import Api from '../../api/api';
import AppStore from '../../stores/stores';
import AppConstants from '../../constants/constants';
import SearchHeader from './search-header';
import SearchSidebar from './search-sidebar';
import SearchItem from './search-item';
import SearchPagination from './search-pagination';
import AuthMixin from '../../mixins/authenticationMixin';
import DataError from '../../components/errors/data-error';

import './search-results-page.scss';

class SearchPage extends React.Component {
  constructor() {
    super();
    this.state = {
      noData: false,
      data: [],
      totalShowing : '',
      totalCount: '',
      totalPages: '',
      recentSearches: [],
      popularSearches: [],
      error: false
    };
  }

  componentWillReceiveProps(newProps) {
    this._onChange(newProps);
  }

  componentDidMount() {
    this._onChange(this.props);
  }

  _onChange(props) {
    const { userApiRequest, location } = props;
    const { query } = location;

    AppStore.getSearchResults(query, userApiRequest, {
      onSuccess: ({data}) => {
        this.setState({
          error: false,
          data: data.data,
          noData: (data.data.length) ? false : true,
          totalShowing: data.total_showing,
          totalCount: data.total_count,
          totalPages: data.total_pages
        });
        setTimeout(Api.scrollCentre);

        ReactGA.event({
          category: "User",
          action: "Search",
          label: query.q
        });
      },
      onError: () => this.setState({error: true})
    });

    this.getRecentSearches(props);
  }

  getRecentSearches(props) {
    AppStore.getRecentSearches(props.userApiRequest, {
      onSuccess: ({ data }) => {
        this.setState({
          error: false,
          recentSearches: data.recent_searches,
        });
      },
      onError: error => this.setState({error: true})
    });
  }

  generateFilterList() {
    const items = ['all','checklists','topics','chapters'];
    const { query } = this.props.location;
    const filter = query.filter || 'all';
    const list = items.map((name, index) => {
      let classname = classnames({
        selected: filter.toLowerCase() === name
      })
      return <li className={classname} key={uuid()}>{name}</li>
    })

    return <ul onClick={this.switchFilters.bind(this)}>{list}</ul>
  }

  switchFilters(e) {
    if (!Api.selectorMatches(e.target, 'li')) {
      return;
    }

    const lists = e.currentTarget.childNodes;
    [].forEach.call(lists, (item) => item.classList.remove('selected'))

    e.target.classList.toggle('selected');
    const {query, pathname:url} = this.props.location;
    const queryParams = queryString.stringify({
      q: query.q,
      page: AppConstants.DEFAULTSEARCHPAGE,
      per_page: AppConstants.PERPAGE,
      filter: e.target.textContent
    });

    ReactGA.event({
      category: "User",
      action: "Search filter",
      label: e.target.textContent
    });

    browserHistory.replace(`${url}?${queryParams}`)
  }

  render() {
    if (this.state.error) return <DataError />;

    let results = this.state.data.map((result)=> {
      return <SearchItem {...this.props}  data={result} key={uuid()} />
    })

    return(
      <div>
        <Helmet>
          <meta name="description" content="Powerful and effortless search for fully referenced neurological information." />
          <title>Neurochecklists | Searching for neurology checklists</title>
        </Helmet>

        <SearchHeader {...this.props}
          totalShowing={this.state.totalShowing}
          totalCount={this.state.totalCount}
          totalPages={this.state.totalPages}
        />
        <div className="search-results">
          <header className="search-filter">
            <div className="inner-container">
              <div className="pure-g">
                <div className="pure-u-1 pure-u-md-15-24">
                  <div className="l-box">
                    {this.generateFilterList()}
                  </div>
                </div>
                <div className="mobile-hide-ib pure-u-md-9-24">
                  <div className="l-box">
                    <span className="recent-searches-title">Your recent searches:</span>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="inner-container">
            <div className="pure-g">
              <section className="pure-u-1 pure-u-md-15-24">
                <div className="l-box">
                    {
                      (this.state.noData) ? (
                        <div className="result-item">
                          <h3>
                            No results found
                          </h3>
                        </div>
                      ) : results
                    }
                </div>
              </section>
              <aside className="pure-u-1 pure-u-md-9-24 mobile-hide-ib">
                <SearchSidebar
                  recentSearches={this.state.recentSearches}
                  popularSearches={this.state.popularSearches}
                />
              </aside>
            </div>

            <SearchPagination {...this.props}
              totalShowing={this.state.totalShowing}
              totalCount={this.state.totalCount}
              totalPages={this.state.totalPages}
            />
          </div>

        </div>
      </div>
    )
  }
}

export default AuthMixin(withRouter(SearchPage));
