import React from 'react';
import { Helmet } from 'react-helmet';
import store from 'store';
import uuid from 'uuid/v4';
import Api from '../../api/api';
import AppAuthStore from '../../stores/login-store';
import { SERVER_ERROR } from '../../constants/messages';
import SettingsNotice from './settings-notice';
import UserSection from './user-section';
import Loading from '../../components/loading/loading';

class ChangePassword extends React.Component {
  constructor(){
    super();
    this.state = {
      success: false,
      errors: false,
      errorMsgs: {},
      loading: false
    };
  }

  validateData(data){
    let errors = {};
    if(Api.hasEmptyFields(data)) {
      errors['empty_fields'] = "Please complete all fields";
    }

    if(data.password !== data.confirm_password) {
      errors['mismatch'] = "Your new password did not match";
    }

    if(Object.keys(errors).length){
      return { errors:true, errorMsgs: errors };
    }

    return { errors: false };
  }

  handleSubmit(e){
    e.preventDefault();

    this.setState({ loading: true })

    let data = {
      current_password: this.refs.current_password.value,
      password: this.refs.password.value,
      confirm_password: this.refs.confirm_password.value
    };

    let isValid = this.validateData(data);

    if(isValid.errors){
      return this.setState({
        errors:true,
        errorMsgs: isValid.errorMsgs,
        loading: false
      })
    }

    AppAuthStore.updateUserPassword(this.props.userApiRequest, data, {
      onSuccess: ({data}) => {
        this.setState({
          errors: false,
          success: true,
          errorMsgs: {},
          loading: false
        });
        setTimeout( () => {
          store.clearAll();
          window.location.reload();
        }, 500);
      },
      onError: (error) => {
        this.setState({
          errors: true,
          success: false,
          errorMsgs: {
            serverError: SERVER_ERROR
          },
          loading: false
        });
      }
    });
  }

  renderErrorMessages(){
    return Object.keys(this.state.errorMsgs).map((key, index) => {
      return <li key={uuid()}>{this.state.errorMsgs[key]}</li>
    })
  }

  renderNotice(){
    let success =  <p className="successMsg">Successfully Updated Password.</p>
    let fail = <ul className="failMsg">{this.renderErrorMessages()}</ul>
    let msg = (!this.state.errors)? success : fail;
    return(
      <SettingsNotice>
        {msg}
      </SettingsNotice>
    );
  }

  render(){
    let notice = (this.state.errors || this.state.success)? this.renderNotice() : null;

    return (
      <div>

        <Helmet>
          <title>Neurochecklists | Change Password</title>
        </Helmet>
        <div className="user-section-form force-80-height">
          {notice}
          <form className="pure-form settings-form" onSubmit={this.handleSubmit.bind(this)}>
            {(this.state.loading)? <Loading text="Please wait..." /> : null }
            <div className="l-box">
              <div className="pure-control-group pure-g">
                <div className="pure-u-1 pure-u-md-6-24">
                  <label htmlFor="current_password">Current Password</label>
                </div>
                <div className="pure-u-1 pure-u-md-18-24">
                  <input
                    ref="current_password"
                    className="pure-input-1"
                    id="current_password"
                    name="current_password"
                    type="password"
                    autoComplete="off"
                    required={true}
                  />
                </div>
              </div>

              <div className="pure-control-group pure-g">
                <div className="pure-u-1 pure-u-md-6-24">
                  <label htmlFor="password">New Password</label>
                </div>
                <div className="pure-u-1 pure-u-md-18-24">
                  <input
                    ref="password"
                    className="pure-input-1"
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="off"
                    required={true}
                  />
                </div>
              </div>

              <div className="pure-control-group pure-g">
                <div className="pure-u-1 pure-u-md-6-24">
                  <label htmlFor="confirm_password">Confirm Password</label>
                </div>
                <div className="pure-u-1 pure-u-md-18-24">
                  <input
                    ref="confirm_password"
                    className="pure-input-1"
                    id="confirm_password"
                    name="confirm_password"
                    type="password"
                    autoComplete="off"
                    required={true}
                  />
                </div>
              </div>

              <div className="pure-control-group form-footer pure-g clearfix">
                <div className="pure-u-1 pure-u-md-6-24">
                  </div>
                <div className="pure-u-1 pure-u-md-18-24">
                  <button type="submit" className="pure-button pure-button-primary">Update Password</button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    )
  }
}


export default UserSection(ChangePassword);
