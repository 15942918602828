import React from 'react';
import ClassNames from 'classnames';
import ReactGA from 'react-ga';

import AppStore from '../../stores/stores';
import LoginStore from '../../stores/login-store';
import { browserHistory } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

export default class Bookmark extends React.Component {
  constructor(){
    super();
    this.state = { bookmarked: false, visible: LoginStore.isLoggedIn() };
  }

  componentWillReceiveProps(newprops){
    this.setState(newprops);
  }

  handleClick(){
    const { title, userApiRequest } = this.props;

    if (!this.state.bookmarked) {
      AppStore.storeBookmark(title, userApiRequest, {
        onSuccess: () => {
            this.setState({ bookmarked: true });
            ReactGA.event({
              category: "User",
              action: "Added a bookmark",
              label: title
            });
        },
        onError: () => {}
      });

      return;
    }

    AppStore.removeBookmark(title, userApiRequest, {
      onSuccess: () => {
        this.setState({ bookmarked: false });
        ReactGA.event({
          category: "User",
          action: "Removed a bookmark",
          label: title
        });
      },
      onError: () => {}
    });
  }

  requestRegisterModal() {
    browserHistory.replace(`${window.location.pathname}?modal=register&restricted=1`)
  }

  render(){
    let value = (this.state.bookmarked)? "Remove Bookmark" : "Bookmark Checklist";

    let classes = ClassNames({
      'bookmark-btn' : true,
      'pure-button' : true,
      'bookmarked': this.state.bookmarked,
      'disabled': !this.state.visible
    })

    return (
      <button className={classes} onClick={ this.state.visible ? this.handleClick.bind(this) : this.requestRegisterModal.bind(this) }>
        <span>{value}</span>
        {this.state.visible ? <i className="icon-bookmark"></i>  : <i><FontAwesomeIcon icon={faLock} /></i>}
      </button>
    )
  }
}
