import React from 'react';
import { Link } from 'react-router';
import uuid from 'uuid/v4';
import ReactGA from 'react-ga';
import AppRoutes from '../../routes/routes'

export default class SearchHistory extends React.Component {
  onClick(e) {
    ReactGA.event({
      category: "User",
      action: "Popular search clicked",
      label: e.target.title
    });
  }
  render() {
    let searches = this.props.data.map((item) => {
      return(
        <li key={uuid()}>
          <Link
            onClick={this.onClick.bind(this)}
            title={item.title}
            to={{pathname: `${AppRoutes.checklists}${item._slugs[0]}`}}>
            {item.title}
          </Link>
        </li>
      )
    });

    return (
      <ul className="search-history">
        {searches}
      </ul>
    )
  }
}
