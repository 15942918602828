import React from 'react';
import SearchesHistory from '../../components/searches/search-history';

export default (props)=> (
  <div className="search-sidebar l-box">
    <div>
      <h3 className="mobile-show">Your recent searches:</h3>
      <SearchesHistory data={props.recentSearches} />
    </div>
  </div>
)
