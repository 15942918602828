import React from 'react';
import { Link } from 'react-router';
import './free-trial-expired.scss';
import AuthMixin from '../../mixins/authenticationMixin';

class FreeTrialExpired extends React.Component {
    render() {
        if (!this.props.user.free_trial_expired) {
            return '';
        }
        let to = window.location.pathname + '?modal=upgrade';

        return (<div className="free-trial-expired">
            <div className="inner-container">
                <div className="l-box tac">
                Your free trial has now expired, please <Link to={to}>upgrade</Link> to continue with full access to Neurochecklists
                </div>
            </div>
        </div>)
    }
}

export default AuthMixin(FreeTrialExpired);
