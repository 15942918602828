import React from "react";
import { Helmet } from "react-helmet";
import LoginStore from "../../stores/login-store";
import Banner from "../../components/banners/title-banner";
import StartTrialBanner from "../../components/banners/start-trial";
import Faqs from "./contact-faqs";
import Styles from "./contact.scss";

export default class Contactus extends React.Component {
  render() {
    const trialBanner = LoginStore.isLoggedIn() ? null : (
      <StartTrialBanner {...this.props} />
    );

    return (
      <div>
        <Helmet>
          <title>Neurochecklists | Frequently asked questions</title>
          <meta
            name="description"
            contents="Frequently asked questions about Neurochecklists."
          />
        </Helmet>
        <div className="contact-us">
          <Banner title="Frequently asked questions" />

          <div id="faqs" className="inner-container">
            <Faqs />
          </div>

          {trialBanner}
        </div>
      </div>
    );
  }
}
