import React from 'react';
import Classnames from 'classnames';
import { OutboundLink } from 'react-ga';

export default class SignpostItem extends React.Component {

    constructor(props) {
        super(props);

        this.hashChanged = this.hashChanged.bind(this);
        this.state = {
            highlight: window.location.hash === '#' + props.id
        };
    }

    hashChanged() {
        setTimeout(()=> this.setState({highlight: window.location.hash === '#' + this.props.id}));
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.hashChanged);
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.hashChanged);
    }
    render() {
        let classes = Classnames({ highlight: this.state.highlight });
        return (
            <li id={this.props.id} className={classes}>
                <OutboundLink eventLabel="Reference" to={this.props.url} target="_blank">{this.props.title}</OutboundLink>
            </li>
        );
    }
}
