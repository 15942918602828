import React from 'react';
import uuid from 'uuid/v4';
import { Helmet } from 'react-helmet';

import AppStore from '../../stores/stores';
import LoginStore from '../../stores/login-store';
import ChecklistHeader from './checklist-header';
import ChecklistSection from './checklist-section';
import ChecklistSidebar from './checklist-sidebar';
import ChecklistContext from '../../components/checklists/checklist-context';
import AuthMixin from '../../mixins/authenticationMixin';
import DataError from '../../components/errors/data-error';
import RecommendationError from '../../components/errors/recommendation-error';

import './checklist.scss';

class Checklist extends React.Component {
  constructor() {
    super();
    this.state = {
      title: '',
      title_footnotes: [],
      sections: [],
      signposts: [],
      topic: '',
      chapter: '',
      category: '',
      did_you_mean: false,
      bookmarked: false,
      error: false,
      video: false
    };
  }

  componentDidMount() {
    this._onChange(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.routeParams.id !== this.props.params.id) {
      this._onChange(nextProps);
    }
  }

  _onChange({ params, userApiRequest }) {
    AppStore.getChecklist(params.id, userApiRequest, {
      onSuccess: ({ data: checklist }) => {
        this.setState({
          title: checklist.data.title,
          last_updated: checklist.data.updated_at,
          title_footnotes: checklist.data.title_footnotes ? checklist.data.title_footnotes : [],
          sections: checklist.data.sections,
          signposts: checklist.data.signposts || [],
          topic: checklist.topic,
          category: checklist.part,
          chapter: checklist.chapter,
          bookmarked: checklist.bookmarked,
          show_notes: checklist.data.title,
          video: checklist.data.video
        });

        if (LoginStore.isLoggedIn()) {
          /* we don't track checklist views for logged out users @todo */
          AppStore.checklistViewed(params.id, userApiRequest);
        }
      },
      onError: error => {
        let {message, did_you_mean} = error.error;
        this.setState({ error: true, message, did_you_mean})
      }
    });
  }

  signposts() {
    return <ChecklistSection key={uuid()} title={{ text: 'References' }} list={{ data: this.state.signposts }} signposts={true} query={this.props.location.query} />;
  }

  sections() {
    return this.state.sections.map((section, key) => {
      return <ChecklistSection key={uuid()} id={key} title={section.title} list={section.list_items} query={this.props.location.query} />;
    });
  }

  render() {
    let { error, did_you_mean, title, chapter, topic, category } = this.state;
    let { params } = this.props;

    if (error) {
      return did_you_mean ? <RecommendationError {...this.state} /> : <DataError {...this.state} />;
    }

    const pageTitle = title ? title : '';


    return (

      <div className="bg-grey">
        <Helmet>
          <title>{ 'Neurochecklists | Fully referenced checklist for ' +  pageTitle }</title>
          <meta name="description" contents={'Fully referenced information about the neurological topic ' + pageTitle}></meta>

          <meta name="og:title" contents={pageTitle}></meta>
          <meta name="og:description" contents={'Fully referenced information about the neurological topic ' + pageTitle}></meta>
        </Helmet>

        <ChecklistHeader
          {...this.state}
          {...this.props}
        />

        <div className="pure-g inner-container big-padding-bottom inner-container--extra-wide">

          <div className="tablet-hide pure-u-1 checklist-sidebar pure-u-md-8-24 pure-u-lg-6-24">
            <ChecklistContext {...this.props} current={params.id} chapter={chapter} topic={topic} category={category} />
          </div>

          <article className="pure-u-1 pure-u-lg-12-24">
            <div className="l-box">
              {this.sections()}
              {this.signposts()}
            </div>

          </article>

          <aside className="pure-u-1 checklist-sidebar pure-u-lg-6-24">
            <div className="l-box">
              <ChecklistSidebar {...this.props} {...this.state} videoEnabled={false} video={this.state.video} />
            </div>
          </aside>
        </div>
      </div>
    );
  }
}

export default AuthMixin(Checklist);
