import React from 'react';
import TitleBanner from '../banners/title-banner';
import { SERVER_ERROR_SHORT } from '../../constants/messages';
import CONSTANTS from '../../constants/constants';

export default class DataError extends React.Component {
  render() {
    return (
      <section className="big-padding-bottom force-full-height">
        <TitleBanner title={this.props.message || SERVER_ERROR_SHORT} />
        <div className="inner-container">
            <p className="error-text">If this problem persists please email <a href={`mailto:${CONSTANTS.CONTACT_EMAIL}`}>{CONSTANTS.CONTACT_EMAIL}</a></p>
        </div>
      </section>
    )
  }
}

