import React from 'react';
import { Link } from 'react-router';

export default ({ totalPages, location })=>{
  if (totalPages === 1 || totalPages === 0) {
    return <div></div>
  }

  let {query, pathname:url} = location;
  let nextPageQuery = Object.assign({}, query);
  nextPageQuery.page = +nextPageQuery.page + 1;

  let previousPageQuery = Object.assign({}, query);
  previousPageQuery.page = +previousPageQuery.page - 1;

  let previousPage = (
    <Link
      to={{pathname: url,  query: previousPageQuery}}
    >
      <div className="prevPage">
        <div className="page-text-direction">&#10094; Previous Page</div>
        <div className="page-count">{+query.page - 1}&nbsp;of&nbsp;{totalPages}</div>
      </div>
    </Link>
  )

  let nextPage = (
    <Link
      to={{pathname: url,  query: nextPageQuery}}
    >
      <div className="nextPage">
        <div className="page-text-direction">Next Page &#10095;</div>
        <div className="page-count"> {+query.page + 1 }&nbsp;of&nbsp;{totalPages}</div>
      </div>
    </Link>
  );

  if(query && query.page == 1) {
    previousPage = '';
  }

  if(query && query.page == totalPages) {
    nextPage = '';
  }

  return (
    <div className="pure-g">
      <div className="pure-u-1 pagination">
        <div className="l-box">
          {previousPage}
          {nextPage}
        </div>
      </div>
    </div>
  )
}
