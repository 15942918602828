import React from 'react';
import { withRouter } from 'react-router';
import Api from '../../api/api';
import LoginActions from '../../actions/login-actions'
import LoginStore from '../../stores/login-store';
import AppRoutes from '../../routes/routes';
import Loading from '../loading/loading';
import Notice from '../notices/notice';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
    this.state = {
      error: false,
      error_messages: [],
      loading: false,
      email_valid: true,
      email_error: false
    };
    this.captcha = Api.captcha();
  }

  componentDidMount() {
    LoginStore.addChangeListener( this._onChange );
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener( this._onChange );
  }

  validateInput(data) {
    var error_messages = [];

    if ( data.password !== data.rePassword) {
      error_messages.push('The Passwords you entered do not match');
    }

    if ( !this.state.email_valid ) {
      error_messages.push('The email you entered is invalid. Reason: ' + this.state.email_reason);
    }

    if (+data.captcha != this.captcha.result) {
      error_messages.push("Your answer was incorrect please try again");
    }

    for (var key in data) {
      if (!data[key].length) {
        error_messages.push('Please fill in all fields correct');
        break;
      }
    }

    return error_messages;
  }

  parseSeverResponse(data) {
    var error_messages = [];

    if (typeof(data) === 'string') {
      error_messages.push(data);
    } else {
      for (var key in data) {
        if (!data.hasOwnProperty(key)) {
          continue;
        }

        if (Array.isArray(data[key])) {
          data[key].forEach( msg => error_messages.push(`${key} ${msg}`) )
        } else {
          error_messages.push(data[key])
        }
      }
    }

    return error_messages;
  }

  renderErrors() {
    const errors = this.state.error_messages.map((item, index) => <li key={index}>{item}</li>)

    return (
      <Notice alert='alert-danger'>
        <ul className="list-errors">
          {errors}
        </ul>
      </Notice>
    )
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    const data = {
      name: this.refs.name.value,
      email: this.refs.email.value,
      password: this.refs.password.value,
      rePassword: this.refs.passwordConfirmation.value
    };
    const captcha   = this.refs.captcha.value;

    let error_messages = this.validateInput(Object.assign({}, data, {captcha}));
    if (error_messages.length) {
      this.setState({
        loading: false,
        error: true,
        error_messages
      });
    } else {
      LoginActions.register(data);
    }
  }

  _checkEmail() {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    var email = this.refs.email.value;
    var headers = this.props.userApiRequest;

    var timeout = setTimeout(function() {
      Api.validateEmail(email, headers, {
        onSuccess: (res) => {
          this.setState({
            email_valid: res.data.is_valid,
            email_reason: res.data.reason,
          });
        },
        onError: (res) => {
          // the API is broken, all results come back as 200 if it works at all.
          this.setState({
            email_valid: true,
            email_reason: false
          });
        },
      });
    }.bind(this), 500);

    this.setState({timeout: timeout});
  }

  _onChange() {
    if (!LoginStore.errors) {
      return;
    }

    this.setState({
      loading: false,
      error: true,
      error_messages: this.parseSeverResponse(LoginStore.errors)
    });
  }

  render() {
    return (
      <div>
        {(this.state.loading)? <Loading text="Please wait..." /> : null }
        <form className="pure-form pure-form-aligned login-form" onSubmit={this.handleSubmit.bind(this)}>
          <span className="login-form-title">{ this.props.restricted ?
            "This feature requires an account at Neurochecklists. Sign up to receive full access to all checklists and their references plus bookmarking and more." :
            "For full access to the hand-curated data at Neurochecklists please register"
          }</span>
          {this.state.error && this.renderErrors()}
          <fieldset>
            <div className="pure-g text-field clearfix">
              <div className="pure-u-1-4 fl">Name <span className="cred">*</span></div>
              <input className="pure-u-3-4" id="f_name" type="text" ref="name" name="name" autoComplete="name" required />
            </div>

            <div className="pure-g text-field clearfix">
              <div className="pure-u-1-4 fl">Email <span className="cred">*</span></div>
              <input className="pure-u-3-4" id="email" type="email" ref="email" name="email" autoComplete="email" required onChange={this._checkEmail.bind(this)}/>
            </div>

            <div className="pure-g text-field clearfix">
              <div className="pure-u-1-4 fl" >Password <span className="cred">*</span></div>
              <input className="pure-u-3-4" id="password" type="password" ref="password" name="password" required />
            </div>

            <div className="pure-g text-field clearfix">
              <div className="pure-u-1-4 fl" style={{marginTop: '15'}}>Confirm Password <span className="cred">*</span></div>
              <input className="pure-u-3-4" id="password-confirmation" type="password" ref="passwordConfirmation" name="password-confirmation" required />
            </div>

            <div className="pure-checkbox clearfix l-box">
              <label className="pure-checkbox">
                  <input id="privacy" type="checkbox" ref="privacy" required />
                  <span>I agree to the service <a href={AppRoutes.tcs} className="link" target="_blank">Terms and Conditions</a>, <a className="link" href={AppRoutes.privacy} target="_blank">Privacy Policy</a>, and the <a className="link" href={AppRoutes.data} target="_blank">Personal Data Policy</a>. <span className="cred">*</span></span>
              </label>
            </div>

            <div className="pure-g login-captcha clearfix">
              <div className="pure-u-1" style={{marginTop: 15, marginBottom: 10}}>To prove that you are a human please answer the following question: <span className="cred">*</span></div>
              <input className="pure-u-1" id="captcha" type="text" ref="captcha"
               placeholder={`What is the sum of ${this.captcha.leftside} + ${this.captcha.rightside}? *`} required />
            </div>

            <div className="login-form-submit">
              <button type="submit" className="pure-button login-submit-button ">Sign up</button>
            </div>

          </fieldset>
        </form>
      </div>
    );
  }
}

export default withRouter(Register);
