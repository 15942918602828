import React from 'react';
import {Helmet} from 'react-helmet';

import TitleBanner from '../../components/banners/title-banner';

export default function UserGuide() {
  return (
    <div>
      <Helmet>
        <title>Neurochecklists | User Guide</title>
      </Helmet>
      <TitleBanner title="Guide" />
      <div className="inner-container small-top-padding big-padding-bottom">
        <div className="pure-g guide-content">
          <div className="pure-u-1">
            <div className="l-box">
              <h3>NEUROCHECKLISTS structure</h3>
              <ul>
                <li>The database consists of subspecialty categories</li>
                <li>Each category is made up of chapters</li>
                <li>Each chapter is made up of topics</li>
                <li>Each topic consists of the checklists</li>
              </ul>

              <h3>NEUROCHECKLISTS exploration</h3>
              <ul>
                <li>Explore by search boxes available on all pages</li>
                <li>Explore the index for chapters or by clicking on the 'explore' tab</li>
              </ul>

              <h3>NEUROCHECKLISTS contents</h3>
              <ul>
                <li>History</li>
                <li>Clinical examination</li>
                <li>Investigations</li>
                <li>Treatment</li>
              </ul>

              <h3>NEUROCHECKLISTS references</h3>
              <ul>
                <li>All checklists have a reference section</li>
                <li>The references are sourced from standard neurological texts and journals</li>
                <li>The most important references are shown with the checklist title</li>
                <li>Clicking on the reference number in the text takes you to the reference citation</li>
                <li>Clicking on the reference will open the PubMed abstract of the reference</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
