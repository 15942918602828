import React from 'react';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import Moment from 'moment';
import braintreeDropIn from 'braintree-web-drop-in';
import queryString from 'query-string';

import { SERVER_ERROR_SHORT } from '../../constants/messages';
import AuthMixin from '../../mixins/authenticationMixin';
import authAPI from '../../api/authApi';
import AppAuthStore from '../../stores/login-store';
import AppRoutes from '../../routes/routes';
import upgradePlans from '../../data/upgrade-plans';
import threedstatuses from './threedsecureresponses';

class PaymentModal extends React.Component {
    constructor() {
        super();
        this.state = {
            title: 'Checkout',
            displayCheckout: false,
            billingDate: '',
            nextBillingDate: '',
            errorMessage: false,
            loading: true,
            success: false,
            error: false,
        };

        this._dropinCallback = this._dropinCallback.bind(this);
        this._submit = this._submit.bind(this);
    }

    _dropinCallback(createErr, instance) {
        if (createErr) {
            // Handle any errors that might've occurred when creating Drop-in
            if ('Rollbar' in window) {
                Rollbar.error('Dropin could not be created', createErr);
            }
            return;
        }

        this.setState({ loading: false, errorMessage: false });

        this.refs.upgrade.addEventListener('click', e => {
            this.setState({
                errorMessage: false,
            });

            e.preventDefault();
            instance.requestPaymentMethod(
                {
                    threeDSecure: {
                        amount: this.props.amount,
                        email: this.props.user.email,
                    },
                },
                (requestPaymentMethodErr, payload) => {
                    if (requestPaymentMethodErr) {
                        // Handle any errors that might've occurred when creating Drop-in
                        console.error(requestPaymentMethodErr);
                        return;
                    }

                    if (payload.type === 'CreditCard') {
                        let statusDetails = threedstatuses[payload.threeDSecureInfo.status];

                        if (statusDetails.continue === false) {
                            if (statusDetails.retry === true) {
                                this.setState({
                                    errorMessage: 'A temporary 3DSecure error has occurred. Please retry',
                                });
                            } else {
                                this.setState({
                                    errorMessage:
                                        'We have been unable to verify your card with 3DSecure, please use a different payment method.',
                                });
                            }
                            return;
                        }

                        // Submit payload.nonce to your server
                        this._submit(payload.nonce, payload.threeDSecureInfo.threeDSecureAuthenticationId);
                    } else {
                        this._submit(payload.nonce);
                    }
                }
            );
        });
    }

    componentDidMount() {
        authAPI.getPaymentToken(this.props.userApiRequest, {
            onSuccess: ({ data }) => {
                const clientToken = data.client_token;

                braintreeDropIn.create(
                    {
                        authorization: clientToken,
                        threeDSecure: true,
                        container: '#bt-dropin',
                        paypal: {
                            flow: 'vault',
                        },
                    },
                    this._dropinCallback
                );
            },
            onError: () => {},
        });
    }

    componentDidCatch(e) {
        if ('Rollbar' in window) {
            Rollbar.error('Unable to process payment', e);
        }
    }

    selectedPlan() {
        return upgradePlans.filter(item =>
            Boolean(item.plan_id === queryString.parse(this.props.location.search).plan_id)
        )[0];
    }

    _submit(nonce, threedsecure) {
        this.setState({ loading: true });

        let data = {
            payment_method_nonce: nonce,
            plan_id: queryString.parse(this.props.location.search).plan_id,
            promo: queryString.parse(this.props.location.search).promo,
        };

        if (threedsecure) {
            data['three_d_secure_authentication_id'] = threedsecure;
        }

        authAPI.sendPaymentDetails(this.props.userApiRequest, data, {
            onSuccess: ({ data }) => {
                if (!data.error) {
                    AppAuthStore.updateUser(this.props.userApiRequest, { account_type: data.account_type });

                    this.setState({
                        title: 'Your account has been upgraded!',
                        billingDate: data.billing_date,
                        nextBillingDate: data.next_billing_date,
                        loading: false,
                        success: true,
                    });
                }
            },
            onError: e => {
                if ('Rollbar' in window) {
                    Rollbar.error('Payment error occurred', e);
                }
                this.setState({
                    title: SERVER_ERROR_SHORT,
                    error: true,
                    success: false,
                    loading: false,
                });
            },
        });
    }

    renderError() {
        return (
            <div className="pure-g">
                <div className="pure-u-1">
                    <div className="downgrade-l-box">
                        <div className="downgrade-modal-text downgrade-modal-text--center">
                            <p>
                                This could be due to a number of reasons. Please contact us at{' '}
                                <strong>info@neurochecklists.com</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSuccess() {
        return (
            <div className="pure-g">
                <div className="pure-u-1">
                    <div className="downgrade-l-box">
                        <div className="downgrade-modal-text downgrade-modal-text--center">
                            <p>
                                Congratulations, your Starter plan has now been upgraded to the{' '}
                                <strong>{this.selectedPlan().type} plan</strong>. You can now enjoy unlimited searches.
                                Your subscription starts today {Moment(this.state.billingDate).format('MMMM D, YYYY')}.
                                Your next billing date will be on or after{' '}
                                {Moment(this.state.nextBillingDate).format('MMMM D, YYYY')}.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderForm() {
        let plan = this.selectedPlan();

        return (
            <div>
                <form
                    id="paypal-container"
                    method="post"
                    ref="form"
                    action="/checkout"
                    onSubmit={e => e.preventDefault()}
                >
                    <div className="downgrade-l-box-2">
                        <h2>
                            You are purchasing a {plan.type} subscription with £{this.props.amount} due now
                        </h2>

                        <div className="paypal-container" id="bt-dropin"></div>

                        {this.state.errorMessage ? (
                            <div className="downgrade-modal-text downgrade-modal-text--center">
                                <p className="threedsecure-error">{this.state.errorMessage}</p>
                            </div>
                        ) : null}

                        <p>
                            By clicking upgrade you confirm that you have read, understood and accepted the{' '}
                            <a className="black-link" href={AppRoutes.tcs} target="blank">
                                Terms &amp; Conditions
                            </a>
                        </p>

                        <button
                            ref="upgrade"
                            className="pure-button checkout-button"
                            disabled={this.state.loading}
                            type="submit"
                        >
                            Upgrade
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    renderLoading() {
        return (
            <div className="loading-overlay">
                <div className="loading-content-container">
                    <span className="loading-icon--brain"></span>
                    <span className="loading-text">
                        Please wait... <br /> Please do not close this popup
                    </span>
                </div>
            </div>
        );
    }

    render() {
        const { error, success, loading } = this.state;
        const headerClasses = classnames({
            cgreen: success,
            cred: error,
        });

        return (
            <div className="upgrade-modal upgrade-modal--payment">
                <span className="modal-close">X</span>
                <header className="tac mb30">
                    <h1 className={headerClasses}>{this.state.title}</h1>
                </header>
                {loading ? this.renderLoading() : null}
                {success ? this.renderSuccess() : null}
                {error ? this.renderError() : null}
                {!success && !error ? this.renderForm() : null}
            </div>
        );
    }
}

export default AuthMixin(withRouter(PaymentModal));
