import React from 'react';
import { Link } from 'react-router';
import { OutboundLink } from 'react-ga';
import AppRoutes from '../../routes/routes';
import './footer.scss';

export default () => {
  return (
    <footer className="page-footer">
      <div className="inner-container">
        <div className="l-box">
          <nav className="page-footer--menu">
            <ul>
              <li><OutboundLink target="blank" eventLabel="Blog link" to={AppRoutes.news} title="The Neurology Lounge Podcast">The Neurology Lounge Podcast</OutboundLink></li>
              <li><Link to={AppRoutes.pricing}>Pricing</Link></li>
              <li><Link to={AppRoutes.about}>About</Link></li>
              <li><Link to={AppRoutes.faqs}>Faqs</Link></li>
              <li><Link to={AppRoutes.contact}>Contact</Link></li>
            </ul>
          </nav>
          <div className="social-networks">
            <OutboundLink eventLabel="Facebook" className="social-networks--icon sicon-fb-dark" to={AppRoutes.facebook} target="_blank">Facebook</OutboundLink>
            <OutboundLink eventLabel="Twitter" className="social-networks--icon sicon-twitter-dark" to={AppRoutes.twitter} target="_blank">Twitter</OutboundLink>
            <OutboundLink eventLabel="LinkedIn" className="social-networks--icon sicon-in-dark" to={AppRoutes.linkedin} target="_blank">Linkedin</OutboundLink>
          </div>
          <div className="credits">
            <span>&copy; {new Date().getFullYear()} Neurochecklists Ltd. All Rights Reserved.</span>
            <ul>
              <li><Link to={AppRoutes.privacy}>Privacy Policy</Link></li>
              <li><Link to={AppRoutes.data}>Data Policy</Link></li>
              <li><Link to={AppRoutes.tcs}>Terms and Conditions</Link></li>
              <li><Link to={AppRoutes.credits}>Site Credits</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
