import uuid from "uuid/v4";
import { Link } from "react-router";

import AuthMixin from "../../mixins/authenticationMixin";
import AppRoutes from "../../routes/routes";
import AppStore from "../../stores/stores";
import LocalLoading from "../loading/local-loading";
import classNames from "classnames";
import CollapsibleLi from "./collapsible-li";

class ChecklistContext extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      error: false,
    };
    this.relatedChecklists = this.relatedChecklists.bind(this);
  }

  componentDidMount() {
    let { userApiRequest } = this.props;

    AppStore.getContext(userApiRequest, {
      onSuccess: ({ data }) => this.setState({ data }),
      onError: () => this.setState({ error: true }),
    });
  }

  componentDidCatch(e) {
    console.error(e);
  }

  relatedChecklists(current, chapter, topic, category) {
    return (
      <ul className="siblings cteal">
        {this.state.data
          .filter((item) => {
            return item.title !== this.state.title;
          })
          .map((part) => {
            return (
              <CollapsibleLi
                current={this.props.category.slug === part.slug}
                key={uuid()}
                entry={part.title}
              >
                <ul className="clblue">
                  {part.chapters.map((chapter) => {
                    return (
                      <CollapsibleLi
                        current={this.props.chapter.slug === chapter.slug}
                        key={uuid()}
                        entry={chapter.title}
                      >
                        <ul className="cred">
                          {chapter.topics.map((topic) => {
                            return (
                              <CollapsibleLi
                                current={this.props.topic.slug === topic.slug}
                                key={uuid()}
                                entry={topic.title}
                              >
                                <ul className="spgreen noicon">
                                  {topic.checklists.map((checklist) => {
                                    let classes = classNames({
                                      "is-current": current === checklist.slug,
                                    });
                                    return (
                                      <li className={classes} key={uuid()}>
                                        <Link to={`${AppRoutes.checklists}${checklist.slug}`}>{checklist.title}</Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </CollapsibleLi>
                            );
                          })}
                        </ul>
                      </CollapsibleLi>
                    );
                  })}
                </ul>
                <hr/>
              </CollapsibleLi>
            );
          })}
      </ul>
    );
  }

  render() {
    let { current, chapter, topic, category } = this.props;
    let { data, error } = this.state;

    if (error) {
      return null;
    }

    return (
      <div className="l-box full-height">
        {data.length ? (
          <div className="context-overview">
            <header>
              <div className="classification">
                <h2>See also</h2>
              </div>
              <hr />
            </header>
            <div className="scroll">{this.relatedChecklists(current, chapter, topic, category)}</div>
          </div>
        ) : (
          <LocalLoading />
        )}
      </div>
    );
  }
}

export default AuthMixin(ChecklistContext);
