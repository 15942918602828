import React from 'react';
import Api from '../../api/api';

export default class Notice extends React.Component {
  componentDidMount(){
    Api.scrollCentre(`#${this.refs.notice.id}`);
  }

  componentWillReceiveProps(){
    Api.scrollCentre(`#${this.refs.notice.id}`);
  }

  render(){
    return (
      <div id="notice" ref="notice" className="notice-area">
        <div className="l-box">
          {this.props.children}
        </div>
      </div>
    )
  }
}
