import React from 'react';
import { Link } from 'react-router';
import { Helmet } from 'react-helmet';
import { SERVER_ERROR } from '../../constants/messages';
import Moment from 'moment';
import Api from '../../api/api';
import AppRoutes from '../../routes/logged-in';
import AppAuthStore from '../../stores/login-store';
import UpgradeButton from '../../components/upgrade/upgrade-button';
import DowngradeButton from '../../components/upgrade/downgrade-button';
import UserSection from './user-section';
import AppSettingsNotice from './settings-notice';
import Loading from '../../components/loading/loading';
import AccountDownloadLink from './account-download-button';

import './user-details.scss';

class UserDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      success: false,
      fail: false,
      empty_fields: false,
      loading: false,
      ...AppAuthStore.getSessionUserData()
    };
    this._onChange = this._onChange.bind(this);
  }

  componentDidMount() {
    AppAuthStore.addChangeListener(this._onChange);
  }

  componentWillUnmount() {
    AppAuthStore.removeChangeListener(this._onChange);
  }

  _onChange(data) {
    if (data && data.success) {
      this.setState({
        success: !!data.success,
        fail: data.success? false : true,
        empty_fields: false,
        loading: false
      });
    } else {
      this.setState({
        fail: true,
        loading: false
      });
    }

  }

  renderNotice() {
    let success =  <p className="successMsg">Successfully Updated profile.</p>
    let fail = <p className="failMsg">{SERVER_ERROR}</p>
    let empty_fields = <p className="failMsg">Please complete all fields to continue.</p>
    let msg;

    if (this.state.fail && this.state.empty_fields) {
      msg = empty_fields ;
    } else if (this.state.success) {
      msg = success;
    } else {
      msg = fail;
    }

    return (
      <AppSettingsNotice>
        {msg}
      </AppSettingsNotice>
    );
  }

  validateData(data) {
    if (Api.hasEmptyFields(data)) {
      return {error: true}
    }
    return {error: false}
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true })

    const {name, email, receive_messages} = this.refs;
    const data = {
      name: name.value,
      email: email.value,
      receive_messages: receive_messages.checked ? 1 : 0
    };

    if (this.validateData(data).error) {
      return this.setState({
        fail: true,
        empty_fields: true,
        loading: false
      });
    }

    AppAuthStore.updateUser(this.props.userApiRequest, data);
  }

  upgradable() {
    const {
      account_type
    } = AppAuthStore.getSessionUserData();

    if ( account_type === "Starter" ) {
      return <UpgradeButton {...this.props} />
    } else {
      return <DowngradeButton {...this.props} />
    }
  }

  render() {
    const {
      account_type,
      searches_left,
      created_at,
      name,
      email,
      receive_messages
    } = this.state;

    let success = (this.state.success || this.state.fail)? this.renderNotice() : null;

    return (
      <div>
        <Helmet>
          <title>Neurochecklists | Your profile</title>
        </Helmet>
        <div className="user-section-form force-80-height">
          {success}
          <form onSubmit={this.handleSubmit.bind(this)} className="pure-form settings-form">
            {(this.state.loading)? <Loading text="Please wait..." /> : null }
            <div className="l-box">
              <div className="pure-control-group pure-g">
                <div className="pure-u-8-24 pure-u-md-8-24 tal">
                  <span>Account Type:</span>
                </div>
                <div className="pure-u-16-24 pure-u-md-16-24 tar">
                  <span>{account_type}</span>
                  { (account_type === "Starter") ? (
                    <span className="searches-left">
                      {(searches_left > 1000)? `Unlimited Searches until end of billing cycle` : `(${searches_left} Searches Left)`}
                    </span>
                  ): null }
                  { this.upgradable() }
                </div>
              </div>
              <div className="pure-control-group pure-g">
                <div className="pure-u-2-5 pure-u-md-8-24 tal">
                  <span>Member Since:</span>
                </div>
                <div className="pure-u-3-5 pure-u-md-16-24 tar">
                  <span>{Moment(created_at).format('D MMMM, YYYY')}</span>
                </div>
              </div>
              <div className="pure-control-group pure-g">
                <div className="pure-u-1 pure-u-md-12-24 sub-label">
                  <label htmlFor="name">Name</label>
                </div>
                <div className="pure-u-1 pure-u-md-12-24 sub-label">
                  <input
                    ref="name"
                    className="pure-input-1"
                    id="name" name="account[name]"
                    type="text"
                    defaultValue={name}
                    required={true}
                  />
                </div>
              </div>

              <div className="pure-control-group pure-g">
                <div className="pure-u-1 pure-u-md-12-24 sub-label">
                  <label htmlFor="email">Email</label>
                </div>
                <div className="pure-u-1 pure-u-md-12-24 sub-label">
                  <input
                    ref="email"
                    className="pure-input-1"
                    id="email" name="account[email]"
                    type="email"
                    defaultValue={email}
                    required={true}
                  />
                </div>
              </div>

              <div className="pure-control-group pure-g sub-row">
                <div className="pure-u-1 pure-u-md-12-24 sub-label">
                  <label htmlFor="receive_messages">Subscribe to system messages?</label>
                </div>
                <div className="pure-u-1 pure-u-md-12-24 sub-checkbox">
                  <input
                    ref="receive_messages"
                    className="pure-input-1"
                    id="receive_messages"
                    name="account[receive_messages]"
                    type="checkbox"
                    value="true"
                    checked={receive_messages == 1}
                    onChange={() => this.setState({receive_messages: !receive_messages})}
                  />
                </div>
              </div>

              <div className="pure-control-group form-footer pure-g clearfix">
                <div className="pure-u-1-2 pure-u-md-8-24">
                  <AccountDownloadLink />
                  <Link to={AppRoutes.deleteAccount} className="delete-link">Delete my account</Link>
                </div>
                <div className="pure-u-1-2 pure-u-md-16-24">
                  <button type="submit" className="pure-button pure-button-primary">Save Changes</button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default UserSection(UserDetails);
