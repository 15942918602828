import React from 'react';
import { Helmet } from 'react-helmet';
import SearchBanner from '../../components/banners/search-banner';
import NewsSection from './news-section';
import AppStore from '../../stores/stores';
import Subscribe from '../../components/subcribe/subscribe';
import Testimonials from '../../components/testimonials/index';
import LoginStore from '../../stores/login-store';
import AuthMixin from '../../mixins/authenticationMixin';
import VideoSection from './video-section';

import './homepage.scss';

class HomePage extends React.Component {
  constructor() {
    super();
    this.state = {
      recentSearches: [],
      video: false
    };
  }

  componentDidMount() {
    AppStore.loadHomepage(this.props.userApiRequest, {
      onSuccess: ({ data }) => {
        this.setState({
          recentSearches: data.recent_searches,
          video: data.video
        });
      },
      onError: () => {}
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="description" contents="Neurochecklists gives you quick and easy access to an extensive range of neurological topics. With an extensive range of checklists across the spectrum                             of neurology, neurochecklists offer relevant and practical information in a clear and concise format. Each checklist is fully referenced and linked to                             updated internet sources or abstracts. Neurochecklists.com simplifies neurological diagnosis and management on the ward or in the clinic."></meta>
          <link rel="canonical" href={'https://neurochecklists.com'} />
        </Helmet>

        <SearchBanner {...this.props} {...this.state} />

        {LoginStore.isLoggedIn() ? (
          ''
        ) : (
          <div>
            <div className="bg-grey padded-content">
              <div className="inner-container">
                <div className="pure-g">
                  <div className="pure-u-1">
                    <div className="l-box bbgrey">
                      <div className="centred-text-80 tac">
                        <h2 id="findout-more">Everything neurology a click away</h2>
                        <p>
                          Neurochecklists gives you quick and easy access to an extensive range of neurological topics. With an extensive range of checklists across the spectrum
                          of neurology, neurochecklists offer relevant and practical information in a clear and concise format. Each checklist is fully referenced and linked to
                          updated internet sources or abstracts. Neurochecklists.com simplifies neurological diagnosis and management on the ward or in the clinic.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pure-g tac icon-text-section">
                  <div className="pure-u-1-2 pure-u-md-1-4">
                    <div className="l-box">
                      <img className="pure-img centered-content" src={require('../../../images/referenced.svg')} width="100" height="110" alt="Fully referenced" />
                      <span className="icon-subtext">Fully referenced and linked</span>
                    </div>
                  </div>
                  <div className="pure-u-1-2 pure-u-md-1-4">
                    <div className="l-box">
                      <img className="pure-img centered-content" src={require('../../../images/quick.svg')} width="100" height="110" alt="Quick and easy" />
                      <span className="icon-subtext">Quick and easy</span>
                    </div>
                  </div>
                  <div className="pure-u-1-2 pure-u-md-1-4">
                    <div className="l-box">
                      <img
                        className="pure-img centered-content"
                        src={require('../../../images/practical.svg')}
                        width="100"
                        height="110"
                        alt="Practical and clinically relevant"
                      />
                      <span className="icon-subtext">Practical and clinically relevant</span>
                    </div>
                  </div>
                  <div className="pure-u-1-2 pure-u-md-1-4">
                    <div className="l-box">
                      <img className="pure-img centered-content" src={require('../../../images/extensive.svg')} width="100" height="110" alt="Extensive range of topics" />
                      <span className="icon-subtext">Extensive range of topics</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <NewsSection newsItems={this.state.newsItems}  video={this.state.video}/>

        <Subscribe />
        {LoginStore.isLoggedIn() ? '' : <Testimonials />}
      </div>
    );
  }
}

export default AuthMixin(HomePage);
