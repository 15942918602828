import React from 'react';
import ClassNames from 'classnames';

import ApiEndPoints from '../../constants/endpoints';
import AppStore from '../../stores/stores';
import authenticationMixin from '../../mixins/authenticationMixin';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePowerpoint, } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import "./powerpoint-button.scss";

class PowerPointButton extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      baseUrl: ApiEndPoints.DOWNLOAD_POWERPOINT
    };
  }

  waitForDownload(title, token) {
    const { userApiRequest } = this.props;

    return new Promise(function(resolve, reject) {
      AppStore.powerpointReady(title, userApiRequest, {
        onSuccess: ({response, data}) => {
          if (response.status === 202) {
              return waitForDownload(title, token);
          }

          if (response.statusCode) {
            resolve(title, token);
          }
        },
        onError: (err) => {
          reject(err);
        }
      });
    });
  }

  componentDidMount() {
    const { userApiRequest, readOnly } = this.props;

    if (readOnly) {
      this.setState({loading: false});
      return;
    }

    this.setState({loading: true});

    AppStore.downloadToken(userApiRequest, {
        onSuccess: ({data}) => {
          this.setState({
            loading: false,
            token: data.token,
            url: this.state.baseUrl.replace(/:id/, this.props.params.id)
          });
        },
        onError: () => {
            this.setState({loading: true});
            setTimeout(this.handleClick, 2500);
        }
    });
  }

  render() {
    let { readOnly } = this.props;

    let classes = ClassNames({
      'pptx-btn' : true,
      'pure-button' : true,
      readonly: readOnly
    });

    let content = (
      <div>
        {this.state.loading ? <i><FontAwesomeIcon size="2x" spin icon={faSpinner} /></i> : <i><FontAwesomeIcon size="2x" icon={faFilePowerpoint} /></i>}
        <div>Slideshow (pptx)</div>
      </div>
    );

    return readOnly ?
      (
        <a className={classes} href="#" onClick={e => e.preventDefault()} title="Subscribe to download a PPTX file with the contents of this checklist.">
          {content}
        </a>
      ) : !this.state.token ? null : (
        <a className={classes} href={this.state.url + '?t=' + this.state.token} title="Download a PPTX file with the contents of this checklist.">
          {content}
        </a>
      );
  }
}

export default authenticationMixin(PowerPointButton);
