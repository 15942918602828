import React from 'react';
import LoginActions from '../../actions/login-actions';
import LoginStore from '../../stores/login-store';
import Loading from '../loading/loading';

import './login.scss';

class Login extends React.Component {
  constructor() {
    super();
    this._onChange = this._onChange.bind(this);
    this.state = {
      error: false,
      error_message: '',
      loading: false
    };
  }

  componentDidMount() {
    LoginStore.addChangeListener( this._onChange );
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener( this._onChange );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    let email = this.refs.email.value;
    let password = this.refs.password.value;

    if (email.length && password.length) {
      LoginActions.login(email, password);
      return;
    }

    this.setState({
      loading: false,
      error: true,
      error_message: 'Please complete all fields!'
    });
  }

  handleClick(e) {
    e.preventDefault();
    LoginActions.forgotPassword();
  }

  _onChange(payload) {
    if (LoginStore.errors) {
      return this.setState({
        loading: false,
        error: true,
        error_message: LoginStore.errors
      });
    }

    if (LoginStore.isLoggedIn()) {
      // ditch the modal querystring and just redirect to the page in question
      window.history.replaceState(null, null, window.location.pathname);
      window.location.reload();
    }
  }

  render() {
    return (
      <div>
        {(this.state.loading)? <Loading text="Please wait..." /> : null }

        <form
          className="pure-form login-form"
          onSubmit={this.handleSubmit.bind(this)}
        >
          <span className="login-form-title">Enter your email and password to sign in</span>
          {
            (this.state.error) ? (
              <div className='alert alert-danger'>
                <p>{this.state.error_message}</p>
              </div>
            ): null
          }
          <fieldset>
            <div className="pure-g text-field clearfix">
              <div className="pure-u-1-5 fl">Email*</div>
              <input className="pure-u-4-5" id="email" type="email" ref="email" name="email" placeholder="Your Email" required />
            </div>

            <div className="pure-g text-field clearfix">
              <div className="pure-u-1-5 fl">Password*</div>
              <input className="pure-u-4-5" id="password" type="password" ref="password" name="password" placeholder="Your Password" required />
            </div>
          </fieldset>
          <div className="login-form-submit">
            <button type="submit" className="pure-button login-submit-button ">Login</button>
          </div>
          <div className="pure-g login-form-footer">
            <div className="pure-u-1 tac">
              Forgot your password? <span className="link" onClick={this.handleClick}>Click here</span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
