import React from "react";
import { Link } from "react-router";
import uuid from "uuid/v4";
import { OutboundLink } from "react-ga";

import NoteField from "../../components/notes/field";
import AppRoutes from "../../routes/routes";
import DownloadPPTX from "./powerpoint-button";
import LoginStore from "../../stores/login-store";
import authenticationMixin from "../../mixins/authenticationMixin";

import "./checklist-sidebar.scss";

class ChecklistSidebar extends React.Component {
  render() {
    let { video, videoEnabled } = this.props;
    let notesEnabled =
      this.props.userLoggedIn && this.props.user.account_type !== "Starter";
    return (
      <div>
        {videoEnabled && video ?
          <div className="tablet-hide">
                  <div className="pure-control-group">
                    <div className="video-wrapper">
                      <iframe src={`https://www.youtube.com/embed/${video}`} title="Neurochecklists Video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </div>
            <hr />
          </div>
          : null
        }
        <div>
          <div className="checklist-sidebar-section">
            <header className="">
              <div className="classification">
                <h2>
                  Downloads
                  {!LoginStore.isLoggedIn() ||
                  this.props.user.free_trial_expired
                    ? " (Subscribers only)"
                    : ""}
                </h2>
              </div>
            </header>
            <ul className="downloads">
              <DownloadPPTX
                {...this.props}
                readOnly={
                  !LoginStore.isLoggedIn() || this.props.user.free_trial_expired
                }
              />
            </ul>
          </div>
          <hr />
        </div>
        {this.props.show_notes ? (
          <div className="checklist-sidebar-section">
            <header className="">
              <div className="classification">
                <h2>Notes</h2>
              </div>
            </header>
            <div className="classification">
              <div className="checklist-user-notes">
                <NoteField
                  checklist={this.props.params.id}
                  readOnly={!notesEnabled}
                />
              </div>
            </div>

            <hr />
          </div>
        ) : null}

        <div className="checklist-section-footer">
          <header className="">
            <div className="classification">
              <h2>Share</h2>
            </div>
          </header>

          <div className="l-box">
            <ul className="social-media-icons">
              <li>
                <OutboundLink
                  eventLabel="Facebook"
                  to={AppRoutes.facebookShare
                    .replace(":title", this.props.title + " on Neurochecklists")
                    .replace(":url", window.location.href)}
                  target="_blank"
                  className="icons sicon-fb"
                ></OutboundLink>
              </li>
              <li>
                <OutboundLink
                  eventLabel="Twitter"
                  to={AppRoutes.twitterShare
                    .replace(":title", this.props.title + " on Neurochecklists")
                    .replace(":url", window.location.href)}
                  target="_blank"
                  className="icons sicon-twitter"
                ></OutboundLink>
              </li>
            </ul>
            <p>
              Do you have any feedback about the content on this page? &nbsp;
              <Link
                to={{
                  pathname: `${this.props.location.pathname}`,
                  query: { modal: "feedback" },
                }}
              >
                Let us know
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default authenticationMixin(ChecklistSidebar);
