import React from 'react';
import { browserHistory, withRouter } from 'react-router';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import Classnames from 'classnames';

import Api from '../../api/api';
import LoginStore from '../../stores/login-store';

import Login from './login';
import Register from './register';
import ForgotPassword from './forgot-password';
import PasswordReset from './password-reset';
import Thankyou from './thanks-for-sign-up';
import VerifyToken from './verify-token';
import PaymentModal from '../upgrade/payment-modal';
import UpgradeModal from '../upgrade/upgrade-modal';
import DowngradeModal from '../upgrade/downgrade-modal';
import FeedbackModal from '../feedback/feedback-modal';

import './login-modal.scss';

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentlySelected: props.currentlySelected };
    this.updateURL = this.updateURL.bind(this);
    this._onChange = this._onChange.bind(this);
    this.expectedModals = [
      "login",
      "register",
      "forgot-password",
      "password-reset",
      "thank-you",
      "verify-token",
      "upgrade",
      "payment",
      "cancel-subscription",
      "feedback",
    ];
  }

  updateURL(modalState, data = {}) {
    const query = queryString.stringify(Object.assign({}, data, {
      modal: modalState
    }));

    if (modalState !== this.state.currentlySelected) {
      browserHistory.replace(`${location.pathname}?${query}`);
    }
  }

  componentDidMount() {
    LoginStore.addChangeListener( this._onChange );

    let query = queryString.parse(this.props.location.search);
    const modalState = query.modal || this.props.currentlySelected;
    const token = query.confirmation_token || this.state.confirmation_token;

    if (this.expectedModals.indexOf(modalState) > -1) {
      this.setState({
        confirmation_token: token
      });
      this.updateURL(modalState);
    }
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener( this._onChange );
  }

  _onChange(payload) {
    if (payload && payload.modal && (payload.modal !== this.state.currentlySelected)) {
      this.updateURL(payload.modal);
    }
  }

  handleClick(e) {
    if (!Api.selectorMatches(e.target, '.panel')) {
      return;
    }

    let panel = e.target;
    let selected = panel.id;
    this.setState({currentlySelected: selected});
    this.updateURL(selected);
  }

  render() {
    let modalContent;

    if (!this.props.currentlySelected) {
      return null;
    }

    const query = queryString.parse(this.props.location.search);

    switch (this.props.currentlySelected) {
      case "login":
        modalContent = <Login {...this.props} />;
        break;
      case "register":
        modalContent = <Register {...this.props} />;
        break;
      case "forgot-password":
        modalContent = <ForgotPassword {...this.props} />;
        break;
      case "password-reset":
        modalContent = <PasswordReset {...this.props} />;
        break;
      case "thank-you":
        modalContent = <Thankyou {...this.props} />;
        break;
      case "verify-token":
        if (query.confirmation_token) {
          let params = { location: { query } };
          modalContent = <VerifyToken {...this.props} {...params} />;
        } else {
          modalContent = <Login {...this.props} />;
        }
        break;
      case "upgrade":
        modalContent = (
          <UpgradeModal {...this.props} updateURL={this.updateURL} />
        );
        break;
      case "payment":
        modalContent = <PaymentModal {...this.props} amount={query.amount} />;
        break;
      case "cancel-subscription":
        modalContent = <DowngradeModal {...this.props} />;
        break;
      case "feedback":
        modalContent = <FeedbackModal {...this.props} />;
        break;
      default:
        modalContent = null;
    }

    const registerPanel = Classnames({
      'pure-u-1-2 panel': true,
      'selected': (this.props.currentlySelected === 'register')
    });

    const loginPanel = Classnames({
      'pure-u-1-2 panel': true,
      'selected': (this.props.currentlySelected == 'login')
    });

    const header = ['login', 'register'].indexOf(this.props.currentlySelected) > -1 ? (
        <div className='pure-g login-modal-header' onClick={this.handleClick.bind(this)}>
          <div id='login' className={loginPanel}>Login</div>
          <div id='register' className={registerPanel}>Sign up</div>
        </div>
    ): null;

    return (
      <div className='login-modal-container backdrop-container'>
        <i className='modal-close login-modal-close'/>
        <div className={'login-modal ' + this.props.currentlySelected}>
          {header}
          <div className='login-modal-content'>
            {modalContent}
          </div>
        </div>
      </div>
    );
  }
}

LoginModal.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(LoginModal);
