import React from 'react';
import classnames from 'classnames';
import { browserHistory } from 'react-router';
import queryString from 'query-string';
import Api from '../../api/api';
import AppRoutes from '../../routes/logged-in';
import LoginStore from '../../stores/login-store';
import AppConstants from '../../constants/constants';
import './top-searchbar.scss';
import BannerSearchForm from '../banners/banner-search-form';

export default class TopSearchBar extends React.Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      category: ''
    };

    this.categoryOptions = {
      all: 'All Chapters, Topics and Checklists',
      checklists: 'Checklists',
      topics: 'Topics',
      chapters: 'Chapters'
    }
  }

  componentWillReceiveProps() {
    this.setState({ menuOpen: false });
  }

  revealSearchBar(e) {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleSelect(e) {
    var targetValue = '';
    if (Api.selectorMatches(e.currentTarget, 'li')) {
      let text = e.currentTarget.textContent;
      targetValue = Object.keys(this.categoryOptions).filter(
        key => this.categoryOptions[key] === text
      )[0];
    } else if (Api.selectorMatches(e.currentTarget, 'select')) {
      targetValue = e.currentTarget.value;
    }


    if (targetValue) {
      this.setState({ category: targetValue });
    } else {
      this.setState({ category: '' });
    }

  }

  handleSubmit(e) {
    e.preventDefault();
    const userData = LoginStore.getSessionUserData();
    const selectValue = this.state.category;
    const searchValue = e.target[0].value;
    const searchQuery = queryString.stringify({
      q: searchValue || '*',
      filter: selectValue,
      page: AppConstants.DEFAULTSEARCHPAGE,
      per_page: AppConstants.PERPAGE
    });

    const modalQuery = queryString.stringify({modal: 'upgrade'});

    if (userData.searches_left <= 0 && userData.account_type === "Starter" ) {
      return browserHistory.replace( `${AppRoutes.search}?${modalQuery}`);
    }

    browserHistory.push( `${AppRoutes.search}?${searchQuery}` )
  }

  render() {
    const menuStyles = classnames({
      "l-box": true
    });

    return (
      <div className="top-search-bar">
        <div className="inner-container">
          <div ref="searchFormContainer" className={menuStyles}>
            <BannerSearchForm {...this.props} />
          </div>
        </div>
      </div>
    )
  }

}
