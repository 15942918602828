import React from "react";
import { Link } from "react-router";
import AppRoutes from "../../routes/routes";

export default ({ location, data }) => {
  const query = location.query.q;
  const { title, type, slug, taxonomy, part } = data;
  let baseurl = AppRoutes.explore;
  let url = `${baseurl}?open=${slug}`;
  let linkTag;

  if (type === "checklist") {
    url = `${AppRoutes.checklists}${slug}`;
    linkTag = (
      <Link
        to={{
          pathname: url,
          query: { "highlight-text": query },
        }}
      >
        <span>{title}</span>
      </Link>
    );
  } else {
    linkTag = (
      <Link to={url}>
        <span>{title}</span>
      </Link>
    );
  }

  return (
    <div className="result-item">
      <span className="result-type">{type}</span>
      <h3>{linkTag}</h3>
    </div>
  );
};
