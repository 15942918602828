import zenscroll from 'zenscroll';
import { getData, postData, deleteData, headData } from './fetchData';
import AppConstants from '../constants/constants';
import ApiEndPoints from '../constants/endpoints';
import AppAuthAction from '../actions/login-actions';

const captcha = () => {
  const randomNumner1 = Math.floor(Math.random() * 9) + 1;
  const randomNumber2 = Math.floor(Math.random() * 9) + 1;
  const result = randomNumner1 + randomNumber2;

  return {
    leftside: randomNumner1,
    rightside: randomNumber2,
    result: result
  };
};

const scrollTo = (target, cb) => {
  if (!cb) {
    cb = function() {};
  }

  let element = target;
  if (!target) {
    zenscroll.toY(0);
    return;
  }

  if (typeof(target) === 'string') {
    element = document.querySelector(target);
  }

  let header = document.getElementById('app-header');
  zenscroll.toY(element.getBoundingClientRect().top - header.scrollHeight, undefined, cb);
};

const scrollCentre = (target, offset, cb) => {
  if (!cb) {
    cb = function() {};
  }

  let element = target;
  if (!target) {
    zenscroll.toY(0);
    return;
  }

  if (typeof(target) === 'string') {
    element = document.querySelector(target);
  }

  zenscroll.center(element, undefined, offset, cb);
};

const hasEmptyFields = (data) => {
  let fieldsEmpty = false;
  for(var key in data){
    if(data.hasOwnProperty(key)){
      if(data[key] == null) fieldsEmpty = true;
    }
  }
  return fieldsEmpty;
};

const selectorMatches = (el, selector) => {
	var p = Element.prototype;
	var f = p.matches || p.webkitMatchesSelector || p.mozMatchesSelector || p.msMatchesSelector || function(s) {
		return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
	};
	return f.call(el, selector);
};

const animateElement = (element, animations, callback) => {
  try {
    const target = element.animate(animations, 400);
    target.onfinish = (event) => {
      callback();
    };
  } catch(e) {
    callback();
  }
}

export default {
  selectorMatches,
  hasEmptyFields,
  scrollTo,
  scrollCentre,
  captcha,
  animateElement,
  sendFeedback(headers, data, callbacks) {
    postData(
      `${ApiEndPoints.FEEDBACK}`,
      headers,
      {feedback: data},
      callbacks
    );
  },
  snakeCaseToWords(string) {
    return string.split('_').join(" ");
  },
  sendContact(data, callbacks) {
    postData(
      `${ApiEndPoints.CONTACT}`,
      undefined,
      {contact: data},
      callbacks
    );
  },
  subscribeToNewsLetter(data, callbacks) {
    postData(
      `${ApiEndPoints.SUBSCRIBE}`,
      undefined,
      {subscriber: data},
      callbacks
    );
  },
  checklistViewed(id, headers, callbacks){
    postData(
      `${ApiEndPoints.CHECKLISTVIEW}`,
      headers,
      { search: { checklist_id: id } },
      {
        onSuccess: ({data}) => {
          AppAuthAction.checklistView(data)
        },
        onError: () => {}
      }
    );
  },
  fetchNews(callbacks){
    getData(ApiEndPoints.NEWS, undefined, callbacks);
  },
  getContext(headers, callbacks){
    getData(ApiEndPoints.CONTEXT, headers, callbacks);
  },
  getPartByID(id, headers, callbacks){
    getData(`${ApiEndPoints.PARTS}/${id}`, headers, callbacks);
  },
  getParts(headers, callbacks){
    getData(ApiEndPoints.PARTS, headers, callbacks);
  },
  getPartChapters(id, headers, callbacks){
    getData(`${ApiEndPoints.PARTS}/${id}/chapters`, headers, callbacks);
  },
  getChapterByID(id, headers, callbacks){
    getData(`${ApiEndPoints.CHAPTERS}/${id}/`, headers, callbacks);
  },
  getTopicByID(id, headers, callbacks){
    getData(`${ApiEndPoints.TOPICS}/${id}/`, headers, callbacks);
  },
  getChapterTopics(id, headers, callbacks) {
    getData(`${ApiEndPoints.CHAPTERS}/${id}/topics`, headers, callbacks);
  },
  getTopicChecklists(id, headers, callbacks) {
    getData(`${ApiEndPoints.TOPICS}/${id}/checklists`, headers, callbacks);
  },
  getChecklist(id, headers, callbacks) {
    getData(`${ApiEndPoints.CHECKLISTS}/${id}/`, headers, callbacks);
  },
  getRandomParts(headers, callbacks){
    getData(ApiEndPoints.RANDOM_PARTS, headers, callbacks);
  },
  getSearchResults(query, headers, callbacks) {
    const data = {
      q: query.q || '',
      page: query.page || AppConstants.DEFAULTSEARCHPAGE,
      per_page: query.per_page || AppConstants.PERPAGE,
      filter: query.filter || AppConstants.DEFAULTFILTER
    };
    const url = `${ApiEndPoints.SEARCH}?q=${data.q}&page=${data.page}&per_page=${data.per_page}&filter=${data.filter}`;

    getData(url, headers, callbacks);
  },
  getRecentSearches(headers, callbacks){
    getData(`${ApiEndPoints.RECENTSEARCHES}`, headers, callbacks)
  },
  getAutocomplete(query, headers, specific, callbacks) {
    const url = (specific)? `${ApiEndPoints.AUTOCOMPLETE}?q=${query}&strategy=${specific}&limit=${AppConstants.CLAUTOCOMPLELIMIT}` : `${ApiEndPoints.AUTOCOMPLETE}?q=${query}`;

    getData(url, headers, callbacks)
  },
  getBookmarks(headers, callbacks) {
    getData(`${ApiEndPoints.BOOKMARKS}`, headers, callbacks)
  },
  storeBookmark(title, headers, callbacks){
    postData(
      `${ApiEndPoints.BOOKMARKS}`,
      headers,
      { bookmark: { title } },
      callbacks
    );
  },
  removeBookmark(title, headers, callbacks) {
    deleteData(
      `${ApiEndPoints.BOOKMARKS}/1`,
      headers,
      { bookmark: { title } },
      callbacks
    );
  },
  getNotes(headers, callbacks) {
    getData(`${ApiEndPoints.NOTES}`, headers, callbacks)
  },
  getNote(checklist, headers, callbacks) {
    getData(`${ApiEndPoints.NOTE}`.replace(/:id/, checklist), headers, callbacks)
  },
  storeNote(checklist, content, headers, callbacks){
    postData(
      `${ApiEndPoints.NOTE}`.replace(/:id/, checklist),
      headers,
      { note: { content } },
      callbacks
    );
  },
  removeNote(checklist, headers, callbacks) {
    deleteData(
      `${ApiEndPoints.NOTE}`.replace(/:id/, checklist),
      headers,
      {},
      callbacks
    );
  },
  downloadPowerpoint(title, headers, callbacks) {
    getData(ApiEndPoints.DOWNLOAD_POWERPOINT.replace(/:id/, title), headers, callbacks);
  },
  downloadToken(headers, callbacks) {
    getData(ApiEndPoints.DOWNLOAD_TOKEN, headers, callbacks);
  },
  validateEmail(email, headers, callbacks) {
    getData(`${ApiEndPoints.VALIDATE_EMAIL}?email=${email}`, headers, callbacks);
  },
  checkPromo(headers, data, callbacks) {
    postData(
      `${ApiEndPoints.PROMO}`,
      headers,
      data,
      callbacks
    );
  },
  loadHomepage(headers, callbacks) {
    getData(`${ApiEndPoints.HOMEPAGE}`, headers, callbacks)
  }
};
