import React from "react";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import './collapsible-notes.scss';

export default class CollapsibleNotes extends React.Component {
  constructor() {
    super();
    this.state = {
      closed: true
    };
  }

  render() {
    let { note } = this.props;
    let { closed } = this.state;

    let previewClasses = classNames({
      'notes-collapsed': closed,
      'notes-preview': true
    });

    return (
      <div className="notes-collapse">
        <div className="notes-collapse-controls">
          <div className="clickable" onClick={() => this.setState({ closed: !closed })}> Preview <FontAwesomeIcon icon={closed ? faPlus : faMinus} /></div>
        </div>
        <div className={previewClasses} dangerouslySetInnerHTML={{ __html: note.content }}></div>
      </div>
    );
  }
}
