import React from 'react';
import { Link } from 'react-router';
import { Helmet } from 'react-helmet';
import LoginStore from '../../stores/login-store';
import CONSTANTS from '../../constants/constants';
import Banner from '../../components/banners/title-banner';
import StartTrialBanner from '../../components/banners/start-trial';
import ContactForm from './contact-form';
import './contact.scss';


export default class Contactus extends React.Component {

  render(){
    const trialBanner = (LoginStore.isLoggedIn()) ? null : <StartTrialBanner {...this.props} />

    return (
      <div className="contact-us">
        <Helmet>
          <title>Neurochecklists | Contact us</title>
          <meta name="description" contents="Contact us about anything Neurochecklists."/>
        </Helmet>

        <Banner title="Contact us" />
        <div className="inner-container">
          <div className="pure-g contact-us-content">
            <div className="pure-u-1">
              <div className="l-box">
                <h1>{CONSTANTS.CONTACT_EMAIL}</h1>
                <p>Questions about Neurochecklists?</p>
                <p>You can get answers to some of our most frequently asked questions from our <Link className="clblue anchor-link" to={'/faqs'}>FAQs</Link> section.</p>
                <p>If you can't find the answers you're looking for feel free to contact us using the form below, or get in touch using the email above.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grey">
          <div className="inner-container">
            <ContactForm />
          </div>
        </div>

        {trialBanner}
      </div>
    )
  }

}
