import React from 'react';
import AppStore from '../../stores/stores';
import NewsItem from '../../components/news/news-item';
import VideoSection from './video-section';

export default class NewsSection extends React.Component {

  constructor(){
    super();
    this.state = {
      newsItems: [],
    }
  }

  componentDidMount(){
    AppStore.getNews({
      onSuccess: ({ data }) => {
        if(data.posts) {
          this.setState({ newsItems: data.posts });
        }
      },
      onError: function(){}
    });
  }

  renderNewsItem(index, withImage = true){
    if( this.state.newsItems[index] && Object.keys(this.state.newsItems[index]).length ) {
      return(
        <article className="pure-u-1">
          <div className="l-box">
            <NewsItem {...this.state.newsItems[index]} showImage={withImage} />
          </div>
        </article>
      )
    }
  }

  render(){
    return (
      <div className="new-section">
        <div className="inner-container">
          <div className="pure-g">
            <div className="pure-u-1">
              <h3 className="tac">Updates from Neurochecklists</h3>
            </div>

            <div className="pure-u-1 pure-u-md-1-2">
              {this.renderNewsItem(0)}
            </div>
            <div className="pure-u-1 pure-u-md-1-2">
              {this.renderNewsItem(1, false)}
              {this.renderNewsItem(2, false)}
            </div>
            <div className="pure-u-1 pure-u-md-1-2">
              <VideoSection video={this.props.video} />
            </div>

          </div>

        </div>
      </div>
    )
  }

}
