import React from 'react';
import Classnames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCircle } from '@fortawesome/free-regular-svg-icons';

import './checklist-item.scss';

export default class ChecklistItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false,
            sublist: props.sublist || false
        };

        this.icon = this.icon.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.stopPropagation(); // don't affect parent list items

        // if the click is for the footnote links then don't
        // toggle the checkbox.
        if (['footnote-link', 'sup'].indexOf(e.target.localName) > -1 ) {
            return;
        }
        this.setState({
            checked: !this.state.checked
        });
    }

    icon() {
        let checked = faCheckSquare;
        let unchecked = faSquare;

        if (this.state.sublist) {
            checked   = faCheckCircle;
            unchecked = faCircle;
        }

        return this.state.checked ? checked : unchecked;
    }

    render() {
        let classes = Classnames({
            checked: this.state.checked,
            clickable: true,
            'checklist-item': true,
        });
        let size = this.state.sublist ? 'xs' : '1x';

        return (
            <li className={classes} onClick={this.handleClick}>
                <div className={'checklist-item-icon'}>
                    <FontAwesomeIcon icon={this.icon()} size={size} />
                </div>
                <div className={'checklist-item-entries'}>
                    {this.props.title}
                    {this.props.innerList}
                </div>
            </li>
        );
    }
}
