import React from 'react';

import FreeTrialExpired from '../../components/subcribe/free-trial-expired';

import './title-banner.scss';

export default class TitleBanner extends React.Component {
  render() {
    return (
      <div>
        <div className="splash-title-container">
          <div className="splash-title">
            <div className="l-box">
              <h1 className="splash-title-container--title">{this.props.title}</h1>
            </div>
          </div>
        </div>

        <FreeTrialExpired />
      </div>
    )
  }
}
