import React from "react";
import classnames from "classnames";
import { browserHistory, withRouter } from "react-router";
import uuid from "uuid/v4";

import AppAuthStore from "../../stores/login-store";
import upgradePlans from "../../data/upgrade-plans";
import PaymentButton from "./payment-button";
import authenticationMixin from "../../mixins/authenticationMixin";
import Api from "../../api/api";

class UpgradeModal extends React.Component {
  constructor() {
    super();
    this.state = {
      plan: upgradePlans[0],
      promo: {
        code: "",
        valid: null,
      },
      showPromoBox: false,
    };

    this.promoEntered = this.promoEntered.bind(this);
    this.inputTimeout = null;
  }

  handSubmit(e) {
    e.preventDefault();
  }

  promoEntered(value) {
    clearTimeout(this.inputTimeout);

    if (value.length < 4) {
      return;
    }

    var that = this;
    this.inputTimeout = setTimeout(function () {
      let code = value;

      /**
       * check the validity of the code with the API and apply discount if acceptable
       */
      Api.checkPromo(
        that.props.userApiRequest,
        { code },
        {
          onSuccess: ({ data }) => {
            that.setState({
              promo: {
                code,
                valid: data.valid,
              },
            });
          },
          onError: (e) => {
            that.setState({
              promo: {
                code,
                valid: false,
              },
            });
          },
        }
      );
    }, 300);
  }

  handSubmitOptionSelect(selectedOption) {
    this.setState({
      plan: selectedOption,
    });
  }

  componentDidMount() {
    const { account_type } = AppAuthStore.getSessionUserData();

    if (!account_type) {
      return browserHistory.push(window.location.pathname);
    }

    this.setState({
      plan: upgradePlans.find((x) => x.type === account_type),
    });
  }

  renderPayPrice() {
    const freeAccount = this.state.plan.type === "Starter";
    const { account_type, is_academic } = AppAuthStore.getSessionUserData();
    const { promo } = this.state;

    if (freeAccount || account_type === this.state.plan.type) {
      return null;
    }

    const price = is_academic ? (
      <span className="price">
        &pound;<strike>{this.state.plan.price}</strike>{" "}
        <span className="cgreen">{this.state.plan.discount_price}</span>/
        {this.state.plan.recurrence}
      </span>
    ) : (
      <span className="price cgreen">
        &pound;{this.state.plan.price}/{this.state.plan.recurrence}
      </span>
    );

    return (
      <div className="pure-g">
        <div className="pure-u-1">
          <div className="big-text">
            <div className="l-box clearfix">
              <div className="text">
                You will be charged {price}
                {promo.valid ? " after the first 12 months" : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPayButton() {
    const freeAccount = this.state.plan.type === "Starter";
    const { account_type, is_academic } = AppAuthStore.getSessionUserData();

    if (freeAccount || account_type === this.state.plan.type) {
      return null;
    }

    return (
        <>
            <div className="pure-u-12-24">
                <div className="big-text">
                    <div className="l-box clearfix">
                        <PaymentButton
                            {...this.props}
                            selectedPlan={this.state.plan}
                            promoCode={this.state.promo.valid ? this.state.promo.code : ''}
                            amount={is_academic ? this.state.plan.discount_price : this.state.plan.price}
                        />
                    </div>
                </div>
            </div>
            <div className="pure-u-1">
                <div className="l-box payment-legals">
                    By paying via PayPal, Neurochecklists will set up renewal payments for your convenience
                </div>
            </div>
        </>
    );
  }

  renderUpgradeOptions() {
    const { account_type, searches_left } = AppAuthStore.getSessionUserData();
    const { promo } = this.state;

    let plans = promo.valid ? upgradePlans.filter(p => p.plan_id === 'yearly_premium' ) : upgradePlans;

    return plans.map((plan, key) => {
      const classes = classnames({
        option: true,
        "pure-button": true,
      });

      const currentAccount = account_type === plan.type;
      const planOption = currentAccount ? (
        <span className="cgreen">Current Plan</span>
      ) : (
        <span>Select Plan</span>
      );

      const searches_left_or_upgrade =
        plan.price < 1 ? (
          <div className="l-box">
            <span className="corange">
              {searches_left > 1000
                ? `Unlimited access until end of billing cycle`
                : `(${searches_left} views Left)`}
            </span>
          </div>
        ) : (
          <div className="l-box">
            <span>Unlimited access</span>
          </div>
        );

      return (
        <div
          className={classes}
          key={uuid()}
          onClick={this.handSubmitOptionSelect.bind(this, plan)}
        >
          <input
            type="radio"
            name="plan"
            id={plan.id}
            value={plan.type}
            onChange={() => {}}
            checked={this.state.plan.type === plan.type}
          />
          <label htmlFor={plan.id}>
            <div className="pure-u-1-4">
              <div className="l-box">
                <span>
                  {plan.type === "Starter" ? "Free Trial" : plan.type}
                </span>
              </div>
            </div>
            <div className="pure-u-1-4">{searches_left_or_upgrade}</div>
            <div className="pure-u-1-4">
              <div className="l-box">
                {plan.price !== false ? (
                  <span>
                    &pound;{plan.price}/{plan.abbreviation}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="pure-u-1-4">
              <div className="l-box">
                <span className="cgreen">
                  {this.state.plan.type === plan.type &&
                  this.state.plan.type !== "Starter" &&
                  !currentAccount
                    ? "Upgrade"
                    : planOption}
                </span>
              </div>
            </div>
          </label>
        </div>
      );
    });
  }

  renderPromoCode() {
    const { promo, showPromoBox, plan } = this.state;

    if (plan.id == "starter") {
      return;
    }

    return (
      <>
          {!showPromoBox ? (
      <div className="pure-u-12-24">
        <label>
            <a
              href="#"
              onClick={(e) => this.setState({ showPromoBox: true })}
            >
              Have a promo code?
            </a>
        </label>
      </div>
          ) : (
            <div className="pure-u-12-24">
              <div className="l-box">
              <div className="pure-g">
                <label className="pure-u-1-3">
                  <p>Enter Code:</p>
                </label>
                <input
                  type="text"
                  defaultValue={promo.code}
                  className={classnames({
                    "promo-input": true,
                    "pure-input-2-3": true,
                    success: promo.code && promo.valid,
                    error: promo.code && !promo.valid,
                  })}
                  ref="promo"
                  onChange={(e) => this.promoEntered(e.target.value)}
                />
              </div>
            </div>
            </div>
          )}
      </>
    );
  }

  render() {
    const { is_academic } = AppAuthStore.getSessionUserData();

    return (
      <form onSubmit={this.handSubmit}>
        <div className="upgrade-modal">
          <header className="tac mb30">
            <h1>Upgrade to a premium account</h1>
            {is_academic ? (
              <p>You are eligible for educational discount</p>
            ) : (
              ""
            )}
          </header>

          {this.renderUpgradeOptions()}
          {this.renderPayPrice()}
          <div className="pure-g">
            {this.renderPromoCode()}
            {this.renderPayButton()}
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(authenticationMixin(UpgradeModal));
