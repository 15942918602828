import { register } from '../dispatchers/dispatcher';
import BaseStore from './base-store';
import AppConstants from '../constants/constants';
import Api from '../api/api'

class AppStore extends BaseStore {
  constructor(){
    super();
    this.subscribe( this.dispatcherIndex.bind(this) );
  }

  dispatcherIndex() {
    register( ( action ) => {
      switch(action.actionType) {
        case AppConstants.TABOPENED:
          this.emitChange();
          break;
        case AppConstants.POPUPCLOSED:
          this.emitChange();
          break;
      }
    })
  }

  checklistViewed(id, headers){
    return Api.checklistViewed(id, headers);
  }

  getNews(callbacks){
    return Api.fetchNews(callbacks);
  }

  getContext(headers, callbacks) {
    return Api.getContext(headers, callbacks);
  }

  getPartByID(id, headers, callbacks){
    return Api.getPartByID(id, headers, callbacks);
  }

  getParts(headers, callbacks) {
    return Api.getParts(headers, callbacks);
  }

  getRandomParts(headers, callbacks) {
    return Api.getRandomParts(headers, callbacks);
  }

  getPartChapters(id, headers, callbacks) {
    return Api.getPartChapters(id, headers, callbacks);
  }

  getChapterByID(id, headers, callbacks) {
    return Api.getChapterByID(id, headers, callbacks);
  }

  getTopicByID(id, headers, callbacks) {
    return Api.getTopicByID(id, headers, callbacks);
  }

  getChapterTopics(id, headers, callbacks) {
    return Api.getChapterTopics(id, headers, callbacks);
  }

  getTopicChecklists(id, headers, callbacks) {
    return Api.getTopicChecklists(id, headers, callbacks)
  }

  getChecklist(id, headers, callbacks) {
    return Api.getChecklist(id, headers, callbacks)
  }

  getSearchResults(query, headers, callbacks){
    return Api.getSearchResults(query, headers, callbacks);
  }

  getRecentSearches(headers, callbacks){
    return Api.getRecentSearches(headers, callbacks);
  }

  getAutocomplete(query, headers, specific, callbacks) {
    return Api.getAutocomplete(query, headers, specific, callbacks);
  }

  getBookmarks(headers, callbacks) {
    return Api.getBookmarks(headers, callbacks);
  }

  storeBookmark(title, headers, callbacks){
    return Api.storeBookmark(title, headers, callbacks);
  }

  removeBookmark(title, headers, callbacks){
    return Api.removeBookmark(title, headers, callbacks);
  }

  downloadPowerpoint(title, headers, callbacks) {
    return Api.downloadPowerpoint(title, headers, callbacks);
  }

  downloadToken(headers, callbacks) {
    return Api.downloadToken(headers, callbacks);
  }

  getNotes(headers, callbacks) {
    return Api.getNotes(headers, callbacks);
  }

  getNote(checklist, headers, callbacks) {
    return Api.getNote(checklist, headers, callbacks);
  }

  storeNote(checklist, content, headers, callbacks){
    return Api.storeNote(checklist, content, headers, callbacks);
  }

  removeNote(checklist, headers, callbacks){
    return Api.removeBookmark(checklist, headers, callbacks);
  }

  loadHomepage(headers, callbacks){
    return Api.loadHomepage(headers, callbacks);
  }
}

export default new AppStore();
