export default [
  {
    type: 'Starter',
    searches: 15,
    price: false,
    id: 'starter'
  },
  {
    type: 'Monthly Premium',
    searches: 'Unlimited searches',
    price: 2.49,
    discount_price: 2.0,
    recurrence: 'month',
    abbreviation: 'mo',
    plan_id: 'monthly_premium'
  },
  {
    type: 'Yearly Premium',
    searches: 'Unlimited searches',
    price: 24.99,
    discount_price: 20.0,
    recurrence: 'year',
    abbreviation: 'yr',
    plan_id: 'yearly_premium'
  }
];
