let map = {
    "authenticate_attempt_successful": {
        continue: true,
        retry: false,
    },
    "authenticate_successful": {
        continue: true,
        retry: false,
    },
    "authenticate_successful_issuer_not_participating": {
        continue: true,
        retry: false,
    },
    "authenticate_error": {
        continue: false,
        retry: true,
    },
    "authenticate_failed": {
        continue: false,
        retry: true,
    },
    "authenticate_signature_verification_failed": {
        continue: false,
        retry: true,
    },
    "authenticate_unable_to_authenticate": {
        continue: false,
        retry: true,
    },
    "authentication_unavailable": {
        continue: false,
        retry: false,
    },
    "lookup_bypassed": {
        continue: false,
        retry: false,
    },
    "lookup_enrolled": {
        continue: false,
        retry: true,
    },
    "lookup_error": {
        continue: false,
        retry: true,
    },
    "lookup_not_enrolled": {
        continue: true,
        retry: false,
    },
    "unsupported_card": {
        continue: false,
        retry: false,
    },
    "unsupported_account_type": {
        continue: false,
        retry: false,
    },
    "unsupported_three_d_secure_version": {
        continue: false,
        retry: false,
    },
    "authentication_bypassed": {
        continue: true,
        retry: false,
    },
    "challenge_required": {
        continue: false,
        retry: true,
    },
    "authenticate_rejected": {
        continue: false,
        retry: false,
    },
    "authenticate_frictionless_failed": {
        continue: true,
        retry: false,
    },
    "lookup_failed_acs_error": {
        continue: false,
        retry: true,
    },
    "authenticate_failed_acs_error": {
        continue: false,
        retry: true,
    },
    "data_only_successful": {
        continue: true,
        retry: false,
    },
    "lookup_card_error": {
        continue: false,
        retry: false,
    },
    "lookup_server_error": {
        continue: false,
        retry: true,
    },
};

export default map;
