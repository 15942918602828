export default {
  homepage: '/',
  pricing: '/pricing',
  about: '/about',
  search: '/search',
  contact: '/contact',
  login: '/login',
  faqs:'/faqs',
  register: '/register',
  news: 'https://theneurologylounge.com/',
  paper: 'https://www.finderpublications.co.uk/books/p/74p1cyvqqwu10dbw5k7o1u551ptpke-mpeks-ysjsr-gbjzm-f26ry-ehmwc-l6bg2-7y8e6-ffmnm-t26eb',
  tcs: '/terms-and-conditions',
  privacy: '/privacy-policy',
  data: '/data-policy',
  credits: '/site-credits',
  facebook: 'https://www.facebook.com/neurochecklists/?fref=ts',
  twitter: 'https://twitter.com/neurochecklists',
  linkedin: 'https://www.linkedin.com/company/neurochecklists-com?trk=biz-companies-cym',

  facebookShare: 'http://www.facebook.com/sharer/sharer.php?u=:url',
  twitterShare: 'http://www.twitter.com/intent/tweet?url=:url&text=:title',

  book: 'https://www.routledge.com/700-Essential-Neurology-Checklists/Imam/p/book/9781032076232',

  explore: '/explore/',
  category: '/explore/category/',
  chapters: '/explore/chapters/',
  topics: '/explore/topics/',
  checklists: '/explore/checklists/',

  sitemap: '/sitemap.xml'
}
