import React from 'react';
import UserBanner from './user-section-banner';
import AuthMixin from '../../mixins/authenticationMixin';
import Styles from './user-section.scss';

export default ( InnerComponent ) => AuthMixin(class AccountDetails extends React.Component {
  render(){
    return (
      <div>
        <UserBanner user={this.props.user} />
        <div className="inner-container">
          <InnerComponent {...this.state} {...this.props} />
        </div>
      </div>
    )
  }

})
