import AppAuthConstants from '../constants/auth-constants';
import { dispatch } from '../dispatchers/dispatcher';

export default {
  login(email, password) {
    dispatch({
      actionType: AppAuthConstants.REQUEST_LOGIN_USER,
      email,
      password
    });
  },
  logout(headers, redirectPath = '/') {
    dispatch({
      actionType: AppAuthConstants.REQUEST_LOGOUT_USER,
      headers,
      redirectPath
    });
  },
  register(data) {
    dispatch({
      actionType: AppAuthConstants.REQUEST_SIGNUP_USER,
      data
    });
  },
  forgotPassword() {
    dispatch({
      actionType: AppAuthConstants.REQUEST_FORGOT_PASSWORD_VIEW
    });
  },
  thankRegister() {
    dispatch({
      actionType: AppAuthConstants.REQUEST_THANK_YOU_VIEW
    });
  },
  checklistView(data) {
    dispatch({
      actionType: AppAuthConstants.CHECKLISTVIEW,
      data
    });
  }
};
