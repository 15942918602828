import React from "react";
import { browserHistory, withRouter } from "react-router";
import PropTypes from "prop-types";

import PopupDialog from '../popups/popup-dialog';
import LoginModal from '../login/login-modal';

import queryString from 'query-string';

class GlobalPopup extends React.Component {
  constructor() {
    super();
    this.state = {
      popupActive: false
    };
  }

  componentDidMount() {
    const qs = queryString.parse(window.location.search);
    if (qs.modal) {
        this.setState({
            popupActive: true,
            currentlySelected: qs.modal,
            restricted: !!qs.restricted
        });
    }

    this.unlisten = browserHistory.listen(location => {
        let newState = {
            popupActive: !!location.query.modal
        };

        if (newState.popupActive) {
            newState.currentlySelected = location.query.modal;
        }

        this.setState(newState);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    let { popupActive } = this.state;

    return popupActive ? (
      <PopupDialog {...this.props}>
        <LoginModal {...this.props} {...this.state} />
      </PopupDialog>
    ) : null;
  }
}

GlobalPopup.propTypes = {
  location: PropTypes.object.isRequired
}

export default withRouter(GlobalPopup);
