import React from 'react';
import Moment from 'moment';
import uuid from 'uuid/v4';

import { SERVER_ERROR_SHORT } from '../../constants/messages';
import CONSTANTS from '../../constants/constants';
import AuthMixin from '../../mixins/authenticationMixin';
import LoginStore from '../../stores/login-store';
import authAPI from '../../api/authApi';
import upgradePlans from '../../data/upgrade-plans';

class DownGradeModal extends React.Component {
  constructor() {
    super();

    this.state = {
      title: `We're sad to see you go`,
      success: false,
      error: false,
      loading: false,
      billingDate: ''
    };
  }
  renderUpgradeOptions() {
    const { account_type } = LoginStore.getSessionUserData();

    return upgradePlans
      .filter(plan => account_type === plan.type)
      .map( (plan, key) => {
        return(
          <div className="option pure-button" key={uuid()}>
            <label htmlFor={plan.type}>
              <div className="pure-u-1-4">
                <div className="l-box">
                  <span>{plan.type}</span>
                </div>
              </div>
              <div className="pure-u-1-4">
                <div className="l-box">
                  <span>Unlimited searches</span>
                </div>
              </div>
              <div className="pure-u-1-4">
                <div className="l-box">
                  <span>
                    <sup>&pound;</sup>
                    {plan.price}/{plan.abbreviation}
                  </span>
                </div>
              </div>
              <div className="pure-u-1-4">
                <div className="l-box">
                  <span className='cgreen'>Current Plan</span>
                </div>
              </div>
            </label>
          </div>
        );
      });
  }

  renderForm() {
    return(
      <form onSubmit={this.handSubmit.bind(this)}>
        {this.renderUpgradeOptions()}
        <div className="pure-g">
          <div className="pure-u-1">
            <div>
              <div className="downgrade-modal-text">
                <p>
                  By cancelling your subscription you will be downgrading to a free account.
                  Your account will be limited to 15 views total.
                  If you do not wish to downgrade simply cancel this pop up.
                </p>
                <p>
                  If you wish to go ahead with your downgrade, click the link below. Your unlimited searches will be available until the end of your billing cycle.
                </p>
              </div>
              <div className="downgrade-modal-footer clearfix">
                <button type="submit" className="pure-button cancel-subscription-button">Cancel Subscription</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }

  handSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    authAPI.cancelSubscription(this.props.userApiRequest, {
      onSuccess: ({data}) => {
        const { account_type, searches_left, next_refresh } = data;
        this.setState({
          title: 'Your account has been downgraded',
          success: true,
          error: false,
          loading: false,
          billingDate: next_refresh
        });

        LoginStore.updateUser(
          this.props.userApiRequest,
          { account_type: account_type, searches_left: searches_left }
        );
      },
      onError: (response) => {
        this.setState({
          title: SERVER_ERROR_SHORT,
          full: response.error.error,
          error: true,
          success: false,
          loading: false
        });
      }
    });
  }

  renderSuccess() {
    return (
      <div className="pure-g">
        <div className="pure-u-1">
          <div className="downgrade-l-box">
            <div className="downgrade-modal-text">
              <p>
                <span>Your unlimited searches will be available until the end of your current billing cycle:</span>
                <br />
                <span>{Moment(this.state.billingDate).format('MMMM D, YYYY')}</span>
              </p>
              <p>After your billing cycle your account will be downgraded to the starter plan.</p>

              <p className="t-bold">
                <span>Please let us know how we can make things better, send an email to</span>
                <br/>
                <span className="clblue">{CONSTANTS.CONTACT_EMAIL}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderError() {
    let {full} = this.state;

    return (
      <div className="pure-g">
        <div className="pure-u-1">
          <div className="downgrade-l-box">
            <div className="downgrade-modal-text">
              <p>{full}</p>
              <p>If this problem persists please contact us at <a className="clblue" href={'mailto:' + CONSTANTS.CONTACT_EMAIL}>{CONSTANTS.CONTACT_EMAIL}</a></p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLoading() {
    return(
      <div className="loading-overlay">
        <div className="loading-content-container">
          <span className="loading-icon--brain"></span>
          <span className="loading-text">Processing your request... <br/> Please do not close this popup</span>
        </div>
      </div>
    )
  }

  render() {
    const { error, success, loading, title } = this.state;

    return (
      <div className="upgrade-modal">
        <header className="tac mb30">
          <h1>{title}</h1>
        </header>
        {loading ? this.renderLoading() : null}
        {success ? this.renderSuccess() : null}
        {error ? this.renderError() : null}

        {(!success && !error) ? this.renderForm(): null }
      </div>
    )
  }
}

export default AuthMixin(DownGradeModal);
