import React from 'react';
import { Link } from 'react-router';
import Moment from 'moment';
import { Helmet } from 'react-helmet';
import Api from '../../api/api';
import TitleBanner from '../../components/banners/title-banner';
import AppStore from '../../stores/stores';
import AuthMixin from '../../mixins/authenticationMixin';
import AppRoutes from '../../routes/routes';
import DataError from '../../components/errors/data-error';
import CustomSelect from '../../components/custom-elements/custom-select';
import CollapsibleNotes from '../../components/collapsible-notes';

import './notes.scss';

class Notes extends React.Component {
  constructor() {
    super();
    this.state = {
      noData: false,
      notes: [],
      error: false,
      sortBy: ''
    }
  }

  componentDidMount() {
    this._onChange();
  }

  _onChange(cb) {
    AppStore.getNotes(this.props.userApiRequest, {
      onSuccess: ({data}) => {
        this.setState({
          notes: this.sortByDate(data),
          noData: (data.length) ? false : true
        });
      },
      onError: (details) => {
        if ("Rollbar" in window) {
          Rollbar.error("Failed to load notes view", details);
        }
        this.setState({ error: true })
      }
    });
  }

  handleChange(e) {
    var targetValue = '';

    if (Api.selectorMatches(e.currentTarget, 'li')) {
      targetValue = e.currentTarget.textContent;
    } else if (Api.selectorMatches(e.currentTarget, 'select')) {
      targetValue = e.currentTarget.value;
    }

    if(targetValue !== this.state.sortBy) {
      const firstChar = targetValue.charAt(0).toUpperCase();
      const reminderChars = targetValue.substring(1);
      var data = [];
      if(typeof(this[`sortBy${firstChar}${reminderChars}`]) == 'function') {
        data = this[`sortBy${firstChar}${reminderChars}`](this.state.notes)
      }

      this.setState({
        sortBy: targetValue,
        notes: (data.length ? data : this.state.notes)
      })
    }
  }

  sortByAlphabet(data) {
    return data.sort((a, b) => {
      if( a.checklist_data.title.charAt(0) >  b.checklist_data.title.charAt(0) ) {
        return 1
      }
      if(a.checklist_data.title.charAt(0) <  b.checklist_data.title.charAt(0)) {
        return -1;
      }
      return 0;
    })
  }

  sortByDate(data) {
    return data.sort((a, b) => {
      if( new Date(a.created_at) < new Date(b.created_at) ) {
        return 1
      } else if( new Date(a.created_at) > new Date(b.created_at) ) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  notes() {
    return this.state.notes.map(note => {
      // notes, once they exist, can simply have blank content fields.
      // in that case we'll just skip them here
      if (note.content === '') {
        return null;
      }

      return (
        <div key={note.checklist_data.slug} className="note-item clearfix">
          <i className="icon-note"></i>
          <div className="text">
            <p className="title">
              <Link to={`${AppRoutes.checklists}${note.checklist_data.slug}`} className="note" title={note.checklist_data.title}>
                {note.checklist_data.title}
              </Link>
            </p>
            <span className="note-date">{`on ${Moment(note.created_at).format('dddd, D MMMM, YYYY, h:mma')}`}</span>
          </div>
          <CollapsibleNotes note={note} />
        </div>
      )
    })
  }

  render() {
    if(this.state.error) return <DataError />

    let notes = this.notes();
    if(this.state.noData) {
      notes = (<div className="no-notes"><p>You don't currently have any notes. Use the notes field on a checklist to save it here for later.</p></div>)
    }

    return (
      <div className="force-full-height">
        <Helmet>
          <title>Neurochecklists | Notes</title>
        </Helmet>
        <TitleBanner title="My Notes" />
        <div className="inner-container">
          <div className="pure-g">
            <div className="pure-u-1">
              <div className="clearfix">
                <div id="custom-element" onClick={this.handleChange.bind(this)} className="top-tag-sort">
                  <span className="fixed-text">Sort by:</span>
                  <div className="custom-select-holder">
                    <CustomSelect
                      handleChange={ (e) => this.handleChange(e) }
                      default={'date'}
                      options={{
                        date: 'Date',
                        alphabet: 'Alphabet'
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="l-box notes-container">
                {notes}
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default AuthMixin(Notes);
