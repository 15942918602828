import { Dispatcher } from 'flux';
import { EventEmitter } from 'events';

EventEmitter.prototype._maxListeners = 35;

const flux = new Dispatcher();


export function register( callback ){
  return flux.register( callback );
}

export function dispatch( actionType, action) {
  flux.dispatch( actionType, action);
}
