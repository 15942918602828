import React from 'react';
import Api from '../../api/api';


export default class Notice extends React.Component {
  componentDidMount(){
    Api.scrollCentre(`#${this.refs.notice.id}`);
  }

  componentDidUpdate(){
    Api.scrollCentre(`#${this.refs.notice.id}`);
  }

  render(){
    return (
      <div id="notice" ref="notice" className={`alert ${this.props.alert}`}>
        {this.props.children}
      </div>
    )
  }
}
