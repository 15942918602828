import React from 'react';
import { Link } from 'react-router';
import AppRoutes from '../../routes/routes';
import './breadcrumbs.scss';

export default class Breadcrumbs extends React.Component {
  render() {
    return (
      <div className="l-box">
        <ul className="breadcrumbs-list">
          <li>
            <Link to={AppRoutes.explore}>Explore</Link>
            <i className="icon-right-open"/>
          </li>
          {this.props.category ?
            <li>
              <Link to={`${AppRoutes.explore}?open=${this.props.category.slug}`}>{this.props.category.title}</Link>
              <i className="icon-right-open"/>
            </li>
          : ''}
          {this.props.chapter ?
            <li>
              <Link to={`${AppRoutes.explore}?open=${this.props.chapter.slug}`}>{this.props.chapter.title}</Link>
              <i className="icon-right-open"/>
            </li>
          : ''}
          {this.props.topic ?
            <li>
              <Link to={`${AppRoutes.explore}?open=${this.props.topic.slug}`}>{this.props.topic.title}</Link>
              <i className="icon-right-open"/>
            </li>
          : ''}
          {this.props.title ?
            <li>
              <span>{this.props.title}</span>
            </li>
          : ''}
        </ul>
      </div>
    )
  }
}
