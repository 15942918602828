import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../components/banners/title-banner';
import StartTrialBanner from '../../components/banners/start-trial';
import LoginStore from '../../stores/login-store';

export default function AboutPage(props) {
  let trialBanner = (LoginStore.isLoggedIn()) ? null : <StartTrialBanner {...props} />

  return (
    <div>
      <Helmet>
        <title>Neurochecklists | About Us</title>
        <meta name="description" contents="All about Neurochecklists. Thousands of fully referenced neurological checklists perfect for clinicians, students and expert patients"></meta>
      </Helmet>
      <Banner title="About Us" />
      <div className="inner-container padded-content-about">
        <div className="pure-g icon-text-section tac">
          <div className="pure-u-1 pure-u-md-1-3">
            <div className="l-box">
              <div style={{height: 82, width:65, margin:'0 auto'}}>
                <img className="pure-img centered-content" src={require('../../../images/search-icon.png')} alt="Search over 1500 checklists" />
              </div>
              <span className="icon-subtext">Search thousands of checklists</span>
              <span className="icon-description">Search thousands of checklists. Quickly and smartly, search practical neurology topics.</span>
            </div>
          </div>
          <div className="pure-u-1 pure-u-md-1-3">
            <div className="l-box">
              <div style={{height: 82, width:72, margin:'0 auto'}}>
                <img className="pure-img centered-content" src={require('../../../images/referenced.png')} alt="Search over 1500 checklists" />
              </div>
              <span className="icon-subtext">Fully referenced and linked</span>
              <span className="icon-description">Evidence-based and fully referenced. All sources linked and just a click away.</span>
            </div>
          </div>
          <div className="pure-u-1 pure-u-md-1-3">
            <div className="l-box">
              <div style={{height: 82, width:65, margin:'0 auto'}}>
                <img className="pure-img centered-content" src={require('../../../images/bookmark-icon.png')} alt="Search over 1500 checklists" />
              </div>
              <span className="icon-subtext">Bookmarks to searched content</span>
              <span className="icon-description">Save your favourite checklist for easy reference and future access.</span>
            </div>
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1">
            <div className="l-box btgrey">
              <div className="centred-text-80 about-overide tac">
                <p>NEUROCHECKLISTS is a handy comprehensive database, with easy-to-read, bulleted items.</p>

                <p>NEUROCHECKLISTS was developed to address the challenge of accessing relevant and practical information about neurology. This may be on a busy ward round or clinic, or when researching a topic or preparing a presentation.</p>

                <p>NEUROCHECKLISTS serves as a prompt to important but easily overlooked points when taking a history, requesting investigations, or prescribing treatments.</p>

                <p>NEUROCHECKLISTS covers all neurological and allied subspecialties. Several checklists cover the intersection of neurology and general medicine.</p>

                <p>NEUROCHECKLISTS is fully referenced and evidence-­based. Every checklist item is sourced from established guidelines, comprehensive review articles, or major studies. All references are linked to their PubMed abstracts, or to the full online document.</p>

                <p>NEUROCHECKLISTS is regularly revised to keep it up to date. It is an indispensable tool for everyone who cares for patients with neurological problems.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {trialBanner}
    </div>
  )
}
