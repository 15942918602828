import React from 'react';
import ClassNames from 'classnames';
import uuid from 'uuid/v4';
import Api from '../../api/api';
import PropTypes from 'prop-types';

export default class CustomSelect extends React.Component {
  constructor(){
    super();
    this.state = { 'selected': '', displayDropDown: false};
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount(){
    this.setState({ 'selected': this.props.options[this.props.default] });
    document.addEventListener('click', this.handleClose);
  }

  componentWillUnmount(){
    document.removeEventListener('click', this.handleClose);
  }

  handleClose(e){
    if( !Api.selectorMatches(e.target, '#custom-element *') || Api.selectorMatches(e.target, '#custom-element') ){
      this.setState({displayDropDown: false})
    } else {
      return;
    }
  }

  generateSelectOptionTags(){
    let items = Object.keys(this.props.options).map((key, index) => {
      return (
        <option
          key={uuid()}
          value={key}
        >
          {this.props.options[key]}
        </option>
      )
    })
    return (
      <select onChange={this.props.handleChange} ref="selectOptions">{items}</select>
    )
  }

  generateListItems(){
    let items = Object.keys(this.props.options).map((key, index)=>{
      return(
        <li onClick={this.handleSelect} key={key}>
          {this.props.options[key]}
        </li>
      )
    })
    return (
      <ul>{items}</ul>
    )
  }

  handleSelect(e){
    this.props.handleChange(e)
    this.setState({selected: e.target.textContent, displayDropDown: false})
  }

  handleClick(){
    this.setState({displayDropDown: !this.state.displayDropDown})
  }

  render(){
    let classNames = ClassNames({
      'custom-selection': true,
      'hide': !this.state.displayDropDown,
      'show': this.state.displayDropDown
    });

    return (
      <div>
        <div className='mobile-show'>
          {this.generateSelectOptionTags()}
          <i className="icon-down-dir" />
        </div>
        <div className="mobile-hide">
          <span className="curent-selection" onClick={this.handleClick.bind(this)}>{this.state.selected} <i className="icon-down-dir" /></span>
          <div className={classNames}>
            {this.generateListItems()}
          </div>
        </div>
      </div>
    );
  }
}

CustomSelect.propType = {
  handleChange: PropTypes.func
}

CustomSelect.defaultProps = {
  handleChange: function(e){}
}
