import React from 'react';
import { browserHistory, withRouter } from 'react-router';
import queryString from 'query-string';
import './upgrade.scss';

class PaymentButton extends React.Component {
  constructor() {
    super();
    this.state = {popupActive: false}
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    this.setState({ popupActive: query.modal === "payment" });
  }

  componentWillReceiveProps(props) {
    const query = queryString.parse(props.location.search);
    this.setState({ popupActive: query.modal === 'payment' });
  }

  render() {
    return(
      <div className="payment-btn">
        <button onClick={(e) => {
          e.preventDefault();
          this.props.updateURL('payment', {
              plan: this.props.selectedPlan.type,
              plan_id: this.props.selectedPlan.plan_id,
              promo: this.props.promoCode,
              amount: this.props.amount,
          });
        }} className="pure-button pay-btn">Continue to checkout</button>
      </div>
    )
  }
}

export default withRouter(PaymentButton);
