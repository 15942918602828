import React from "react";
import uuid from 'uuid/v4';
import Faqs from './faqs-data';
import { Link } from 'react-router';

class ContactFaqs extends React.Component {

  handleClick(e){
    let currentTarget = e.currentTarget;
    currentTarget.classList.toggle("faq-item-closed");
  }

  generatedFaqItem(){
    return Faqs.map((item, index) => {
      return (
        <div
        key={uuid()}
        className="pure-u-1 pure-u-md-1-2 faq-item clearfix faq-item-closed"
        onClick={this.handleClick}
        >
          <div className="l-box">
            <div className="faq-item-container">
              <h2>
                {item.title}
                <i className="icon-right-open" />
              </h2>
              <p className="faq-contnent">
                {item.content}
              </p>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    return(
      <div className="faqs">
        <div className="faqs-title-section">
          <div className="l-box">
            <p>Here are some of our most frequently asked questions.</p>
            <p>If you have unanswered questions then we'd like to hear them. Please <Link to="/contact">contact us</Link></p>
          </div>
        </div>
        <div className="pure-g">
          {this.generatedFaqItem()}
        </div>
      </div>
    )
  }
}

export default ContactFaqs;