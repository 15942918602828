import React from 'react';
import ObjectAssign from 'object-assign';
import LoginStore from '../stores/login-store';

export default ( InnerComponent ) => class AuthMixin extends React.Component {
  constructor(props) {
    super(props);
    this.state = ObjectAssign(this.getLoginState(), {});
    this._onChange = this._onChange.bind(this);
  }

  getLoginState() {
      return {
        userLoggedIn: LoginStore.isLoggedIn(),
        user: LoginStore.getSessionUserData(),
        userApiRequest: {
          "X-USER-EMAIL" : LoginStore.getSessionUserData().email,
          "X-USER-TOKEN" : LoginStore.getSessionUserData().authentication_token
        }
      };
  }

  componentDidMount() {
    LoginStore.addChangeListener( this._onChange )
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener( this._onChange )
  }

  _onChange() {
    this.setState( this.getLoginState() );
  }

  handleError() {
    console.log(error);
  }

  render() {
    return <InnerComponent {...this.state} {...this.props} />
  }
}
