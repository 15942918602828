import React from "react";
import AdminHeader from "./header/header";
import AdminFooter from "./footer/user-footer";
import FeedbackButton from "./feedback/feedback-button";
import Api from "../api/api";

import PopupDialog from './popups/popup-dialog';
import LoginModal from './login/login-modal';

export default class LoggedInTemplate extends React.Component {
  constructor() {
    super();
    this.state = {
      popupActive: false
    };
  }

  componentDidMount() {
    Api.scrollCentre();
  }

  render() {
    const { pathname } = this.props.location;
    const key = pathname || "root";

    let loginPopup = this.state.popupActive ? (
      <PopupDialog {...this.props}>
        <LoginModal {...this.props} {...this.state} />
      </PopupDialog>
    ) : (
      ""
    );

    return (
      <div className="main-container logged-in clearfix">
        <AdminHeader {...this.props} />
        {React.cloneElement(this.props.children || <div />, { key: key })}
        <FeedbackButton {...this.props} />
        <AdminFooter />

        {loginPopup}
      </div>
    );
  }
}
