import React from 'react';
import { Helmet } from 'react-helmet';
import AuthMixin from '../../mixins/authenticationMixin';
import CONSTANTS from '../../constants/constants';
import TitleBanner from '../../components/banners/title-banner';
import './404.scss';

class PageNotFound extends React.Component {
  render(){
    return (
      <div>
        <Helmet>
          <title>Neurochecklists | 404 Page Not Found</title>
          <meta name="description" contents="404 page"/>

        </Helmet>
        <section className="big-padding-bottom force-full-height">
          <TitleBanner title="Page not found" />
          <div className="inner-container">
              <p className="error-text">We can't find the page you're looking for.</p>
              <p className="error-text">If you've received this page in error please email <a href={`mailto:${CONSTANTS.CONTACT_EMAIL}`}>{CONSTANTS.CONTACT_EMAIL}</a></p>
          </div>
        </section>
      </div>
    )
  }
}

export default AuthMixin(PageNotFound);
