import React from 'react';
import { browserHistory } from 'react-router';
import Classnames from 'classnames';
import queryString from 'query-string';

import AppRoutes from '../../routes/routes';
import AppStore from '../../stores/stores';
import LoginStore from '../../stores/login-store';
import AuthMixin from '../../mixins/authenticationMixin';
import SearchSuggestion from './banner-search-suggestion';
import AppConstants from '../../constants/constants';

class BannerSearchForm extends React.Component {
  constructor() {
    super();
    this.state = { searchValue: "", previousSearches: {} };
    this.handleChange = this.handleChange.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
  }

  componentDidMount() {
    document.querySelector('#search-suggestions').addEventListener("click", this.resetSearch);
  }

  componentWillUnmount() {
    document.querySelector('#search-suggestions').removeEventListener("click", this.resetSearch);
  }

  resetSearch(e) {
    if (e.target.tagName === "A") {
      this.setState({searchValue: ''});
    }
  }

  handleSearch(e) {
    e.preventDefault();
    const searchValue = this.state.searchValue;
    const userData = LoginStore.getSessionUserData();
    const modalQuery = queryString.stringify({ modal: "upgrade" });
    const searchQuery = queryString.stringify({
      q: searchValue || "*",
      page: AppConstants.DEFAULTSEARCHPAGE,
      per_page: AppConstants.PERPAGE,
    });

    if (userData.searches_left <= 0 && userData.account_type === "Starter") {
      return browserHistory.replace(`${AppRoutes.homepage}?${modalQuery}`);
    }

    return browserHistory.push(`${AppRoutes.search}?${searchQuery}`);
  }

  handleChange(e) {
    const { userApiRequest } = this.props;
    const value = e.target.value;

    this.setState({ searchValue: value });

    if (value.length >= 3) {
      AppStore.getAutocomplete(value, userApiRequest, undefined, {
        onSuccess: ({ data }) => {
          const state = this.state.previousSearches;
          state[value] = data;
          this.setState({
            previousSearches: state,
          });
        },
        onError: (error) => {},
      });
    }
  }

  getData(target) {
    let results = this.state.previousSearches[this.state.searchValue] || null;
    if (!results) {
      return false;
    }

    let data = results[target] || null;

    if (!data || data.length === 0) {
      return false;
    }

    return data;
  }

  checklistsSuggestions() {
    let checklists = this.getData("checklists");
    if (checklists) {
      return (
        <SearchSuggestion
          {...this.state}
          {...this.props}
          data={checklists}
          title="Checklists"
        />
      );
    } else {
      return "";
    }
  }

  topicsSuggestions() {
    let topics = this.getData("topics");
    if (topics) {
      return (
        <SearchSuggestion
          {...this.state}
          {...this.props}
          data={topics}
          title="Topics"
        />
      );
    } else {
      return "";
    }
  }

  chaptersSuggestions() {
    let chapters = this.getData("chapters");
    if (chapters) {
      return (
        <SearchSuggestion
          {...this.state}
          {...this.props}
          data={chapters}
          title="Chapters"
        />
      );
    } else {
      return "";
    }
  }

  render() {
    let checklists,
      topics,
      chapters = "";
    let searchLength = this.state.searchValue.length;
    let hasData = false;

    if (searchLength > 2) {
      checklists = this.checklistsSuggestions();
      topics = this.topicsSuggestions();
      chapters = this.chaptersSuggestions();
      hasData = checklists.props || topics.props || chapters.props;
    }

    let suggestionClasses = Classnames({
      "pure-u-1": true,
      "search-suggestions": searchLength > 2 && hasData,
    });

    let suggestionDataClasses = Classnames({
      "l-box": searchLength > 2 && hasData,
      "search-suggestions-open": searchLength > 2 && hasData,
    });

    return (
      <form
        onSubmit={this.handleSearch.bind(this)}
        className="pure-form pure-g banner-search-form"
      >
        <div className="pure-u-20-24 pure-u-md-21-24 search-input-field">
          <input
            placeholder="Disorder, Symptom or Disease"
            onChange={this.handleChange}
            className="pure-input-1"
            type="search"
            value={this.state.searchValue}
            required={true}
          />
          <i className="icon-search" />
        </div>
        <div className="pure-u-4-24 pure-u-md-3-24">
          <button className="pure-button pure-u-1">
            <span className="mobile-hide">Search</span>
            <i className="icon-search mobile-show" />
          </button>
        </div>
        <div id="search-suggestions" className={suggestionClasses}>
          <div className={suggestionDataClasses}>
            {checklists}
            {topics}
            {chapters}
          </div>
        </div>
      </form>
    );
  }
}

export default AuthMixin(BannerSearchForm);
