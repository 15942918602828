import React from 'react';
import Api from '../../api/authApi';
import AppRoutes from '../../routes/routes';
import { SERVER_ERROR } from '../../constants/messages';
import Loading from '../loading/loading';

export default class ForgotPassword extends React.Component {
  constructor(){
    super();
    this.state = {
      success: false,
      error: false,
      loading: false
    };
  }
  handleSubmit(e){
    e.preventDefault();

    this.setState({ loading: true });

    Api.sendForgotPasswordEmail({ email: this.refs.email.value }, {
      onSuccess: ({data}) => {
        this.setState({
          success: true,
          loading: false
        });
      },
      onError: () => {
        this.setState({
          error: true,
          loading: false
        });
      }
    });
  }

  errorMsg(){
    return(
      <div className="alert alert-danger">
        <p>{SERVER_ERROR}</p>
      </div>
    )
  }

  forgotPasswordForm(){
    let errorMsg = (this.state.error)? this.errorMsg(): null;
    return(
      <div>
        {(this.state.loading)? <Loading text="Please wait..." /> : null }
        <form
          className="pure-form login-form"
          onSubmit={this.handleSubmit.bind(this)}
        >
          <span className="login-form-title">Please enter your email address. <br/> You will receive a link to create a new password via email</span>
          <fieldset>
            {errorMsg}
            <div className="pure-g text-field clearfix">
              <div className="pure-u-1-5 fl">Email*</div>
              <input className="pure-u-4-5" id="email" type="email" ref="email" name="email" placeholder="Your Email" required />
            </div>
          </fieldset>
          <div className="login-form-submit">
            <button type="submit" className="pure-button login-submit-button">Get New Password</button>
          </div>
        </form>
      </div>
    )
  }

  successMessage(){
    return(
      <div className="pure-g thanks-popup">
        <div className="pure-u-1">
          <section className="l-box">
            <h1>Thanks requesting for a password reset.</h1>
            <img className="pure-img" src={require('../../../images/email.png')} />
            <div className="text-inner">
              <p>We have send you instructions for reseting your password to
              your email address. Don’t forget to check your junk/spam folder.
              If you still haven’t received the email please &nbsp;
              <a href={AppRoutes.contact} className="link">contact us</a>
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </section>
        </div>
      </div>
    )
  }

  render(){
    let formOrSuccess = ( this.state.success) ? this.successMessage() : this.forgotPasswordForm();
    return formOrSuccess;
  }
}
