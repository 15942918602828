import React from 'react';

export default class VideoSection extends React.Component {
    render() {
        let { video } = this.props;

        return video ? (
            <div className="tablet-hide">
                <div className="l-box">
                    <div className="news-article-content bg-grey">
                        <div className="text-content">
                            <h2>Find us on YouTube</h2>
                            <div className="video-wrapper">
                                <iframe
                                    src={`https://www.youtube.com/embed/${video}`}
                                    title="Neurochecklists Video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }
}
