export default {
  REQUEST_LOGIN_USER: 'REQUEST_LOGIN_USER',
  REQUEST_LOGIN_USER_SUCCESS: 'REQUEST_LOGIN_USER_SUCCESS',
  REQUEST_LOGIN_USER_ERROR: 'REQUEST_LOGIN_USER_ERROR',

  REQUEST_SIGNUP_USER: 'REQUEST_SIGNUP_USER',
  REQUEST_SIGNUP_USER_SUCCESS: 'REQUEST_SIGNUP_USER_SUCCESS',
  REQUEST_SIGNUP_USER_ERROR: 'REQUEST_SIGNUP_USER_ERROR',

  USER_DETAILS_UPDATED: 'USER_DETAILS_UPDATED',
  USER_DETAILS_UPDATE_ERROR: 'USER_DETAILS_UPDATE_ERROR',

  REQUEST_LOGOUT_USER: 'REQUEST_LOGOUT_USER',
  REQUEST_LOGOUT_USER_SUCCESS: 'REQUEST_LOGOUT_USER_SUCCESS',

  REQUEST_FORGOT_PASSWORD_VIEW: 'REQUEST_FORGOT_PASSWORD_VIEW',
  REQUEST_THANK_YOU_VIEW: 'REQUEST_THANK_YOU_VIEW',

  CHECKLISTVIEW: 'CHECKLISTVIEW'
};
