import "@babel/polyfill";
import 'classlist-polyfill';
import 'web-animations-js/web-animations-next-lite.min';

// ensure that htese are imported before any other components, so they have an early position in the css load order.
import 'purecss/build/pure.css';
import 'purecss/build/grids-responsive.css';

import React from 'react';
import FastClick from 'fastclick';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import store from 'store';
import App from './app';

import '../css/typography.css';
import '../css/font-icons.css';
import '../css/main.scss';

function run() {
  const container = document.getElementById('main');

  // Make taps on links and buttons work fast on mobiles
  FastClick.attach(document.body);

  //Inialize Google Analytics
  ReactGA.initialize(process.env.GA_CODE);
  ReactDOM.render(<App />, container);

  // Check if store is enabled in browser
  if (!store.enabled) {
		alert('Local storage is not supported by your browser. Please disable "Private Mode", or upgrade to a modern browser.')
		return;
	}
}

// Run the application when both DOM is ready and page content is loaded
if (['complete', 'loaded', 'interactive'].includes(document.readyState) && document.body) {
  run();
} else {
  document.addEventListener('DOMContentLoaded', run, false);
}
