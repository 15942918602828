import store from 'store';
import { SERVER_ERROR } from '../constants/messages';
import { register } from '../dispatchers/dispatcher';
import BaseStore from './base-store';
import AppAuthConstants from '../constants/auth-constants'
import authAPI from '../api/authApi';
import ReactGA from 'react-ga';

class LoginStore extends BaseStore {
  constructor() {
    super();
    this.subscribe( this.dispatcherIndex.bind(this) );
    this.errors = null;
    this.key = "3D9C1EDB38DABE27";
  }

  dispatcherIndex() {
    this.errors = null;

    register( ( action ) => {
      this.errors = null;

      switch(action.actionType) {

        case AppAuthConstants.REQUEST_LOGIN_USER:
          authAPI.attemptLogin(action.email, action.password);
          this.emitChange();
          break;

        case AppAuthConstants.REQUEST_LOGIN_USER_SUCCESS:
          ReactGA.event({
            category: 'User',
            action: 'Logged In'
          });
          authAPI.storeSessionUser(this.key, action.data);
          this.emitChange();
          break;

        case AppAuthConstants.REQUEST_LOGIN_USER_ERROR:
          this.errors = action.error || SERVER_ERROR;
          this.emitChange();
          break;

        case AppAuthConstants.REQUEST_LOGOUT_USER:
          authAPI.logout(action.headers, action.redirectPath);
          store.clearAll();
          break;

        case AppAuthConstants.REQUEST_LOGOUT_USER_SUCCESS:
          store.clearAll();
          authAPI.changeLocation(action.redirectPath || '/');
          break;

        case AppAuthConstants.REQUEST_SIGNUP_USER:
          authAPI.attemptRegister(action.data);
          this.emitChange();
          break;

        case AppAuthConstants.REQUEST_SIGNUP_USER_SUCCESS:
          ReactGA.event({
            category: 'User',
            action: 'Created an Account'
          });
          authAPI.storeSessionUser(this.key, action.data);
          this.emitChange({modal: 'thank-you'});
          break;

        case AppAuthConstants.REQUEST_SIGNUP_USER_ERROR:
          this.errors = action.error || SERVER_ERROR;
          this.emitChange();
          break;

        case AppAuthConstants.USER_DETAILS_UPDATED:
          ReactGA.event({
            category: 'User',
            action: 'Updated Profile'
          });
          authAPI.updateSessionUserData(this.key, action.data);
          this.emitChange({success: true});
          break;

        case AppAuthConstants.USER_DETAILS_UPDATE_ERROR:
          this.emitChange({success: false});
          break;

        case AppAuthConstants.REQUEST_FORGOT_PASSWORD_VIEW:
          ReactGA.modalview('forgot-password');
          this.emitChange({modal: 'forgot-password'});
          break;

        case AppAuthConstants.CHECKLISTVIEW:
          ReactGA.event({
            category: 'Checklist',
            action: 'Viewed',
            label: window.location.pathname.replace('/explore/checklists/', '')
          });
          authAPI.updateSessionUserData(this.key, action.data);
          this.emitChange();
          break;
      }
    });

  }

  getSessionToken() {
    return authAPI.getSessionToken(this.key)
  }
  getSessionUser() {
    return authAPI.getSessionUser(this.key)
  }

  getSessionUserData() {
    return authAPI.getSessionUserData(this.key)
  }

  getLoginErrors() {
    return this._error;
  }

  isLoggedIn() {
    return authAPI.isLoggedIn(this.key);
  }

  updateSessionUserData(data, callbacks) {
    return authAPI.updateSessionUserData(this.key, data, callbacks);
  }

  updateUser(headers, data, callbacks) {
    return authAPI.updateUser(headers, data, callbacks);
  }

  updateUserPassword(headers, data, callbacks) {
    return authAPI.updateUserPassword(headers, data, callbacks);
  }

  verifyToken(token, callbacks) {
    return authAPI.verifyToken(token, callbacks);
  }

}

export default new LoginStore();
