import React from 'react';
import AppRoutes from '../../routes/routes';

export default class Thanks extends React.Component {
  render(){
    return (
      <div className="pure-g thanks-popup">
        <div className="pure-u-1">
          <section className="l-box">
            <h1>Thanks for signing up to Neurochecklists!</h1>
            <img className="pure-img" src={require('../../../images/smiley-face.png')} />
            <div className="text-inner">
              <p>Before you can begin using Neurochecklists you have to confirm
              your email address. Don’t forget to check your junk/spam folder.
              If you still haven’t received the email please <a href={AppRoutes.contact} className="link">contact us</a></p>
            </div>
            <footer>
              <p><strong>Click the link in your email to activate your account.</strong></p>
            </footer>
          </section>
        </div>
      </div>
    )
  }
}
