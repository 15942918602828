import React from 'react';
import { Link } from 'react-router';
import AuthMixin from '../../mixins/authenticationMixin';
import BannerSearchForm from './banner-search-form';
import AppRoutes from '../../routes/routes';
import uuid from "uuid/v4";

import './search-banner.scss';

class SearchBanner extends React.Component {
  render() {
    let recent_searches = this.props.recentSearches && this.props.recentSearches.map ? this.props.recentSearches.map( (item, index, array) => {
      let lastItem = index === (array.length - 1);
      return (
        <li key={uuid()}>
          <Link title={item.title} to={`${AppRoutes.checklists}${item._slugs[0]}`} >
            {item.title}{(lastItem)? '': ',' }
          </Link>
        </li>
      )
    }) : '';

    return (
      <div className="banner-title-container">
        <div className="inner-container">
          <div className="search-banner">
            <div className="search-banner-logo">
              <img
                className="search-banner-logo-img"
                src={require("../../../images/logo.svg")}
              />
              <aside className="search-banner-aside">
                your gateway to neurology
              </aside>
            </div>
          </div>

          <div className="banner-form">
            <BannerSearchForm {...this.props} />

            {recent_searches.length > 0 ? (
              <div className="pure-g">
                <div className="pure-u-1 tal recent-searches">
                  <span className="bold">Your Recent Searches:</span>
                  <ul className="recent-searches-list">{recent_searches}</ul>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="banner-form explore-option-horizontal">
            <Link
              title="Explore"
              to={AppRoutes.explore}
              className="explore-button"
            >
              <span className="explore-option-title">Explore</span>
            </Link>
          </div>
        </div>
      </div>
    );

  }
}

export default AuthMixin(SearchBanner);
