import React from 'react';
import { Helmet } from 'react-helmet';
import { browserHistory } from 'react-router';
import queryString from 'query-string';
import LoginStore from '../../stores/login-store';
import AppRoutes from '../../routes/routes'
import Banner from '../../components/banners/title-banner';
import Subscribe from '../../components/subcribe/subscribe';
import './pricing.scss';

const registerQuery = queryString.stringify({modal: 'register'});

export default class Pricing extends React.Component {
  registerModal() {
    browserHistory.replace(`${this.props.location.pathname}?${registerQuery}`);
  }
  render() {
    const tableFooter = (LoginStore.isLoggedIn()) ? '' : (
      <tfoot className="pricing-table-footer">
        <tr>
          <td colSpan="2">
            <p>Create your account in minutes. <a href={AppRoutes.tcs} target="_blank">Terms &amp; conditions</a> apply.</p>
            <button className="pure-button contact-us-footer-button" onClick={this.registerModal.bind(this)}>Get started</button>
            <small>Student discount available, subscribe with a valid institutional email address to qualify</small>
          </td>
        </tr>
      </tfoot>
    );

    return (
      <div>
        <Helmet>
          <title>Neurochecklists | Pricing</title>
          <meta name="description" contents="Pricing for Neurochecklists. Free trial with student and group discounts available."></meta>
        </Helmet>
        <Banner title="Pricing" />
        <div className="inner-container">
          <div className="l-box">
            <div className="pure-g pricing-table">
              <div className="pure-u-1">
                <table width="100%" className="pure-table pure-table-horizontal">
                  <thead className="pricing-table-head">
                    <tr>
                      <th colSpan="2">
                        <h1 className="pricing-table-heading">
                          <span className="pricing-table-heading--thin clickable" onClick={this.registerModal.bind(this)}>Free Trial</span>
                          <span className="pricing-table-heading--normal">View 15 fully-referenced checklists</span>
                          <hr />
                          <span className="pricing-table-heading--normal">Go premium with unlimited access for <del>&pound;4.99</del> <b>&pound;2.49</b> per month</span>
                          <span className="pricing-table-heading--normal cgreen">*12 months for the price of 10 if you pay yearly. Only <del>&pound;49.99</del> <b>&pound;24.99</b></span>
                        </h1>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="pricing-table-pusher"></tr>
                    <tr>
                      <td><span className="pricing-info">Smart, quick and easy to search</span> <i className="icon-ok"/></td>
                      <td><span className="pricing-info">Extensive and comprehensive range of topics</span> <i className="icon-ok"/></td>
                    </tr>
                    <tr>
                      <td><span className="pricing-info">Bookmark all checklists for easy acess</span><i className="icon-ok"/></td>
                      <td><span className="pricing-info">View Popular checklists</span><i className="icon-ok"/></td>
                    </tr>
                    <tr>
                      <td><span className="pricing-info">Evidence ­based references linked to sources</span> <i className="icon-ok"/></td>
                      <td><span className="pricing-info">Accessible on the move</span> <i className="icon-ok"/></td>
                    </tr>
                    <tr>
                      <td><span className="pricing-info">Keep notes and reminders</span> <i className="icon-ok"/></td>
                      <td><span className="pricing-info">Easy download for presentations</span> <i className="icon-ok"/></td>
                    </tr>
                  </tbody>
                  {tableFooter}
                </table>
              </div>
            </div>
          </div>
        </div>

        <Subscribe />

      </div>
    )
  }
}
