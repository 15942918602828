import React from 'react';
import Api from '../../api/api';
import CustomSelect from '../custom-elements/custom-select';
import { SERVER_ERROR } from '../../constants/messages';
import AuthMixin from '../../mixins/authenticationMixin';
import Loading from '../loading/loading';

import './feedback.scss';

class FeedbackForm extends React.Component {
  constructor(){
    super();
    this.state = {
      success: false,
      error: false,
      loading: false,
      subject: '',
      defaultSubject: 'Choose a subject...'
    };
  }

  handleSelect(e){
    var targetValue = '';
    if (Api.selectorMatches(e.currentTarget, 'li')) {
      targetValue = e.currentTarget.textContent;
    } else if (Api.selectorMatches(e.currentTarget, 'select')) {
      targetValue = e.currentTarget.value;
    }

    if(targetValue && targetValue !== this.state.defaultSubject){
      this.setState({ subject: targetValue });
    } else {
      this.setState({ subject: '' });
    }

  }

  handleSubmit(e){
    e.preventDefault();

    const form = e.target;
    const subject = this.state.subject;
    const detail = form.querySelector('textarea').value;

    this.setState({ loading: true });

    if(!subject || !detail) {
      this.setState({
        error: true,
        success: false,
        errorMsg: 'Please fill in all fields',
        loading: false
      });
    } else {
      Api.sendFeedback(this.props.userApiRequest, {subject, detail}, {
        onSuccess: ({data}) => {
          form.reset();
          this.setState({
            success: true,
            error: false,
            loading: false
          });
        },
        onError: () => {
          this.setState({
            error: true,
            success: false,
            loading: false,
            errorMsg: SERVER_ERROR
          });
        }
      });
    }
  }

  render(){
    var msg;
    let successMsg = (
      <div className="pure-u-1 input-holder">
        <div className="alert alert-success" role="alert">
          Thanks for your feedback!
        </div>
      </div>
    );

    let failMsg = (
      <div className="pure-u-1 input-holder">
        <div className="alert alert-danger" role="alert">
          {this.state.errorMsg}
        </div>
      </div>
    );

    if(this.state.success){
      msg = successMsg;
    } else if(this.state.error) {
      msg = failMsg;
    } else {
      msg = null;
    }

    return (
      <div className="feedback-modal">
        {(this.state.loading)? <Loading text="Please wait..." /> : null }

        <form onSubmit={this.handleSubmit.bind(this)} className="feedback-form pure-form">
          <div className="form-container pure-g">
            <header className="pure-u-1">
              <div className="input-holder">
                <h1 className="title">Leave some feedback</h1>
                <p className="sub-title">Help us make your experience better</p>
              </div>
            </header>
            {msg}
            <div className="pure-u-1 input-holder">
              <div id="custom-element" className="custom-select-holder">
                <CustomSelect
                  handleChange={ (e) => this.handleSelect(e) }
                  default={'default'}
                  options={{
                    'default': this.state.defaultSubject,
                    'General Feedback': 'General Feedback',
                    'Suggest a new checklist': 'Suggest a new checklist',
                    'Fault in a checklist': 'Fault in a checklist',
                    'Others': 'Other'
                  }}
                />
              </div>
            </div>
            <div className="input-holder">
              <textarea className="pure-input-1" name="message" placeholder="Please type your feedback here" />
            </div>
            <footer className="feedback-form-footer pure-g clearfix">
              <div className="pure-u-1-2">
                <div className="l-box">
                  <img className="pure-img" src={require('../../../images/blue-logo.png')}/>
                </div>
              </div>
              <div className="pure-u-1-2">
                <div className="l-box clearfix">
                  <button type="submit" className="pure-button">Send message</button>
                </div>
              </div>
            </footer>
          </div>
        </form>
      </div>
    )
  }
}

export default AuthMixin(FeedbackForm);
