import React from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import AppStore from '../../stores/stores';
import AuthMixin from '../../mixins/authenticationMixin';
import TitleBanner from '../../components/banners/title-banner';
import DataError from '../../components/errors/data-error';

import ExploreAll from '../../components/banners/explore-all';

class IndexPage extends React.Component {
  constructor() {
    super();
    this.state = { data: [], sortBy: 'default', error: false }
  }

  componentDidMount() {
    setTimeout(() => window.scrollTo(0, 0));

    AppStore.getParts(this.props.userApiRequest, {
      onSuccess: ({data}) => this.setState({data}),
      onError: error => this.setState({error: true})
    });

    ReactGA.event({
      category: "User",
      action: "Explored"
    });
  }

  render() {
    if(this.state.error) {
      return <DataError />
    }

    return (
      <div>
        <Helmet>
          <title>Neurochecklists | Explore neurology</title>
          <meta name="description" content="Explore an index of fully referenced neurological data, perfect for clinicians, students and expert patients. The perfect companion to search" />

          <meta name="og:description" content="Explore an index of fully referenced neurological data, perfect for clinicians, students and expert patients. The perfect companion to search" />
          <meta name="og:title" content="Neurochecklists | Explore neurology" />
        </Helmet>
        <TitleBanner title="Explore" />
        <section className="big-padding-bottom l-box">
          <div><ExploreAll sort={this.state.sortBy} /></div>
        </section>
      </div>
    )
  }
}

export default AuthMixin(IndexPage);
