import React from "react";
import { Link, withRouter } from "react-router";
import uuid from "uuid/v4";
import queryString from "query-string";
import classNames from "classnames";

import AuthMixin from "../../mixins/authenticationMixin";
import AppStore from "../../stores/stores";
import AppRoutes from "../../routes/routes";
import DataError from "../errors/data-error";
import LocalLoading from "../../components/loading/local-loading";
import ExploreIcon from "./explore-icon";
import CollapsibleLi from "../checklists/collapsible-li";
import api from "../../api/api";

import "./explore-options.scss";

class ExploreAll extends React.Component {
  constructor() {
    super();

    this.state = {
      data: [],
      loading: true,
      open: false,
    };

    this._scrollTo = this._scrollTo.bind(this);
    this._isCurrentOrAncestor = this._isCurrentOrAncestor.bind(this);
  }

  _isCurrentOrAncestor(item) {
    let search = this.state.open;
    if (!search) {
      return;
    }

    let slugs = item._slugs;
    /**
     * this is the absolute worst
     */
    if (item.chapters) {
      slugs = [...slugs, ...item.chapters.map((chapter) => chapter._slugs[0])];
      slugs = [
        ...slugs,
        ...item.chapters.map((chapter) =>
          chapter.topics.map((topic) => topic._slugs[0])
        ),
      ];
      slugs = [
        ...slugs,
        ...item.chapters.map((chapter) =>
          chapter.topics.map((topic) =>
            topic.checklists.map((checklist) => checklist._slugs[0])
          )
        ),
      ];
    } else if (item.topics) {
      slugs = [...slugs, ...item.topics.map((topic) => topic._slugs[0])];
      slugs = [
        ...slugs,
        ...item.topics.map((topic) =>
          topic.checklists.map((checklist) => checklist._slugs[0])
        ),
      ];
    } else if (item.checklists) {
      slugs = [
        ...slugs,
        ...item.checklists.map((checklist) => checklist._slugs[0]),
      ];
    }

    slugs = slugs.flat(3);
    return slugs.indexOf(search) !== -1;
  }

  _scrollTo(e) {
    if (e) {
      e.preventDefault();
    }

    if (!this.state.open) {
      return;
    }

    let el = document.getElementById(this.state.open);

    if (el) {
      api.scrollTo(el);
    } else {
      window.scrollTo(0, 0);
    }
  }

  resolveSearch(location) {
    const { search } = location;
    let parsed = queryString.parse(search);
    if (parsed.open) {
      console.log('setting state');
      this.setState({
        open: parsed.open,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.resolveSearch(this.props.location);
      setTimeout(this._scrollTo(), 200);
    }
  }

  componentDidMount() {
    this.resolveSearch(this.props.location);

    AppStore.getParts(this.props.userApiRequest, {
      onSuccess: ({ data }) => {
        this.setState({ data, loading: false });
        setTimeout(this._scrollTo(), 200);
      },
      onError: () => this.setState({ error: true, loading: false }),
    });
  }

  componentWillReceiveProps(newProps) {
    let newData = this.state.data;

    this.setState({
      data: newData,
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="inner-container">
          <LocalLoading text="Loading..." />
        </div>
      );
    }
    if (this.state.error) {
      return (
        <div className="inner-container">
          <DataError />;
        </div>
      );
    }
    return (
      <div className="inner-container">
        <ul className="explore-options explore-option--outlined">
          {this.state &&
            this.state.data &&
            this.state.data.map((cat) => {
              let classes = classNames({
                "explore-list": true,
              });
              return (
                <CollapsibleLi
                  key={cat.title}
                  current={this._isCurrentOrAncestor(cat)}
                  toggleElement={
                    <div id={cat._slugs[0]} className={classes}>
                      <div className="explore-option">
                        <div className="explore-option-link">
                          <div className="explore-option-horizontal">
                            <ExploreIcon cat={cat} />
                            <h3 className="explore-option-title">
                              {cat.title}
                            </h3>
                            <div className="explore-option-meta">
                              <table className="explore-meta-list">
                                <tbody>
                                  <tr>
                                    <td className="explore-meta-heading clblue">
                                      Chapters
                                    </td>
                                    <td className="explore-meta-count">
                                      {cat.chapter_count || 0}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="explore-meta-heading cred">
                                      Topics
                                    </td>
                                    <td className="explore-meta-count">
                                      {cat.topics_count || 0}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="explore-meta-heading cdgreen">
                                      Checklists
                                    </td>
                                    <td className="explore-meta-count">
                                      {cat.checklists_count || 0}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <div className="context-overview">
                    <ul className="siblings clblue">
                      {cat.chapters.map((chapter) => {
                        let classes = classNames({
                          cred: true,
                        });
                        return (
                          <CollapsibleLi
                            id={chapter._slugs[0]}
                            key={uuid()}
                            entry={chapter.title}
                            current={this._isCurrentOrAncestor(chapter)}
                          >
                            <ul className={classes}>
                              {chapter.topics.map((topic) => {
                                let classes = classNames({
                                  spgreen: true,
                                  noicon: true,
                                });
                                return (
                                  <CollapsibleLi
                                    id={topic._slugs[0]}
                                    key={uuid()}
                                    entry={topic.title}
                                    current={this._isCurrentOrAncestor(topic)}
                                  >
                                    <ul className={classes}>
                                      {topic.checklists.map((checklist) => {
                                        return (
                                          <li
                                            key={uuid()}
                                            id={checklist._slugs[0]}
                                          >
                                            <Link
                                              to={`${AppRoutes.checklists}${checklist._slugs[0]}`}
                                            >
                                              {checklist.title}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </CollapsibleLi>
                                );
                              })}
                            </ul>
                          </CollapsibleLi>
                        );
                      })}
                    </ul>
                  </div>
                </CollapsibleLi>
              );
            })}
        </ul>
      </div>
    );
  }
}

export default withRouter(AuthMixin(ExploreAll));
