import React from 'react';
import { withRouter, browserHistory } from 'react-router';
import { Helmet } from 'react-helmet';

class IndexRedirector extends React.Component {
    componentDidMount() {
        // all this does is instantly switch the URL over.
        browserHistory.replace(`${this.props.location.pathname.replace(/index/, 'explore')}?${this.props.location.search}`)
    }

    render() {
        return (
            <Helmet>
                <link rel="canonical" href={`https://neurochecklists.com${this.props.location.pathname.replace(/index/, 'explore')}?${this.props.location.search}`}></link>
            </Helmet>
        )
    }
}

export default withRouter(IndexRedirector);