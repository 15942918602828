import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../components/banners/title-banner';
import CONSTANTS from '../../constants/constants';


export default (props) => (
    <div>
      <Helmet>
          <title>Neurochecklists | Privacy and data policy</title>
          <meta name="description" contents="Privacy and data policy for Neurochecklists"></meta>
        </Helmet>
      <Banner title="Privacy and cookie policy" />
      <div className="inner-container small-top-padding big-padding-bottom">
        <div className="pure-g terms-and-conditions">
          <div className="pure-u-1">
            <div className="l-box">
              <ol className="legals-ol">
                <li className="legals-ol-li">Introduction
                  <ol className="legals-ol">
                    <li className="legals-ol-li">
                      ZK &amp; I Consultants Ltd (&#8220;we&#8221; &quot;us&quot;) is committed to safeguarding the privacy of www.neurochecklists.com (&quot;ourl website&quot;) 's visitors, this policy sets out how we will treat your personal information
                    </li>
                    <li className="legals-ol-li">
                      By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.
                    </li>
                  </ol>
                </li>
                <li className="legals-ol-li"> Collecting personal information
                  <ol className="legals-ol">
                    <li className="legals-ol-li">We may collect, store and use the following kinds of personal information:
                      <ul style={{listStyleType: 'lower-alpha'}}>
                        <li>information about your computer and about your visits to and use of this website (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths),</li>
                        <li>information that you provide to us when registering with our website (including your email address);</li>
                        <li>information that you provide when completing your profile on our website (d) information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (including your name and email address),</li>
                        <li>information that you provide to us when using the services on our website, or that is generated in the course of the use of those services including the timing, frequency and pattern of service use,</li>
                        <li>information relating to any purchases you make of our services or any other transactions that you enter into through our website (including your name, address, telephone number, email address and card details,)</li>
                        <li>information that you post to our website for publication on the internet (including your user name, your profile pictures and the content of your posts),</li>
                        <li>information contained in or relating to any communications that you send to us or send through our website (including the communication content and meta data associated with the communication),</li>
                        <li>any other personal information that you choose to send to us, and </li>
                      </ul>
                    </li>
                    <li className="legals-ol-li">Before you disclose to us the personal information of another person, you must obtain that person's consent to both the disclosure and the processing of that personal information in accordance with the terms of this policy.</li>

                  </ol>
                </li>
                <li className="legals-ol-li">Using your personal information
                  <ol className="legals-ol">
                    <li className="legals-ol-li">Personal information submitted to us through our website will be used for the purposes specified in this policy or on the relevant pages of the website.</li>
                    <li className="legals-ol-li">ZK &amp;I Consultants Ltd may use your personal information to: (a) administer our website and business, (b) personalise our website for you, (c) enable your use of the services available on our website, (d) supply to you services purchased through our website,
                      <ul style={{listStyleType: 'lower-alpha'}}>
                        <li>administer our website and business</li>
                        <li>personalise our website for you,</li>
                        <li>enable your use of the services available on our website,</li>
                        <li>supply to you services purchased through our website,</li>
                        <li>send statements, invoices and payment reminders to you, and collect payments from you,</li>
                        <li>send you non-marketing commercial communications,</li>
                        <li>send you email notifications that you have specifically requested,</li>
                        <li>send you our email newsletter, if you have requested it (you can inform us at any time if you no longer require the newsletter),</li>
                        <li>send you marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you, either by post or email or similar technology (you can inform us at any time if you no longer require marketing communications),</li>
                        <li>provide third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information),</li>
                        <li>deal with enquiries and complaints made by or about you relating to our website,</li>
                        <li>keep our website secure and prevent fraud,</li>
                        <li>verify compliance with the terms and conditions governing the use of our website (including monitoring messages sent through our website)</li>
                      </ul>
                    </li>
                    <li className="legals-ol-li">If you submit personal information for publication on our website, we will publish and otherwise use that information in accordance with the licence you grant to us.</li>
                    <li className="legals-ol-li">ZK &amp; I Consultants Ltd will share information with our payment services provider only to the extent necessary for the purposes of processing payments you make via our website, refunding such payments and dealing with complaints and queries relating to such payments and refunds.</li>
                  </ol>
                </li>
                <li className="legals-ol-li">Disclosing personal information
                  <ol className="legals-ol">
                    <li className="legals-ol-li">ZK &amp;I Consultants Ltd may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this policy</li>
                    <li className="legals-ol-li">ZK &amp;I Consultants Ltd may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes set out in this policy.</li>
                    <li className="legals-ol-li">ZK &amp; I Consultants may disclose your personal information:
                      <ul style={{listStyleType: 'lower-alpha'}}>
                        <li>to the extent that we are required to do so by law;</li>
                        <li>in connection with any ongoing or prospective legal proceedings;</li>
                        <li>in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk),</li>
                        <li>to the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling, and</li>
                        <li>to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.</li>
                      </ul>
                    </li>
                    <li className="legals-ol-li">Except as provided in this policy, we will not provide your personal information to third parties.</li>
                  </ol>
                </li>
                <li className="legals-ol-li">International data transfers
                  <ol className="legals-ol">
                    <li className="legals-ol-li">Information that we collect may be stored and processed in and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this policy.</li>
                    <li className="legals-ol-li">Information that we collect may be transferred to the following countries which do not have data protection laws equivalent to those in force in the European Economic Area: [the United States of America, Russia, Japan, China and India].</li>
                    <li className="legals-ol-li">Personal information that you publish on our website or submit for publication on our website may be available, via the internet, around the world. ZK &amp;I Consultants Ltd cannot prevent the use or misuse of such information by others.</li>
                    <li className="legals-ol-li">You expressly agree to the transfers of personal information described in this Section 5.</li>
                  </ol>
                </li>
                <li className="legals-ol-li">Retaining personal information
                  <ol className="legals-ol">
                    <li className="legals-ol-li">This Section 6 sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal information.</li>
                    <li className="legals-ol-li">Personal information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</li>
                    <li className="legals-ol-li">Notwithstanding the other provisions of this Section 6, we will retain documents (including electronic documents) containing personal data:
                      <ul style={{listStyleType: 'lower-alpha'}}>
                        <li>to the extent that we are required to do so by law,</li>
                        <li>if we believe that the documents may be relevant to any ongoing or prospective legal proceedings, and</li>
                        <li>in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</li>
                      </ul>
                    </li>
                  </ol>
                </li>

                <li className="legals-ol-li">Security of your personal information
                  <ol className="legals-ol">
                    <li className="legals-ol-li">ZK &amp; I Consultants Ltd will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information</li>
                    <li className="legals-ol-li">ZK &amp; I Consultants Ltd will store all the personal information you provide on our secure (password- and firewall-protected) servers</li>
                    <li className="legals-ol-li">You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</li>
                    <li className="legals-ol-li">You are responsible for keeping the password you use for accessing our website confidential, we will not ask you for your password (except when you log in to our website).</li>
                  </ol>
                </li>

                <li className="legals-ol-li">Amendments
                  <ol className="legals-ol">
                    <li className="legals-ol-li">ZK &amp; I Consultants Ltd may update this policy from time to time by publishing a new version on our website.</li>
                    <li className="legals-ol-li">You should check this page occasionally to ensure you are happy with any changes to this policy</li>
                  </ol>
                </li>

                <li className="legals-ol-li">Your rights
                  <ol className="legals-ol">
                    <li className="legals-ol-li">You may instruct us to provide you with any personal information we hold about you, provision of such information will be subject to:
                      <ul style={{listStyleType: 'lower-alpha'}}>
                        <li>the payment of a fee (currently &#163;10 GBP), and</li>
                        <li>the supply of appropriate evidence of your identity (for this purpose, we will usually accept a photocopy of your passport certified by a solicitor or bank plus an original copy of a utility bill showing your current address).</li>
                      </ul>
                    </li>
                    <li className="legals-ol-li">ZK &amp; I Consultants Ltd may withhold personal information that you request to the extent permitted by law</li>
                    <li className="legals-ol-li">You may instruct us at any time not to process your personal information for marketing purposes.</li>
                    <li className="legals-ol-li">In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal information for marketing purposes.</li>
                  </ol>
                </li>

                <li className="legals-ol-li">Third party websites
                  <ol className="legals-ol">
                    <li className="legals-ol-li">Our website includes hyperlinks to, and details of, third party websites.</li>
                    <li className="legals-ol-li">We have no control over, and are not responsible for, the privacy policies and practices of third parties.</li>
                  </ol>
                </li>

                <li className="legals-ol-li">Updating information
                  <ol className="legals-ol">
                    <li className="legals-ol-li">Please let us know if the personal information that we hold about you needs to be corrected or updated.</li>
                  </ol>
                </li>

                <li className="legals-ol-li">Cookies
                  <ol className="legals-ol">
                    <li className="legals-ol-li">ZK &amp; I Conuktants Ltd may use both session and persistent cookies on our website.</li>
                    <li className="legals-ol-li">A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</li>
                    <li className="legals-ol-li">Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</li>
                    <li className="legals-ol-li">Cookies can be used by web servers to identity and track users as they navigate different pages on a website and identify users returning to a website.</li>
                  </ol>
                </li>

                <li className="legals-ol-li">Analytics cookies
                  <ol className="legals-ol">
                    <li className="legals-ol-li">ZK &amp; I Consultants Ltd may use Google Analytics to analyse the use of our website.</li>
                    <li className="legals-ol-li">Our analytics service provider generates statistical and other information about website use by means of cookies</li>
                    <li className="legals-ol-li">The information generated relating to our website is used to create reports about the use of our website.</li>
                  </ol>
                </li>

                <li className="legals-ol-li">Third party cookies
                  <ol className="legals-ol">
                    <li className="legals-ol-li">Our website may also use third party cookies.</li>
                  </ol>
                </li>

                <li className="legals-ol-li">Blocking/deleting cookies
                  <ol className="legals-ol">
                    <li className="legals-ol-li">Most browsers allow you to refuse to accept cookies. You can manage your preferences relating to the use of cookies by going to &#8220;S etting and or Tools&#8221; on your web browser.</li>
                    <li className="legals-ol-li">Blocking/deleting all cookies will have a negative impact upon the usability of many websites.</li>
                  </ol>
                </li>

                <li className="legals-ol-li">Our details
                  <ol className="legals-ol">
                    <li className="legals-ol-li">This website is owned and operated by ZK &amp; I Consultants Ltd</li>
                    <li className="legals-ol-li">We are registered in England under registration number 7765383 and our registered office is at 19 Moorland Close, Bittaford, Ivybridge. Devon. PL21 0EQ</li>
                    <li className="legals-ol-li">You can contact us by using our website contact form or by emailing {CONSTANTS.CONTACT_EMAIL}</li>
                  </ol>
                </li>

              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
)
