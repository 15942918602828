import React from 'react';
import { Helmet } from 'react-helmet';
import AppRoutes from '../../routes/routes';
import Banner from '../../components/banners/title-banner';
import CONSTANTS from '../../constants/constants';

export default () => (
  <div>
    <Helmet>
      <title>Neurochecklists | Terms &amp; Conditions</title>
      <meta name="description" contents="Terms and conditions for Neurochecklists"></meta>
    </Helmet>
    <Banner title="Terms &amp; Conditions" />
    <div className="inner-container small-top-padding big-padding-bottom terms-and-conditions">
      <div className="pure-g">
        <div className="pure-u-1">
          <div className="l-box">
            <h2>Neurochecklists Terms and Conditions of use (&quot;Terms&quot;)</h2>
            <h3>Last updated: 26 May 2016</h3>
            <p>
              The following Terms and Conditions (&quot;Terms&quot;, &quot;Terms and Conditions&quot;) govern use of the www.neurochecklists.com (&#8220;Neurochecklists&#8221;, &quot;The Website&quot; &quot;The Service&quot;) operated by ZK &amp; I Consultants Ltd. Registered company number: 7765383 England. (&quot;The Copyright Owner&quot; &#8220;The Publisher&quot;)
            </p>
            <p>
              Please read them carefully because your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use The Service.
            </p>
            <p>
              By <strong>accessing,</strong> registering with, visiting The Website, <strong>or using The Service, you agree to be bound by these Terms.</strong>
            </p>
            <p>
              By registering to access the content of the Website, you have consented to receive occasional ZK &amp; I Consultants Ltd product related e-mail communications. If at any time you wish to stop receiving these emails contact {CONSTANTS.CONTACT_EMAIL}
            </p>
            <p>
              Copyright for Neurochecklists &#169; ZK &amp; I Consultants Ltd.
            </p>
            <p>
              The entire content of the Website is protected by copyright (unless otherwise indicated). As a User, you have certain rights set forth below; all other rights are reserved.
            </p>
            <ol style={{listStyleType: 'decimal'}}>
              <li>
                <p><strong>Rules of Use</strong></p>
                <ul style={{listStyleType: 'upper-alpha'}}>
                  <li>
                    <strong>The following rules apply to all Users:</strong> You may download, view, copy and save to hard disk or diskette and store or print out single copies of individual articles or items for your own personal and non-commercial use including scholarly, educational or scientific research or study. You may transmit to a third party, in hard copy or electronically, a single article or item from The Website for that party's own personal and non-commercial use including scholarly, educational, or scientific research or study. In addition, you have the right to use, with appropriate credit, brief excerpts from The Website content in your own work for the above purposes.
                  </li>
                  <li>
                    <p>
                      Except as provided in Paragraph (A) above, you may not copy, distribute, transmit or otherwise reproduce material from The Website or systematically store such material in any form or media in a retrieval system; or print out multiple copies of any article or item for inclusion in packs; or transmit any material from the Website directly or indirectly, for use in any paid service or for use by any information brokerage or for systematic distribution of material, whether or not to another User and whether for commercial or non-profit use or for a fee or free of charge. For any uses other than those permitted by Paragraph (A), permission should be sought from the Copyright owner in the first instance by contacting ZK &amp; I Consultants Ltd by e-mailing {CONSTANTS.CONTACT_EMAIL}
                    </p>
                    <p>
                      In order to protect the integrity and attribution of The Website content, you agree not to remove or modify any copyright or proprietary notices, author attribution or disclaimer contained in the material or on any screen display, and not to integrate material from the Website with other materials or otherwise create derivative works in any medium based on or including materials from the Website. This is not meant to prohibit the use of quotations from The Website for the purposes of comment, criticism or review in accordance with fair dealing law. Framing of the Website is prohibited. (If a link to the Website is directed to an area requiring registration, the link must be directed to the relevant registration page).
                    </p>
                    <p>
                      Finally, you may not do anything to restrict or inhibit any other User's access to or use of The Website.
                    </p>
                  </li>
                  <li>
                    All trademarks, logos, get-up, product and company names are owned by the Publisher or third parties. No permission is given in respect of the use of any such trademarks, logos, get-up, product or company names where such use may infringe the holder's rights.
                  </li>
                  <li>
                    If you refuse or fail to abide by these rules or violate any other terms or conditions of access, The Publisher reserves the right in its sole discretion to suspend or terminate your access to The Website immediately without notice, in addition to any other available remedies.
                  </li>
                </ul>
              </li>
              <li>
                <p><strong>Additional Terms</strong></p>
                <ol style={{listStyleType: 'lower-alpha'}}>
                  <li>
                    The Publisher is not responsible for any charges associated with accessing the Website including any computer equipment, telephone lines, or access software.
                  </li>
                  <li>
                    Alterations to The Website. The Publisher reserves the right at any time without notice to alter the content of The Website and these Terms of Use. Any changes to these Terms of Use shall be posted on The Website. Your continued use of The Website following any such change shall signify that you agree to be bound by the Terms of Use as amended.
                  </li>
                  <li>
                    The Website may provide links to third party websites. Where such links exist, The Publisher disclaims all responsibility and liability for the content of such third party websites. Users assume the sole responsibility for accessing of third party websites and the use of any content appearing on such websites.
                  </li>
                  <li>
                    Registration is required to access selected areas of The Website. Once you have registered with a Username and password, you should not share or disclose your password to any other User. Without prejudice to the foregoing, The Publisher reserves the right to deny Users access to the Website or any part of it without notice and to decline to provide access or services to any User who is in breach of these Terms of Use.
                  </li>
                  <li>
                    The Website may in the future comprise areas, including without limitation, the Classified Area and the Recruitment Area, in which third parties can advertise goods and services. The use of such goods and services shall be subject to the third party's terms and conditions and Users assume the sole responsibility for entering into agreements with such third parties. The Publisher disclaims all responsibility and liability for any disputes arising from such agreements or for any errors or inaccuracies in the description of such goods and services. The advertising of goods and services in selected areas of The Website implies no endorsement or warranty on the part of The Publisher in relation to those goods and services. Any special offers or promotions advertised on the Website will comply with the relevant legislation and codes of practice in force in the United Kingdom.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>Warranty Limitations and Exclusions of Liability</strong>
                </p>
                <ol style={{listStyleType: 'lower-roman'}}>
                  <li>
                    The Website and its content (whether provided by The Publisher or by third parties) is provided on an 'as is' basis, without warranties of any kind, either express or implied, including, but not limited to, warranties of satisfactory quality or fitness for a particular purpose or warranties as to accuracy or completeness;
                  </li>
                  <li>
                    The use of The Website and any services provided is at the User's own risk. Users should be aware that identifiable personal information, such as name or e-mail address, that is disclosed voluntarily on bulletin boards or in chat areas that are accessible to other Users could be collected and disclosed by others and that no liability is accepted for such collection and disclosure;
                  </li>
                  <li>
                    Access to The Website may be interrupted and may not be error free; and
                  </li>
                  <li>
                    Neither The Publisher nor anyone else involved in creating, producing or delivering The Website shall be liable to the fullest extent permitted by the law for any direct, indirect, incidental, special, consequential or punitive damages arising out of the User's use of or inability to use The Website or any materials or information in it;
                  </li>
                  <li>
                    The User accepts and agrees that the information on The Website is intended as general information only. Neither The Publisher nor anyone else involved in creating, producing or delivering The Website shall be liable for any loss or damage occasioned to any person or property through using the information contained in www.neurochecklists.com, or acting or refraining from acting as a result of such use.
                  </li>
                  <li>
                    The Website is aimed primarily at Users who are healthcare professionals practising in the United Kingdom. The Publisher makes no representation that any information, service or product referred to in The Website is suitable or available for use outside the United Kingdom. Neither The Publisher nor anyone else involved in creating, producing or delivering The Website shall be liable for any failure to comply with any local legislation and codes of practice applicable outside of the United Kingdom.
                  </li>
                  <li>
                    For non-health care professionals, the Neurochecklists is intended to provide tools and information that are intended to help you participate with your doctor in the management of your health care. &#160;All contents provide through The Website, including the Materials and all other text, graphics, images, and other content, is provided for informational purposes only. &#160;The information the Neurochecklists provides are not intended to be, and are not professional medical advice or a substitute for such advice, or for diagnosis, treatment, cure, or prevention of any health conditions, and you should not rely on them as such. &#160;No doctor-patient relationship is formed by your use of The Website or the information provided. You should always seek the advice of physicians or other qualified health care providers if you have questions about any physical or mental health condition or any of the information you receive from The Website. ZK&amp;I Consultants Ltd does not recommend or endorse any specific tests, products, procedures, opinions, or other information that may be described on The Website.
                  </li>
                  <li>
                    Non-healthcare professionals should never disregard professional medical advice or delay seeking professional medical advice, diagnosis, or treatment based on anything you read or obtain through The Website. &#160;You should consult your doctor or other qualified health care professional if you have any questions about a medical condition, and before taking any drug, changing your diet, or commencing or discontinuing any course of medical or other health care treatment. &#160;Do not ignore or delay obtaining professional medical advice because of any information or other content you obtain from The Website. &#160;
                  </li>
                  <li>
                    These Terms of Use shall be governed by English law as if made and wholly performed in England and the parties agree to submit to the exclusive jurisdiction of the English courts.
                  </li>
                </ol>
              </li>
              <li>
                <p><strong>Privacy Policy</strong></p>
                <p>
                  By submitting your personal information to The Website, you agree to its use in accordance with the Publisher's Privacy Policy.
                </p>
                <p>
                  Please visit the Privacy Policy page for more information.
                </p>
              </li>
              <li>
                <p>
                  <strong>Subscriptions and Purchases</strong>
                </p>
                <p>
                  Some parts of the Service are billed on a subscription basis (&quot;Subscription(s)&quot;). You will be billed in advance on a recurring monthly or annual basis
                </p>
                <p>
                  If you wish to purchase any product or service made available through the Service (&quot;Purchase&quot;), you may be asked to supply certain information relevant to your Purchase.
                </p>
              </li>
              <li>
                <p><strong>General</strong></p>
                <p>
                  No waiver. No failure by The Publisher in exercising any rights under these Terms of Use shall prejudice or restrict our rights or operate or be construed as a waiver thereof. Any single or partial exercise of any such rights shall not preclude other or further exercise thereof or the exercise of any other right. No waiver of any breach of these Terms of Use shall be held to be a waiver of any other or subsequent breach.
                </p>
              </li>
              <li>
                <p>
                  <strong>Force Majeure</strong>
                </p>
                <p>
                  The Publisher shall not be liable for any breach of these Terms of Use or any failure to provide or delay in provision of any services through The Website resulting from any event or circumstance beyond its reasonable control including, without limitation, any Acts of God, strikes, lock outs and other industrial disputes, breakdown of systems or network access, fire, flood, explosion or accident.
                </p>
                <p>
                  Severability. If any of these Terms of Use are or become or are declared by a court of competent jurisdiction to be invalid, illegal or unenforceable, such terms shall be severed from these Terms of Use and shall not affect the validity, legality or enforceability of the remainder of these Terms of Use.
                </p>
              </li>
            </ol>

          </div>
        </div>
      </div>
    </div>
  </div>
)
