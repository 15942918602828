import React from 'react';
import { browserHistory } from 'react-router';
import queryString from 'query-string';
import './upgrade.scss';

export default class DowngradeButton extends React.Component {
  constructor(){
    super();
    this.state = {popupActive: false}
    this.popupName = 'cancel-subscription';
  }

  handleClick(location, e){
    e.preventDefault();
    const query = queryString.stringify({ modal: location});
    browserHistory.replace(`${this.props.location.pathname}?${query}`);
  }

  render(){
    return(
      <div className="upgrade-btn cancel-subscription">
        <a
          href="#"
          onClick={this.handleClick.bind(this, this.popupName)}
        >Cancel subscription</a>
      </div>
    )
  }

}
