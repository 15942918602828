import React from 'react'
import AppRoutes from '../../routes/routes';

import images from '../../images';

import './explore-icon.scss'

export default class ExploreIcon extends React.Component {
  render () {
    let { cat } = this.props;

    if (cat.title === 'EXPLORE') {
      cat.icon = require('../../../images/icons/001-category-1.svg');
      cat.link = AppRoutes.explore;
    } else {
      cat.icon = images['icons/categories/' + cat._slugs[0] + '.svg'];
      if (!cat.icon) {
        cat.icon = images['icons/002-medicine.svg']
      }
      cat.link = `${AppRoutes.category}${cat._slugs}`;
    }
    return <img src={cat.icon} alt={cat.title} className="explore-option-icon" />
  }
}
