import React from "react";
import { Router, Route, IndexRoute, browserHistory, Redirect } from "react-router";
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";

import LoginStore from './stores/login-store';
import { runMiddleWare, hasLoginExpired } from './middleware/middleware';
import constants from './constants/constants';

import LoggedOutTemplate from './components/loggedout-template';
import LoggedInTemplate from './components/loggedin-template';
import GlobalPopup from './components/popups/global-popup';

import HomePage from  './pages/homepage/homepage';
import Pricing from './pages/pricing/pricing';
import ContactUs from './pages/contact-us/contact';
import Faqs from './pages/contact-us/faqs';
import About from './pages/about/about-page';
import Credits from './pages/about/credits';
import Terms from './pages/legals/tcs';
import Privacy from './pages/legals/privacy';
import Data from './pages/legals/data';
import BookRegister from "./pages/book-register";

import PageNotFound from './pages/404/404';
import IndexPage from './pages/index/index-page';
import Checklist from './pages/checklists/checklist';
import Search from './pages/searches/search-page';
import Bookmarks from './pages/bookmarks/bookmarks';
import Notes from './pages/notes/notes';
import UserDetails from './pages/settings/user-details';
import UserGuide from './pages/settings/user-guide';
import UserChangePassword from './pages/settings/user-change-password';
import UserDelete from './pages/settings/user-delete';
import IndexRedirector from './components/index-redirector';
import sitemapRedirector from "./components/sitemap-redirector";

const checkSession = (nextState, replace, next) => {
  if (!LoginStore.isLoggedIn()) replace(`/home?modal=login`);

  const userData = LoginStore.getSessionUserData();
  const middlewareList = [ hasLoginExpired ];
  LoginStore.updateSessionUserData({ lastActive: Date.now() });

  runMiddleWare(middlewareList, userData, nextState, replace);
  next();
};

function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

// here we'll run an interval to make sure the user has an active session still.
  var sessionInterval = sessionInterval || setInterval(() => {
  if (!LoginStore.isLoggedIn()) return; // do nothing unless the user is actually logged in
  let intent = hasLoginExpired(LoginStore.getSessionUserData());

  // noop if nothing to do, so just invoke always.
  intent.redirect();
}, constants.CHECK_USER_SESSION_INTERVAL);

class App extends React.Component {
  render() {
    return (
      <div className="app">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="With an extensive range of fully referenced and linked checklists across the spectrum of neurology, neurochecklists offers relevant and practical information in a clear and concise format." />
          <meta name="og:description" content="With an extensive range of fully referenced and linked checklists across the spectrum of neurology, neurochecklists offers relevant and practical information in a clear and concise format." />
          <title>Neurochecklists | Your gateway to neurology</title>
          <meta name="og:title" content="Neurochecklists | Your gateway to neurology" />
          <link rel="canonical" href={'https://neurochecklists.com' + window.location.pathname} />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@neurochecklists" />

        </Helmet>
        <Router history={browserHistory} onUpdate={logPageView}>
          <Route name="sitemap.xml" path="/sitemap.xml" component={ sitemapRedirector } />
          <Route name="dashboard" component={LoginStore.isLoggedIn() ? LoggedInTemplate : LoggedOutTemplate}>
            <Route name="bookmarks" path="bookmarks" component={ Bookmarks } onEnter={checkSession} />
            <Route name="notes" path="notes" component={ Notes } onEnter={checkSession} />
            <Route name="Search results" path="search" component={ Search } />
            <Route name="legacy-index" path="index/*" component={ IndexRedirector }></Route>
            <Route name="legacy-index-root" path="index" component={ IndexRedirector }></Route>
            <Route name="explore" path="explore">
              <IndexRoute component={ IndexPage } />
              <Route name="checklists" path="checklists/:id"  component={ Checklist } />
            </Route>
            {/* <Route name="book-registration" path="book-register" component={BookRegister} /> */}
            <Route name="account">
              <Route path="account/update-details" component={ UserDetails } onEnter={checkSession} />
              <Route path="account/guide" component={ UserGuide } onEnter={checkSession} />
              <Route path="account/change-password" component={ UserChangePassword } onEnter={checkSession} />
              <Route path="account/delete-account" component={ UserDelete } onEnter={checkSession} />
            </Route>
          </Route>

          <Route name="Neurochecklists" component={LoginStore.isLoggedIn() ? LoggedInTemplate : LoggedOutTemplate}>
            <Route name="home" path="/" component={ HomePage } />
            <Route name="home" path="/home" component={ HomePage } />
            <Route name="contact" path="contact" component={ ContactUs } />
            <Route name="faqs" path="faqs" component={ Faqs } />
            <Route name="pricing" path="pricing" component={ Pricing } />
            <Route name="about" path="about" component={ About } />
            <Route name="tcs" path="terms-and-conditions" component={ Terms } />
            <Route name="privacy" path="privacy-policy" component={ Privacy } />
            <Route name="data" path="data-policy" component={ Data } />
            <Route name="credits" path="site-credits" component={ Credits } />
            <Route path="*" component={ PageNotFound } />
          </Route>
        </Router>
        <GlobalPopup location={window.location}></GlobalPopup>
      </div>
    );
  };
}

export default App;
