export default {
  dashboard: '/',
  search: '/search',
  bookmarks: '/bookmarks/',
  notes: '/notes/',
  account: '/account/update-details',
  changePassword: '/account/change-password',
  deleteAccount: '/account/delete-account',
  guide: '/account/guide'
}
