import React from 'react';
import { Link } from 'react-router';
import uuid from 'uuid/v4';
import AppConstants from '../../constants/constants';
import AppRoutes from '../../routes/routes';

export default props => {
  let list = props.data.map((data, key) => {
    let url = `${AppRoutes[data.type + 's']}${data.slug}`;
    let urlTag = <Link to={url}>{data.title}</Link>;

    if (data.type == 'checklist') {
      urlTag = <Link to={`${url}?highlight-text=${props.searchValue}`}>{data.title}</Link>;
    }

    if (data.type == 'chapter') {
      urlTag = (
        <Link to={`${AppRoutes.explore}?open=${data.slug}`}>{data.title}</Link>
      );
    }

    if (data.type == 'topic') {
      urlTag = (
        <Link to={`${AppRoutes.explore}?open=${data.slug}`}>{data.title}</Link>
      );
    }

    return <li key={uuid()}>{urlTag}</li>;
  });

  return (
    <div className="search-suggestions-item">
      <h3 className="clearfix">
        <span>{props.title}</span>
        <Link
          to={{
            pathname: AppRoutes.search,
            query: {
              q: props.searchValue,
              page: AppConstants.DEFAULTSEARCHPAGE,
              per_page: AppConstants.PERPAGE,
              filter: props.title.toLowerCase()
            }
          }}
          className="view-more"
        >
          {' '}
          More
        </Link>
      </h3>
      <ul>{list}</ul>
    </div>
  );
};
