import React from 'react';
import Classnames from 'classnames';
import { browserHistory } from 'react-router';
import uuid from 'uuid/v4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import ChecklistItem from './checklist-item';
import SignpostItem from './signpost-item';

export default class ChecklistSection extends React.Component {

  constructor() {
    super();
    this.state = { closed: false }
    this.hasHighlight = false;
    this.requestRegisterModal = this.requestRegisterModal.bind(this);
    this.escapeRegex = this.escapeRegex.bind(this);
  }

  componentDidMount() {
    let closed = false;
    let inactive = typeof(this.props.list) === 'undefined' || this.props.list.length === 0;

    closed = !this.props.signposts && !(this.props.query && this.props.query['highlight-text'] && this.hasHighlight) && this.props.id !== 0;
    return this.setState( { closed, inactive });
  }

  escapeRegex(s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  highlightText(text) {
    if (this.props.query && this.props.query['highlight-text']) {
      let term = this.escapeRegex(this.props.query['highlight-text']);
      let regex = new RegExp(`(${term})`,'i');

      if (text.toLowerCase().match(regex)) {
        if (!this.hasHighlight) {
          this.hasHighlight = true;
        }
        return this.decorateText(text, regex);
      }
    }
    return text;
  }

  decorateText(text, regex) {
    return text.replace(regex, (match) => {
      return `<mark>${match}</mark>`;
    })
  }

  requestRegisterModal() {
    browserHistory.replace(`${window.location.pathname}?modal=register&restricted=1`)
  }

  generateTitle(titleObj) {
    let title = this.highlightText(titleObj.text);
    let footnotes = titleObj.footnotes;
    let parsed_footnotes = '';

    if (footnotes && footnotes.length) {
      parsed_footnotes = footnotes.map((footnote, i, array) => {
        let lastItemIndex = array.length - 1;
        let comma = i === lastItemIndex ? '' : ', ';

        return `<a class="footnote-link" href="${window.location.pathname}#s${footnote}"><sup>${footnote}${comma}</sup></a>`;
      }).join("");
    }

    let html = `${title}${parsed_footnotes}`

    return(
        <div className="text-content" dangerouslySetInnerHTML={{__html: html}}></div>
    )
  }

  generateLists(list) {
    return list.map( (item, index) => {
      let title = this.generateTitle(item.title);
      let { sub_list } = item;
      let innerList = (sub_list) ? this.genereteSubList(sub_list): '';
      return(
        <ChecklistItem key={uuid()} title={title} innerList={innerList}>
        </ChecklistItem>
      )
    })
  }

  genereteSubList(list) {
    if (!list) {
      return;
    }

    return list.map( (item, index) => {
      let title = this.generateTitle(item.title);
      return (
        <ul className="fa-ul" key={uuid()}>
          <ChecklistItem sublist={true} title={title}></ChecklistItem>
        </ul>
      )
    })
  }

  generateSignPostList(list) {
    return list.data.map((signpost, i) =>{
      let title = this.generateTitle({text: signpost.title});
      return(
        <SignpostItem {...signpost} title={title} id={'s' + (i+1)} key={uuid()}></SignpostItem>
      );
    })
  }

  handleClick(e) {
    // if the click is for the footnote links then don't
    // toggle the section.
    if (['footnote-link', 'sup'].indexOf(e.target.localName) > -1 ) {
        e.stopPropagation();
        return;
    }

    if (this.state.inactive) {
      return this.requestRegisterModal();
    }

    this.refs.checklist.classList.toggle('checklist-closed');
    this.setState({closed: !this.state.closed})
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.state);
  }

  render() {
    let classes = Classnames({
      "checklist-section": true,
      "signposts": !!this.props.signposts,
      "checklist-closed": this.state.closed
    });

    let icon = this.state.inactive ? <i><FontAwesomeIcon icon={faLock} /></i> : (!this.state.closed ? <i className="icon-minus"></i> : <i className="icon-plus"></i>);

    let section_title = this.generateTitle(this.props.title);
    let section_list = (this.props.signposts) ? (<ol>{this.generateSignPostList(this.props.list)}</ol>) : (<ul className="fa-ul">{this.generateLists(this.props.list)}</ul>);

    return (
      <div ref="checklist" className={classes}>
        <header className={this.state.inactive ? 'checklist-section--header inactive' : 'checklist-section--header'} onClick={this.handleClick.bind(this)}>
          <div className="l-box pos-rel">
            {icon}
            <h2>{section_title}</h2>
          </div>
        </header>
        { !this.state.inactive ?
        <div className="l-box checklist-content">
          {section_list}
        </div>
        : ''}
      </div>
    )
  }
}
