import { EventEmitter } from 'events';

const CHANGE_EVENT = 'change';

export default class BaseStore extends EventEmitter {
  subscribe(actionSubscribe) {
    this._dispatchToken = actionSubscribe();
  }

  get dispatchToken() {
    return this._dispatchToken;
  }

  emitChange(data){
    this.emit( CHANGE_EVENT, data );
  }

  addChangeListener( callback ){
    this.on( CHANGE_EVENT, callback );
  }

  removeChangeListener( callback ){
    this.removeListener( CHANGE_EVENT, callback );
  }
}
