import React from 'react';

export default ({ text }) => (
  <div className="loading-overlay--light">
    <div className="loading-content-container">
      <span className="loading-icon--brain"></span>
      <span className="loading-text">{text}</span>
    </div>
  </div>
)
