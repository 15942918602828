import uuid from "uuid/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusSquare,
  faPlusSquare,
} from "@fortawesome/free-regular-svg-icons";

import "./collapsible-li.scss";
import classNames from "classnames";

/**
 * augments list item behaviour to add open and close button for any sublists
 * no sub list, no button
 */
class CollapsibleLi extends React.Component {
  constructor(props) {
    super();
    this.state = {
      open: props.current ? true : false,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen(e) {
    let { open } = this.state;
    e.preventDefault();
    this.setState({ open: !open });
  }

  render() {
    let { open } = this.state;
    let { toggleElement, entry, children, current, id } = this.props;

    let classnames = classNames({
      "collapsible-item": true,
      "is-current": current,
    });

    let childClasses = classNames({
      hidden: !open,
    });

    return (
      <li className={classnames} key={uuid()} id={id}>
        <span
          className="clickable collapsible-toggle"
          onClick={this.toggleOpen}
        >
          {toggleElement ? (
            toggleElement
          ) : (
            <>
              <FontAwesomeIcon
                icon={open ? faMinusSquare : faPlusSquare}
                color={"#dcdcdc"}
              />
              {entry}
            </>
          )}
        </span>
        <div className={childClasses}>{children}</div>
      </li>
    );
  }
}

export default CollapsibleLi;
