import constants from '../constants/constants';
import Routes from '../routes/logged-in';
import LoginAction from '../actions/login-actions';

const loginPath = '/home?modal=login';
const noop = function(){}

export function hasLoginExpired(user, state, replace) {
  const difference = Math.abs(Date.now() - user.lastActive) / constants.INACTIVE_LIMIT;
  if (difference >= 1) {
    return {
      continue: false,
      redirect: () => {
        LoginAction.logout(
          {
            "X-USER-EMAIL" : user.email,
            "X-USER-TOKEN" : user.authentication_token
          },
          loginPath
        );
      }
    }
  } else {
    return {
      continue: true,
      redirect: noop
    }
  }
}

export function runMiddleWare(middleware, user, state , replace) {
  for(let i = 0; i < middleware.length; i++){
    let result = middleware[i](user, state, replace);
    if(!result.continue) {
      result.redirect();
      break;
    }
  }
}
