import React from 'react';
import UserNavigation from './user-section-navigation';
import FreeTrialExpired from '../../components/subcribe/free-trial-expired';

export default class UserSectionBanner extends React.Component {
  render() {
    return (
      <div>
        <div className="splash-title-container">
          <div className="splash-title">
            <div className="l-box">
              <h1 className="splash-title-container--title">{this.props.user.name}</h1>
            </div>
          </div>
        </div>


        <UserNavigation />
        <FreeTrialExpired />
      </div>
    )
  }
}
