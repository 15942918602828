export default {
  TABOPENED: 'TABOPENED',
  POPUPCLOSED: 'POPUPCLOSED',
  DEFAULTSEARCHPAGE: 1,
  PERPAGE: 15,
  CLAUTOCOMPLELIMIT: 5,
  DEFAULTFILTER: 'all',
  INACTIVE_LIMIT: 36e5, //1 hour
  CHECK_USER_SESSION_INTERVAL: 10000, // 10 seconds
  CONTACT_EMAIL: 'info@neurochecklists.com'
}
