import React from 'react';
import { SERVER_ERROR, HELP_INFO } from '../../constants/messages';
import Api from '../../api/api';
import LoginStore from '../../stores/login-store';
import Loading from '../loading/loading';
import AppRoutes from '../../routes/routes';

export default class VerifyToken extends React.Component {
  constructor() {
    super();
    this.state = {
      error: false,
      success: false,
      loading: true,
      error_heading: '',
      error_msg: '',
      alreadyVerified: false
    };
  }

  componentDidMount() {
    const { confirmation_token } = this.props.location.query;

    if (!confirmation_token) {
      this.setState({
        loading: false,
        error: true,
        error_msg: 'An invalid validation token has been provided',
        success: false
      });
      return;
    }

    LoginStore.verifyToken(confirmation_token, {
      onSuccess: () => {
        this.setState({
          loading: false,
          error: false,
          success: true
        });
      },
      onError: ({ error }) => {
        if (typeof(error) !== 'object') {
          return this.setState({
            loading: false,
            error: true,
            success: false
          });
        }

        const key    = Object.keys(error)[0];
        const title  = (key == 'email') ? 'Email already confirmed' : 'Confirmation token error';
        const reason = `The ${Api.snakeCaseToWords(key)} ${error[key][0]}.`;

        this.setState({
          loading: false,
          error: true,
          error_heading: title,
          error_msg: (error.email)? reason : `${reason} ${HELP_INFO}`,
          alreadyVerified: (error.email) ? true : false,
          success: false
        });
      }
    });
  }

  renderError() {
    return (
      <div className="pure-g thanks-popup">
        <div className="pure-u-1">
          <section className="l-box">
            <h1 className="cred">{(this.state.error_heading) ? this.state.error_heading : SERVER_ERROR}</h1>
            <div className="text-inner">
              <p className="error-text">{(this.state.error_msg)? this.state.error_msg : HELP_INFO }</p>

              { (this.state.alreadyVerified) ? (
                  <p className="error-text">
                    <a className="pure-button thank-you-btn" href={`${AppRoutes.homepage}?modal=login`}>Sign in</a>
                  </p>
                ) : null
              }

            </div>
          </section>
        </div>
      </div>
    )
  }

  renderSuccess() {
    return (
      <div className="pure-g thanks-popup">
        <div className="pure-u-1">
          <section className="l-box">
            <h1 className="cgreen">Your email was successfully verified!</h1>
            <img className="pure-img" src={require('../../../images/email.png')} />
            <footer>
              <p>To login to your account click the link below</p>
              <a className="pure-button thank-you-btn" href={`${AppRoutes.homepage}?modal=login`}>Login</a>
            </footer>
          </section>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.error) {
      return this.renderError();
    }

    if (this.state.success) {
      return this.renderSuccess();
    }

    return (
      <div className="thanks-popup loading-large">
        {(this.state.loading) ? <Loading text="Please wait..." /> : null }
      </div>
    );
  }
}
