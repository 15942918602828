import store from "store";
import { getData, postData, deleteData, patchData } from "./fetchData";
import { dispatch } from "../dispatchers/dispatcher";
import AppAuthConstants from "../constants/auth-constants";
import ApiEndPoints from "../constants/endpoints";
import CryptoJS from "crypto-js";

// changing this value will log everybody out!
const storageKey = "nc-userdata-50419";

const storeSessionUser = (key, data) => {
  if (!data || !key) return;

  const userData = JSON.stringify(data);
  const ciphertext = CryptoJS.AES.encrypt(userData, key);

  store.set(storageKey, ciphertext.toString());
};

const getSessionToken = key => {
  let data = store.get(storageKey);
  if (!data || !key) return false;
  return decryptData(key, data)["authentication_token"];
};

const getSessionUser = key => {
  let data = store.get(storageKey);
  if (!data || !key) return false;
  return decryptData(key, store.get(storageKey))["email"];
};

const decryptData = (key, data) => {
  if (!data || !key) return {};
  var bytes = CryptoJS.AES.decrypt(data, key);
  var plaintext = bytes.toString(CryptoJS.enc.Utf8);

  try {
    return JSON.parse(plaintext);
  } catch (e) {
    // can't do much here, the json is knackered for whatever reason
    if ("Rollbar" in window) {
      Rollbar.warning("Invalid user data detected, deleting their cache", e);
    }
    store.clearAll();
    return {};
  }
};

const getSessionUserData = key => {
  if (!key) return;
  let data = store.get(storageKey);
  return decryptData(key, data);
};

const updateSessionUserData = (key, data) => {
  let userData = getSessionUserData(key);
  let newData = Object.assign({}, userData, data);
  storeSessionUser(key, newData);
};

const isLoggedIn = key => {
  return store.get(storageKey) && getSessionToken(key) && getSessionToken(key);
};

const changeLocation = path => {
  setTimeout(() => {
    window.location.assign(path);
  }, 0);
};

const logout = (headers, redirectPath = "/") => {
  deleteData(ApiEndPoints.LOGOUT, headers, undefined, {
    onSuccess() {
      dispatch({
        actionType: AppAuthConstants.REQUEST_LOGOUT_USER_SUCCESS,
        redirectPath
      });
    },
    onError() {
      store.clearAll();
      changeLocation("/");
    }
  });
};

const attemptLogin = (email, password) => {
  const userData = {
    user: {
      email: email,
      password: password
    }
  };

  postData(ApiEndPoints.LOGIN, undefined, userData, {
    onSuccess: ({ data }) => {
      dispatch({
        actionType: AppAuthConstants.REQUEST_LOGIN_USER_SUCCESS,
        data: data
      });
    },
    onError: ({ error }) => {
      dispatch({
        actionType: AppAuthConstants.REQUEST_LOGIN_USER_ERROR,
        error: error.error || error
      });
    }
  });
};

const attemptRegister = data => {
  postData(
    ApiEndPoints.REGISTER,
    undefined,
    {
      user: {
        name: data.name,
        email: data.email,
        password: data.password,
        password_confirmation: data.rePassword,
        code: data.code,
      }
    },
    {
      onSuccess: ({ data }) => {
        dispatch({
          actionType: AppAuthConstants.REQUEST_SIGNUP_USER_SUCCESS,
          data: data
        });
      },
      onError: ({ error }) => {
        dispatch({
          actionType: AppAuthConstants.REQUEST_SIGNUP_USER_ERROR,
          error: error.errors || error
        });
      }
    }
  );
};

const updateUser = (headers, data) => {
  postData(
    ApiEndPoints.PROFILES,
    headers,
    { account: data },
    {
      onSuccess: ({ data }) => {
        dispatch({
          actionType: AppAuthConstants.USER_DETAILS_UPDATED,
          data
        });
      },
      onError: error => {
        dispatch({
          actionType: AppAuthConstants.USER_DETAILS_UPDATE_ERROR
        });
      }
    }
  );
};

const updateUserPassword = (headers, data, callbacks) => {
  patchData(ApiEndPoints.PASSWORDUPDATE, headers, { account: data }, callbacks);
};

const deleteUserAccount = (headers, data, callbacks) => {
  postData(ApiEndPoints.DELETEACCOUNT, headers, { account: data }, callbacks);
};

const verifyToken = (token, callbacks) => {
  getData(`${ApiEndPoints.VERIFY_ACCOUNT}${token}`, undefined, callbacks);
};

const sendForgotPasswordEmail = (data, callbacks) => {
  postData(
    `${ApiEndPoints.FORGOTPASSWORD}`,
    undefined,
    { user: data },
    callbacks
  );
};

const passwordReset = (data, callbacks) => {
  patchData(
    `${ApiEndPoints.PASSWORDRESET}`,
    undefined,
    { user: data },
    callbacks
  );
};

const getPaymentToken = (headers, callbacks) => {
  getData(ApiEndPoints.CHECKOUT, headers, callbacks);
};

const sendPaymentDetails = (headers, data, callbacks) => {
  postData(ApiEndPoints.CHECKOUT, headers, { checkout: data }, callbacks);
};

const cancelSubscription = (headers, callbacks) => {
  deleteData(ApiEndPoints.PLANS, headers, undefined, callbacks);
};

export default {
  attemptRegister,
  attemptLogin,
  getSessionToken,
  getSessionUser,
  storeSessionUser,
  getSessionUserData,
  isLoggedIn,
  updateUser,
  updateUserPassword,
  updateSessionUserData,
  deleteUserAccount,
  changeLocation,
  logout,
  verifyToken,
  sendForgotPasswordEmail,
  passwordReset,
  getPaymentToken,
  sendPaymentDetails,
  cancelSubscription
};
