import React from "react";
import classnames from "classnames";
import StaticContainer from "react-static-container";
import LoginStore from "../stores/login-store";
import Header from "./header/header";
import Footer from "./footer/footer";
import AdminFooter from "./footer/user-footer";

export default class LoggedOutTemplate extends React.Component {
  constructor() {
    super();
    this.state = {
      popupActive: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal({ location }) {
    const { query } = location;
    const expectedModals = [
      "login",
      "register",
      "forgot-password",
      "password-reset",
      "thank-you",
      "verify-token"
    ];

    if (query && query.modal && expectedModals.includes(query.modal)) {
      this.setState({
        popupActive: true
      });
      return;
    }

    this.setState({
      popupActive: false
    });
  }
  render() {
    const classes = classnames({
      "logged-in": LoginStore.isLoggedIn(),
      "main-container": true,
      clearfix: true
    });

    const footer = LoginStore.isLoggedIn() ? <AdminFooter /> : <Footer />;
    const { pathname } = this.props.location;
    const key = pathname || "root";

    return (
      <div className={classes}>
        <Header {...this.props} />
        <StaticContainer key={key}>
          {React.cloneElement(this.props.children || <div />, { key: key })}
        </StaticContainer>
        {footer}
      </div>
    );
  }
}
