import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import uuid from 'uuid/v4';
import { SERVER_ERROR } from '../../constants/messages';
import AuthApi from '../../api/authApi';
import Api from '../../api/api';
import Loading from '../loading/loading';

class PasswordReset extends React.Component {
  constructor() {
    super();
    this.state = {
      errorMsgs: [],
      clientErrors: false,
      serverErrors: false,
      success: false,
      loading: false
    };
  }

  validateSubmission(data) {
    let errors = [];

    if (Api.hasEmptyFields(data)) {
      errors.push('Please fill in all fields');
    }

    if (data.password !== data.password_confirmation) {
      errors.push('The passwords you have entered did not match');
    }

    return errors;
  }

  handleSubmit(e) {
    let { location } = this.props;
    let query = queryString.parse(location.search);

    e.preventDefault();
    this.setState({ loading: true });
    const password = this.refs.password.value;
    const password_confirmation = this.refs.passwordConfirmation.value;
    var reset_token = 'empty';

    if (query.reset_token) {
      reset_token = query.reset_token;
    }

    const data = {
      password,
      password_confirmation
    };

    const errors = this.validateSubmission(data);
    data['reset_token'] = reset_token;

    if (errors.length) {
      return this.setState({errorMsgs: errors, clientErrors: true});
    }

    AuthApi.passwordReset(data, {
      onSuccess: () => {
        this.setState({
          serverErrors: false,
          clientErrors: false,
          success: true,
          loading: false,
          errorMsgs: []
        });
      },
      onError: () => {
        this.setState({
          serverErrors: true,
          clientErrors: false,
          success: false,
          errorMsgs: [],
          loading: false
        });
      }
    });
  }

  clientErrors() {
    let errors = this.state.errorMsgs.map( (error, index) => {
      return <li key={uuid()}>{error}</li>
    });

    return (
      <div className="alert alert-danger">
        <ul>
          {errors}
        </ul>
      </div>
    );
  }

  serverErrorMsg() {
    return(
      <div className="alert alert-danger">
        <p>{SERVER_ERROR}</p>
      </div>
    )
  }

  successMsg() {
    return (
      <div className="pure-g thanks-popup">
        <div className="pure-u-1">
          <section className="l-box">
            <h1>You password was successfully reset</h1>
            <img className="pure-img" src={require('../../../images/smiley-face.png')} />
            <footer>
              <p>To login, click the button below</p>
              <a href="/?modal=login" className="pure-button thank-you-btn">Login</a>
            </footer>
          </section>
        </div>
      </div>
    )
  }

  passwordForm() {
    let errorMsgs = null;

    if (this.state.clientErrors) {
      errorMsgs = this.clientErrors();
    }

    if (this.state.serverErrors) {
      errorMsgs = this.serverErrorMsg();
    }

    return(
      <div>
        {(this.state.loading)? <Loading text="Please wait..." /> : null }

        <form className="pure-form login-form" onSubmit={this.handleSubmit.bind(this)}>
          <span className="login-form-title">Enter and confirm your new password</span>
          <fieldset>
            {errorMsgs}
            <div className="pure-g text-field clearfix">
              <div className="pure-u-1-5 fl" >Password*</div>
              <input className="pure-u-4-5" id="password" type="password" ref="password" name="password" required />
            </div>
            <div className="pure-g text-field clearfix">
              <div className="pure-u-1-5 fl" style={{marginTop: '15'}}>Confirm Password*</div>
              <input className="pure-u-4-5" id="password-confirmation" type="password" ref="passwordConfirmation" name="password-confirmation" required />
            </div>
          </fieldset>
          <div className="login-form-submit">
            <button type="submit" className="pure-button login-submit-button">Reset Password</button>
          </div>
        </form>
      </div>
    )
  }

  render() {
    return this.state.success ? this.successMsg() : this.passwordForm();
  }
}

PasswordReset.propTypes = {
  location: PropTypes.object.isRequired
};

export default PasswordReset;
